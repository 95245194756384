import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { CanvasList } from '@shared/classes/request-manager';
import { PVService } from '../pv.service'
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';

import { BuildParams } from '@shared/modules/canvas-js/types/graph-builder';
import * as ParamsMaker from '@shared/classes/parameters-maker';
import * as DevData from '@shared/classes/device-data';

const PV_GRAPH = {
  y_format:{
    app_power:StyleSTD.NUMFORMAT.std,
    voltage:StyleSTD.NUMFORMAT.std,
    current:StyleSTD.NUMFORMAT.std,
    real_power:StyleSTD.NUMFORMAT.std
  },
  axis_pos:{
    app_power:StyleSTD.AXISPOS.pri,
    voltage:StyleSTD.AXISPOS.pri,
    current:StyleSTD.AXISPOS.pri,
    real_power:StyleSTD.AXISPOS.pri
  },
  y_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std }
}

const TEM_GRAPH = {
  y_format:{
    tem:StyleSTD.NUMFORMAT.std
  },
  axis_pos:{
    tem:StyleSTD.AXISPOS.pri
  },
  y_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std }
}

function initConsoParams(mesures, initEvent,device){
  initEvent.emit( {
    apppower: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"app_power"),["1"],"app_power"),
    realpower: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"real_power"),["1"],"real_power"),
    voltage: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"voltage"),["0"],"voltage"),
    current: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"current"),["1"],"current")
  });
}
function initRpiParams(mesures, initEvent,device){
  var select = DevData.listPSQLChan(mesures,"tem");
  initEvent.emit( {
    tem:ParamsMaker.initLineParams(["tem"], [select[0]], "sensors")
  });
}

@Component({
  selector: 'pv-graphs',
  templateUrl: './pv-graphs.component.html',
  styleUrls: ['./pv-graphs.component.scss']
})
export class PVGraphsComponent extends CanvasList {
  buildEvent : Subject<any> = new Subject<any>();
  dataEventNext : Subject<any> = new Subject<any>();
  idgraphs = ["apppower", "voltage", "current", "realpower", "tem"]
  linebuilds : BuildParams[] = [
      {name:"Production",mode:"conso",id:'apppower',type:'area'},
      {name:"Tension",mode:"conso",id:'voltage',type:'area'},
      {name:"Courant",mode:"conso",id:'current',type:'area'},
      {name:"Etat Panneaux",mode:"conso",id:'realpower',type:'area'},
      {name:"Température Ambiante",mode:"sensors",id:'tem',type:'area'},
  ]

  constructor( private pv : PVService) { super(); }
  ngOnInit(): void {
    this.pv.get_usr_linc_device().subscribe((psqllincs)=>{
      ParamsMaker.setLineParams(psqllincs, "psqllincs", "conso", PV_GRAPH, initConsoParams, this.buildEvent, this.initRequest, 0);
    });
    this.pv.get_usr_rpi_device().subscribe((psqlrpis)=>{
      ParamsMaker.setLineParams(psqlrpis, "psqlrpis", "sensors", PV_GRAPH, initRpiParams, this.buildEvent, this.initRequest, 0);
    });
  }

  ngAfterViewInit(){
    this.dataEvent.subscribe((event)=>{
      this.passDatas(event,this.dataEventNext);
    });
  }
}
