import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

const EMSMODELIST = {
  smart: "Smart",
  auto: "Auto",
  manu: "Manuel"
}
@Component({
  selector: 'mode-select',
  templateUrl: './mode-select.component.html',
  styleUrls: ['./mode-select.component.scss']
})
export class ModeSelectComponent implements OnInit {
  @Input() emsModeEvent :Observable<any>;
  @Output() emsModeSelect : EventEmitter<string> = new EventEmitter<string>();

  mode_str:string=EMSMODELIST["smart"];
  constructor() { }

  updateMode(datas:any){
    let emsmode = datas["emsmode"];
    this.mode_str = EMSMODELIST[emsmode];
  }

  ngOnInit(): void {
    this.emsModeEvent.subscribe((datas)=>{
      this.updateMode(datas);
    });
  }

  onModeClick(event:string){
    this.emsModeSelect.emit(event);
  }
}
