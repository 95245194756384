import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';

import { PlaceholderPageComponent } from './placeholder-page.component'

@NgModule({
  declarations: [ PlaceholderPageComponent ],
  exports: [ PlaceholderPageComponent ],
  imports: [
    MaterialLoaderModule,
    CommonModule
  ]
})
export class PlaceholderPageModule { }
