import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';
import { InverterPVGraphsComponent } from './inverter-pv-graphs/inverter-pv-graphs.component';
import { InverterPVHeadComponent } from './inverter-pv-head/inverter-pv-head.component';
import { InverterPVPanelComponent } from './inverter-pv-panel/inverter-pv-panel.component';
import { InverterPVViewComponent } from './inverter-pv-view.component';
import { InverterPVDCPanelComponent } from './inverter-pv-dc-panel/inverter-pv-dc-panel.component';
import { InverterPVStatePanelComponent } from './inverter-pv-state-panel/inverter-pv-state-panel.component';
import { InverterPvACSyncComponent } from './inverter-pv-acsync/inverter-pv-acsync.component';
import { InverterPvACINComponent } from './inverter-pv-acin/inverter-pv-acin.component'


@NgModule({
  declarations: [InverterPVGraphsComponent, InverterPVHeadComponent, InverterPVPanelComponent, InverterPVViewComponent, InverterPVDCPanelComponent, InverterPVStatePanelComponent, InverterPvACSyncComponent, InverterPvACINComponent],
  exports: [InverterPVViewComponent ],
  imports: [
    CommonModule,
    MaterialLoaderModule,
    CanvasJsModule,
    SelectorsModule
  ]
})
export class InverterPVViewModule { }
