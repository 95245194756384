<div class="websites page">
    <mat-sidenav-container class="websites-container">
        <mat-sidenav class="websites-sidenav" mode="over" [(opened)]="opened">
            <websites-panel (onSelect)=selectionDone($event) [hostUpdate]="hostUpdate.asObservable()"
                [(onsearch)]="onsearch" (onOpenSearch)="openSearch($event)" (onLeaveSearch)="leaveSeach($event)">
            </websites-panel>
        </mat-sidenav>
        <mat-sidenav-content class="websites-content">
            <div class="sub-header">
                <websites-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></websites-head>
            </div>
            <div class="page-content">
                <websites-graphs [idlist]="'websiteslines'" [dataEvent]="dataEvent.asObservable()"
                    (initRequest)="initRequest('websiteslines',$event)" (serv_devs)="onGetHost($event)" [initWebsites]="initWebsites.asObservable()">
                </websites-graphs>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>