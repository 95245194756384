import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ConfigLoaderService } from './config-loader.service'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    ConfigLoaderService, {
      provide : APP_INITIALIZER,
      useFactory : (config:any)=>{return ()=>config.ensureInit();},
      deps : [ConfigLoaderService,HttpClient],
      multi:true
    }
  ]
})
export class ConfigModule { }
