import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';

interface LinkElt {
  name:string,
  params:{[name:string]:any}
}

@Component({
  selector: 'link-table',
  templateUrl: './link-table.component.html',
  styleUrls: ['./link-table.component.scss']
})
export class LinkTableComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any>;
  @Input() linkEvent :Observable<{sel:string,value:any}>;
  @Input() setTitleEvent:Subject<string>;
  @Input() title:string;
  @Input() idlinks:string;
  @Input() linkbase:string;
  public dataSource: MatTableDataSource<LinkElt> = new MatTableDataSource([]);
  public displayedColumns : string[]=["name"];
  public colNames={
    "name":""
  }
  linktargets:any[]=[]
  constructor() { }
  public params : any = {}

  getParams(elt){
    return elt.params;
  }
  ngOnInit() {
    this.linkEvent.subscribe((event)=>{
      if(event.sel==this.idlinks){
        this.linktargets = event.value;
        console.log(this.linktargets);
        this.updateTable(this.linktargets);
      }
    });
    this.colNames["name"]=this.title;
  }

  public updateTable(link:any[]): void{
    this.dataSource.data = link;
    this.table.renderRows();
  }

}
