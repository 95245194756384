import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

const MODE_LIST:string[]=[
  "Arrêt",
  "Marche",
  "PAC",
  "ELY",
  "Veille"
]

@Component({
  selector: 'params-view',
  templateUrl: './params-view.component.html',
  styleUrls: ['./params-view.component.scss']
})
export class ParamsViewComponent implements OnInit {
  @Output() paramsUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Input() dataEventNext :Observable<any>;
  @Input() idval : string;

  modh_func:number = 0;
  modh_value:number = 0;
  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

    this.dataEventNext.subscribe((data)=>{
      console.log("datas::",data);
      if(this.idval==data.graph){
        var datas = data.datas;
        if(datas.length > 0)
          if(datas[0].length > 0){
            let elt = datas[0][0];
            this.modh_func=elt["function0"];
            this.modh_value=elt["value0"];
            this.paramsUpdated.emit({"func":this.modh_func,"val":this.modh_value});
          }
        }
      });
    }

  getModeString(num:number):string{
    return MODE_LIST[num];
  }

}
