import { OnInit, Output, EventEmitter, Directive } from '@angular/core';

export interface SelectorEntry<T>{
  [filed:string]:T;
}

export class SelectorDefinition<T> {
  list : SelectorEntry<T>;
  tab : string[];

  constructor(selList : SelectorEntry<T>){
    this.list = selList;
    this.tab = Object.keys(selList);
  }
  public get(num : number) : T{
    return this.list[this.tab[num]];
  }
}

@Directive()
export class SelectorBase<T> implements OnInit {
  @Output() selected: EventEmitter<T> = new EventEmitter<T>();

  list : string[];
  sel : string;
  constructor() { }

  ngOnInit() { }
  getSelected(sel:string):T{return null }
  onSelect() { this.selected.emit(this.getSelected(this.sel)); }
}
