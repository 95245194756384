import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigModule } from '@shared/modules/config/config.module';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { ConnectPanelComponent } from './connect-panel.component'


@NgModule({
  declarations: [ ConnectPanelComponent ],
  exports: [ ConnectPanelComponent ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialLoaderModule,
    ConfigModule,
    CommonModule
  ]
})
export class ConnectPanelModule { }
