import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';
import { RouterModule } from '@angular/router';

import { DatacenterEnergyModule } from './datacenter-energy-page/datacenter-energy.module';
import { DatacenterVMModule } from './datacenter-vm-page/datacenter-vm.module';
import { DatacenterOverviewModule } from './datacenter-overview-page/datacenter-overview.module';
import { DatacenterRoutingModule } from './datacenter-routing.module';
import { WebsitesModule } from './websites-page/websites.module';


import { DatacenterPageComponent } from './datacenter-page.component';
import { LinkTableModule } from './link-table/link-table.module';
import { HostLinkTableModule } from './host-link-table/host-link-table.module';

@NgModule({
  declarations: [ DatacenterPageComponent ],
  exports: [ DatacenterPageComponent ],
  imports: [
    CommonModule,
    MaterialLoaderModule,
    CanvasJsModule,
    SelectorsModule,
    DatacenterRoutingModule,
    DatacenterEnergyModule,
    DatacenterVMModule,
    DatacenterOverviewModule,
    WebsitesModule,
    RouterModule,
    LinkTableModule,
    HostLinkTableModule
  ]
})
export class DatacenterPageModule { }
