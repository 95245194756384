import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RequestPage } from '@shared/classes/request-manager';
import { PVService } from './pv.service';

function buildPars(fields, time, group, numbers) {
  return {
    fields: fields,
    time: time,
    group: group,
    numbers: numbers
  }
}

@Component({
  selector: 'pv-page',
  templateUrl: './pv-page.component.html',
  styleUrls: ['./pv-page.component.scss']
})
export class PVPageComponent extends RequestPage {
  idpage:number=1;
  opened: boolean = false;
  searchEvent: Subject<any> = new Subject<any>();

  constructor(private router: Router, protected pv: PVService) {
    super(pv);
  }
  ngOnInit() { }

  selectionDone(event: { sel: string, value: any }) {
    console.log(event.value);
    this.stopRefresh();
    this.opened = false;

    switch (event.sel) {
      case 'time':
        this.manager.selectTime(event.value, (req, requestlist, orig, graphid) => { return true; });
        this.manager.requestPoints("main", (req, requestlist, key, key2) => { return true; })
        break;
      default: break;
    }
    this.setRefresh();
  }
  toEms() {
    this.router.navigate(['/ems/graph']).then(e => {
      if (e) {
        console.log("Navigation success!");
      }
      else {
        console.log('Navigation failed :()');
      }
    });
  }

  ngAfterViewInit(){
    this.setRefresh();
  }


  openPanel() {
    this.opened = true;
  }


}
