<div class="dcdc-graphs">
  <mat-list class="graph-list">
    <mat-list-item *ngFor="let id of iddoughnuts; let i = index" class="graph-item">
      <mat-card class="list-cel">
        <div class="canvas-div">
          <canvas-js class="line-graph" idval="{{iddoughnuts[i]}}" [build]="doughnutbuilds[i]" [buildEvent]="buildEvent.asObservable()" [dataEventNext]="dataEventNext.asObservable()"></canvas-js>
        </div>
        <div class="mmatable">
          <mma-table idval="{{idtabs[i]}}" [build]="mmabuilds[i]" [dataEventNext]="dataEventNext.asObservable()"></mma-table>
        </div>
      </mat-card>
    </mat-list-item>
  </mat-list>
  <div class="line-list">
    <mat-card *ngFor="let id of idgraphs; let i = index" class="line-card">
      <canvas-js class="line-graph" idval="{{idgraphs[i]}}" [build]="linebuilds[i]" [buildEvent]="buildEvent.asObservable()" [dataEventNext]="dataEventNext.asObservable()"></canvas-js>
    </mat-card>
</div>
