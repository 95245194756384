import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DatacenterPageComponent } from './datacenter-page.component';
import { DatacenterEnergyPageComponent } from './datacenter-energy-page/datacenter-energy-page.component'
import { DatacenterVMPageComponent } from './datacenter-vm-page/datacenter-vm-page.component'
import { WebsitesPageComponent } from './websites-page/websites-page.component';
import { DatacenterOverviewPageComponent } from './datacenter-overview-page/datacenter-overview-page.component'

const _datacenter_routes = [
    {path: 'datacenter', component: DatacenterPageComponent, children:[
      { path: '', redirectTo: '/ems/datacenter/overview/0', pathMatch: 'full' },
      { path: 'energy', component: DatacenterEnergyPageComponent },
      { path: 'vm', component: DatacenterVMPageComponent },
      { path: 'vmsites', component: WebsitesPageComponent },
      { path: 'overview/:indexvm', component: DatacenterOverviewPageComponent }]
    }
  ]


@NgModule({
  imports: [ CommonModule ],
  exports:[RouterModule]
})
export class DatacenterRoutingModule {
  static datacenter_routes(){
    return _datacenter_routes[0]
  }
}
