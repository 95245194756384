<div class="link-table">
  <table mat-table [dataSource]="dataSource" class="link-tab mat-elevation-z8">
    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef> {{title}} </th>
      <td mat-cell *matCellDef="let element">
        <button class="link-btn" mat-button  [queryParams]="element.params" mat-button routerLink="{{linkbase}}" routerLinkActive="active">
          {{element.name}}
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
