<div class="datacenter-overview-panel">
  <mat-card class="panel-title">
    <div class="title-div"> <div class="txt-div">Vue Générale</div> </div>
  </mat-card>
  <div class="panel-link-tab">
      <link-table idlinks="over-energy-links" [title]="'Données Energie'" linkbase="/ems/datacenter/energy" [linkEvent]="linkEvent.asObservable()"></link-table>
  </div>
  <div class="panel-link-tab">
    <host-link-table idlinks="over-host-list-links" [title]="'Liste Serveurs'" linkbase="/ems/datacenter/overview" [linkEvent]="linkEvent.asObservable()"></host-link-table>
  </div>
  <div class="panel-link-tab">
    <link-table idlinks="over-host-vm-links" [title]="'Détails Serveur'" linkbase="/ems/datacenter/vm" [linkEvent]="linkEvent.asObservable()"></link-table>
  </div>
  <div class="panel-link-tab">
      <link-table idlinks="over-guest-vm-links" [title]="'Machines Virtuelles'" linkbase="/ems/datacenter/vm" [linkEvent]="linkEvent.asObservable()"></link-table>
  </div>
</div>
