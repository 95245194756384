import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NodeJsonBase } from '@shared/modules/config/node-std';
import { ConfigLoaderService } from '@shared/modules/config/config-loader.service';

interface PVResp{
  module: string,
  section :string,
  warn_temp: number,
  alert_temp: number
}

@Injectable({
  providedIn: 'root'
})
export class PVService  extends NodeJsonBase  {

  constructor(protected http : HttpClient, protected cls : ConfigLoaderService) {
    super(http, cls);
  }
  getParam() : Observable<PVResp>{
    var httpOptions = this.buildOptions(false,this.server_url,null);
    return this.http.get<PVResp>(this.server_url+'/reqparams', httpOptions).pipe(
      tap(_ => {}),
      catchError(this.handleError<PVResp>('getParam', ))
    );
  }

  setParam(params) : Observable<PVResp>{
    var httpOptions = this.buildOptions(false,this.server_url,params);
    return this.http.post<PVResp>(this.server_url+'/setparams', httpOptions).pipe(
      tap(_ => {}),
      catchError(this.handleError<PVResp>('setParam', ))
    );
  }

  get_usr_linc_device() : Observable<any> {
    var httpOptions = this.buildOptions(true,this.server_url,null);
    return this.http.get<any>(this.server_url+'/devices/usrlinc', httpOptions).pipe(
      tap(_ => {
        this.log('fetched pokemon');
        console.log('oki');
      }),
      catchError(this.handleError<any>('get_usr_linc_device', ))
    );
  }

  get_usr_rpi_device() : Observable<any> {
    var httpOptions = this.buildOptions(true,this.server_url,null);
    return this.http.get<any>(this.server_url+'/devices/usrrpi', httpOptions).pipe(
      tap(_ => {
        this.log('fetched pokemon');
        console.log('oki');
      }),
      catchError(this.handleError<any>('get_usr_rpi_device', ))
    );
  }
}
