<div class="mode-select">

    <div class="mode-view">
        <div class="mode-txt">Mode : {{mode_str}}</div>
    </div>
    <div class="mode-action">
        <button mat-button (click)="onModeClick('smart')" class="mode-btn">Smart</button>
        <button mat-button (click)="onModeClick('auto')" class="mode-btn">Auto</button>
        <button mat-button (click)="onModeClick('manu')" class="mode-btn">Manuel</button>
    </div>
   </div>