<div class="datacenter-vm page">
  <mat-sidenav-container class="datacenter-vm-container" >
    <mat-sidenav class="datacenter-vm-sidenav" mode="over" [(opened)]="opened">
      <!-- <datacenter-vm-panel (onSelect)="selectionDone($event)" [updateSelectorEvent]="updateSelectorEvent.asObservable()" ></datacenter-vm-panel> -->
      <datacenter-vm-panel [sitesUpdate]="sitesUpdate.asObservable()" [hostUpdate]="hostUpdate.asObservable()" (onSelect)="selectionDone($event)" [(onsearch)]="onsearch" (onOpenSearch)="openSearch($event)" (onLeaveSearch)="leaveSeach($event)"></datacenter-vm-panel>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="sub-header">
        <datacenter-vm-head (onSelect)="selectionDone($event)" (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></datacenter-vm-head>
      </div>
      <div class="page-content">
        <!-- <datacenter-vm-graphs [idlist]="'vmlines'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('vmlines',$event)" (selectInit)="initSelectors($event)" [devChangeEvent]="devChangeEvent.asObservable()"></datacenter-vm-graphs> -->
        <datacenter-vm-graphs [idlist]="'vmlines'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('vmlines',$event)" (vm_sites)="onVMSites($event)" (serv_devs)="onGetHost($event)" [initVMEvent]="initVMEvent.asObservable()" ></datacenter-vm-graphs>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
