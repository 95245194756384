import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NodeJsonBase } from '@shared/modules/config/node-std';
import { ConfigLoaderService } from '@shared/modules/config/config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class H2Service extends NodeJsonBase  {

  constructor(protected http : HttpClient, protected cls : ConfigLoaderService) {
    super(http, cls);
  }

  setH2Param(params) : Observable<any>{
    var httpOptions = this.buildOptions(false,this.server_url,params);
    return this.http.post<any>(this.server_url+'/seth2params', httpOptions).pipe(
      tap(_ => {}),
      catchError(this.handleError<any>('setParam', ))
    );
  }

  get_usr_greenbox_device() : Observable<any> {
    var httpOptions = this.buildOptions(true,this.server_url,null);
    return this.http.get<any>(this.server_url+'/devices/usrgreenbox', httpOptions).pipe(
      tap(_ => {
        this.log('fetched pokemon');
        console.log('oki');
      }),
      catchError(this.handleError<any>('get_greenbox_device', ))
    );
  }
}
