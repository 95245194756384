import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';

import { RouterPageComponent } from './router-page.component';
import { RouterGraphsComponent } from './router-graphs/router-graphs.component';
import { RouterPanelComponent } from './router-panel/router-panel.component';
import { RouterHeadComponent } from './router-head/router-head.component';



@NgModule({
  declarations: [
    RouterPageComponent,
    RouterGraphsComponent,
    RouterPanelComponent,
    RouterHeadComponent
  ],
  exports:[
    RouterPageComponent
  ],
  imports: [
    MaterialLoaderModule,
    SelectorsModule,
    CanvasJsModule,
    CommonModule
  ]
})
export class RouterPageModule { }
