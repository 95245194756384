import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pv-head',
  templateUrl: './pv-head.component.html',
  styleUrls: ['./pv-head.component.scss']
})
export class PVHeadComponent implements OnInit {
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenPanel: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  toEms() {
    this.onGoBack.emit();
  }
  openPanel() {
    this.onOpenPanel.emit()
  }
}