import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'elec-plugs-panel',
  templateUrl: './elec-plugs-panel.component.html',
  styleUrls: ['./elec-plugs-panel.component.scss']
})
export class ElecPlugsPanelComponent implements OnInit {
  @Output() onSelect: EventEmitter<{ sel: string, value: any }> = new EventEmitter < { sel: string, value: any } >();
  @Output() onSearchEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() searchEvent: Observable<any>;

  onSearch:boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.searchEvent.subscribe((onsearch)=>{
      this.onSearch = onsearch;
    });
  }

  leaveSearch(){
    this.onSearchEvent.emit(false);
  }

  openSearch(){
    this.onSearchEvent.emit(true);
  }

  onSelectionDone(sel:string, event:any){
    this.onSelect.emit({sel:sel,value:event});
  }
  }
