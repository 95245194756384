export interface GroupPar{
  unit:string,
  value:number
}

export interface TimePar{
    start:number;
    end:number;
}
export function getEndDate(date:Date,period:string){
  switch(period){
    case 'Jour' :
      return new Date(date.getFullYear(),
        date.getMonth(),
        date.getDate()+1
      ).getTime();
    case 'Semaine' :
      return new Date(date.getFullYear(),
        date.getMonth(),
        date.getDate()+7
      ).getTime();
    case 'Mois' :
      return new Date (date.getFullYear(),
        date.getMonth()+1,
        date.getDate()
      ).getTime();
    case 'Année' :
      return new Date(date.getFullYear()+1,
        date.getMonth(),
        date.getDate()
      ).getTime();
    default : return 0;
  }
}

function getDateDay(date:Date){
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  ).getTime();
}

function getDateWeek(date:Date){
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    (date.getDate() - date.getDay() + 1),

  ).getTime();
}

function getDateMonth(date:Date){
  return new Date(
    date.getFullYear(),
    date.getMonth()
  ).getTime();
}

function getPrevDateMonth(date: Date) {
  return new Date(
    date.getFullYear(),
    date.getMonth()-1
  ).getTime();
}

function getDateYear(date: Date) {
  return new Date(
    date.getFullYear(),
    0
  ).getTime();
}

export function getTimePar(time:string) : TimePar {
  var date = new Date();
  var t = date.getTime();
  switch(time){
    case 'm': return {
      start: t - 60000,
      end: t
    };
    case 'h' : return {
      start:t-3600000,
      end:t
    };
    case 'd' :return {
      start :getDateDay(date),
      end:t
    };
    case 'w' :return {
      start :getDateWeek(date),
      end:t
    };
    case 'M' :return {
      start: getDateMonth(date),
      end:t
    };
    case 'y' :return {
      start: getDateYear(date),
      end:t
    };
    case 'ph': return {
      start: t - 3600000,
      end: t
    };
    case 'pd': return {
      start: getDateDay(date),
      end: t
    };
    case 'pw': return {
      start: getDateWeek(date),
      end: t
    };
    case 'pM': return {
      start: getPrevDateMonth(date),
      end: getDateMonth(date)
    };
    case 'py': return {
      start: getDateYear(date),
      end: t
    };
    case '7d': return {
      start: t - 604800000,
      end: t
    };
    case '30d': return {
      start: t - 2592000000,
      end: t
    };
    
  }
}
