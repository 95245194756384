import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SelectorDefinition } from '@shared/modules/selectors/selector-base';
import * as Dev from '../const-canvas/device-types';

interface SelectorList {
  [name:string]:SelectorDefinition<any>
}

interface Graphlines {
  [name:string]:Graphline
}
interface Graphline {
  showname:Dev.ShowName,
  mes: Dev.MesList,
  num: Dev.NumList
}

@Injectable({
  providedIn: 'root'
})
export class GraphLoaderService {
  enerpie:string[];
  graphlines:Graphlines;
  useddevice:number[];
  selectors:SelectorList;
  constructor(private httpClient : HttpClient) { }
  ensureInit(): Promise<any> {
    return new Promise((result, error) => {
      this.httpClient.get("assets/conf-files/graphs-conf.json")
      .subscribe(
        (content : GraphLoaderService) => {
          Object.assign(this, content);
          result(this);
        },
        reason => error(reason));
      });
  }
}

function getSelectorList(cls : GraphLoaderService):SelectorList{
  let selectors = cls.selectors;
  let keys = Object.keys(selectors);
  let selectdef : SelectorList = {};
  keys.forEach((key)=>{
    selectdef[key] = new SelectorDefinition(selectors[key]);
  })
  return selectdef;
}

export class GraphConfig {
    private static self : GraphConfig = null;
    private static _linebundle : Dev.LineBundle;
    private static _enerpie : string[];
    private static _useddevice : number[];
    private static _select : SelectorList;
    private constructor( cls : GraphLoaderService){

      GraphConfig._enerpie=cls.enerpie;
      GraphConfig._useddevice=cls.useddevice;
      GraphConfig._select=getSelectorList(cls);
      let datas = cls.graphlines;
      GraphConfig._linebundle = {
        linenames:GraphConfig.buildAllNames(datas)
      }
      GraphConfig.addGraphLines(GraphConfig._linebundle,datas);
    }

    private static addGraphLines(linebundle:Dev.LineBundle,graphlines : Graphlines){
      let linekeys = Object.keys(graphlines);
      linekeys.forEach((key)=>{
        let data = graphlines[key];
        linebundle[key]={mes:data.mes,num:data.num};
      });
    }

    private static buildAllNames(graphlines : Graphlines) : Dev.LineList{
      let list : Dev.LineList = {}
      let linekeys = Object.keys(graphlines);
      linekeys.forEach((key)=>{
        Dev.buildAll(list,graphlines[key]);
      });
      return list;
    }


    public static config(cls : GraphLoaderService) : GraphConfig {
      if(GraphConfig.self==null)GraphConfig.self = new GraphConfig(cls);
      return GraphConfig.self;
    }

    public static select(cls : GraphLoaderService) : SelectorList {
      if(GraphConfig.self==null)GraphConfig.self = new GraphConfig(cls);
      return GraphConfig._select;
    }


    public static linebundle(cls : GraphLoaderService) : Dev.LineBundle {
      if(GraphConfig.self==null)GraphConfig.self = new GraphConfig(cls);
      return GraphConfig._linebundle;
    }

    public static energypie(cls : GraphLoaderService) : string[] {
      if(GraphConfig.self==null)GraphConfig.self = new GraphConfig(cls);
      return GraphConfig._enerpie;
    }

    public static useddevice(cls : GraphLoaderService) : number[] {
      if(GraphConfig.self==null)GraphConfig.self = new GraphConfig(cls);
      return GraphConfig._useddevice;
    }
  }
