<div class="datacenter-overview page">
  <mat-sidenav-container class="datacenter-overview-container">
    <mat-sidenav class="datacenter-overview-sidenav" mode="over" [(opened)]="opened">
      <datacenter-overview-panel [hostUpdate]="hostUpdate.asObservable()"></datacenter-overview-panel>
    </mat-sidenav>
    <mat-sidenav-content class="datacenter-overview-content">
      <div class="sub-header">
        <datacenter-overview-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></datacenter-overview-head>
      </div>
      <div class="page-content">
        <datacenter-overview-graphs [idlist]="'overviewbars'" [dataEvent]="dataEvent.asObservable()"
          (initRequest)="initRequest('overviewbars',$event)" (serv_devs)="onGetHost($event)">
        </datacenter-overview-graphs>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
