import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RequestPage, buildSearchParams } from '@shared/classes/request-manager';
import { InverterPVService } from '../inverter-pv.service';
import { MatDialog } from '@angular/material/dialog';
import { SearchPopUpComponent } from '@shared/modules/dialog/search-pop-up/search-pop-up.component';

@Component({
  selector: 'inverter-pv-view',
  templateUrl: './inverter-pv-view.component.html',
  styleUrls: ['./inverter-pv-view.component.scss']
})
export class InverterPVViewComponent extends RequestPage {
  onsearch = false;

  opened: boolean = false;

  constructor(private router: Router, protected inverterpv: InverterPVService, private dialog: MatDialog) {
    super(inverterpv);
  }
  ngOnInit() { }

  selectionDone(event: { sel: string, value: any }) {
    console.log(event.value);
    this.stopRefresh();
    this.opened = false;

    switch (event.sel) {
      case 'time':
        this.manager.selectTime(event.value, (req, requestlist, orig, graphid) => { return true; });
        this.manager.requestPoints("main", (req, requestlist, key, key2) => { return true; })
        break;
      default: break;
    }
    this.setRefresh();
  }
  leaveSeach(event) {
    this.onsearch = false;
    this.opened = false;
    this.manager.requestPoints("main", (req, requestlist, key, key2) => { return true; });
    this.setRefresh();
  }

  openSearch(event) {
    const dialogRef = this.dialog.open(SearchPopUpComponent, {
      autoFocus: true
    });
    this.stopRefresh();
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.onsearch = true;
        this.opened = false;
        console.log(result);
        var tmppar = buildSearchParams(null, result.time, result.group, null);
        this.manager.selectSearch(tmppar, (req, requestlist, orig, graphid) => {
          return orig == "invpvlines";
        });
        this.manager.requestPoints("search", (req, requestlist, key, key2) => { return true; });
      }
      else {
        console.log("cancel");
      }
    });
  }
  toEms() {
    this.router.navigate(['/ems/graph']).then(e => {
      if (e) {
        console.log("Navigation success!");
      }
      else {
        console.log('Navigation failed :()');
      }
    });
  }
  
  ngAfterViewInit(){
    this.setRefresh();
  }


  openPanel() {
    this.opened = true;
  }

}
