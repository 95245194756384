import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { H2PageComponent } from './h2-page.component';
import { ELYTableComponent } from './h2-tables/ely-table/ely-table.component'
import { PACTableComponent } from './h2-tables/pac-table/pac-table.component'
import { SOCTableComponent } from './h2-tables/soc-table/soc-table.component'
import { H2ParamsDialogComponent } from './h2-dialog/h2-params-dialog/h2-params-dialog.component';
import { ParamsViewComponent } from './h2-views/params-view/params-view.component';
import { StateViewComponent } from './h2-views/state-view/state-view.component';
import { H2HeadComponent } from './h2-head/h2-head.component';
import { H2GraphsComponent } from './h2-graphs/h2-graphs.component';
import { H2TablesComponent } from './h2-tables/h2-tables.component';
import { H2ViewsComponent } from './h2-views/h2-views.component';
import { H2PanelComponent } from './h2-panel/h2-panel.component'
@NgModule({
  declarations: [
    H2PageComponent,
    ELYTableComponent,
    PACTableComponent,
    SOCTableComponent,
    H2ParamsDialogComponent,
    ParamsViewComponent,
    StateViewComponent,
    H2HeadComponent,
    H2GraphsComponent,
    H2TablesComponent,
    H2ViewsComponent,
    H2PanelComponent
  ],
  exports: [ H2PageComponent ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MaterialLoaderModule,
    CanvasJsModule
  ],
  entryComponents: [
    H2ParamsDialogComponent
  ]
})
export class H2PageModule { }
