import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { H2PageModule } from './h2-page/h2-page.module';
import { BatteryPageModule } from './battery-page/battery-page.module';
import { DatacenterPageModule } from './datacenter-page/datacenter-page.module';
import { DCDCPageModule } from './dcdc-page/dcdc-page.module';
import { InverterPVPageModule } from './inverter-pv-page/inverter-pv-page.module';
import { LightingPageModule } from './lighting-page/lighting-page.module';
import { PlaceholderPageModule } from './placeholder-page/placeholder-page.module';
import { PVPageModule } from './pv-page/pv-page.module';
import { ReseauPageModule } from './reseau-page/reseau-page.module';
import { SolidStateIsoPageModule } from './solid-state-iso-page/solid-state-iso-page.module';
import { UtilitiesPageModule } from './utilities-page/utilities-page.module';
import { UtilityPageModule } from 'src/app/gui/sub-page/utility-page/utility-page.module';
import { RouterPageModule } from './router-page/router-page.module';
@NgModule({
  declarations: [],
  imports: [

    H2PageModule,
    BatteryPageModule,
    DatacenterPageModule,
    DCDCPageModule,
    InverterPVPageModule,
    LightingPageModule,
    PlaceholderPageModule,
    PVPageModule,
    ReseauPageModule,
    SolidStateIsoPageModule,
    UtilityPageModule,
    RouterPageModule,
    CommonModule
  ]
})
export class PartPageModule { }
