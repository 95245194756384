import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';

import { LightingPageComponent } from './lighting-page.component'

@NgModule({
  declarations: [ LightingPageComponent ],
  exports: [ LightingPageComponent ],
  imports: [
    MaterialLoaderModule,
    CommonModule
  ]
})
export class LightingPageModule { }
