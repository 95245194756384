import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ConnectService, UserNode } from './connect.service';
@Component({
  selector: 'connect-panel',
  templateUrl: './connect-panel.component.html',
  styleUrls: ['./connect-panel.component.scss']
})


export class ConnectPanelComponent implements OnInit {
  pkm : Object;
  has_main_img: boolean = this.server.has_main_img();
  main_img:string = this.server.main_img();
  connect_to:string = this.server.connect_to();
  sitename:string = this.server.sitename();
  form_user : FormGroup;
  failedConnection:boolean=false;
  constructor(
    private server : ConnectService,
    private router : Router,
    private fb : FormBuilder,
    private titleService: Title
  ) {
      this.form_user = fb.group({
      name: "",
      pwd: "",
    });
  }

  ngOnInit() {
    console.log(this.sitename);
    this.titleService.setTitle(this.sitename)
  }

  connect(event) : void {
    let user : UserNode = {
      name:event.name,
      password:event.pwd
    }
    this.server.connect(user).subscribe((pkm) => {
        console.log(pkm);
        if(pkm){
          this.failedConnection=false;
          this.router.navigate([this.connect_to]).then(e => {
            if(e){
              console.log("Navigation success!");
            }
            else{ console.log('Navigation failed :()'); }
          });
        }
        else{
          this.failedConnection=true;
          console.log('Connection failed');
        }
    });
  }
}
