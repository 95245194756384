import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';

import { InverterPVPageComponent } from './inverter-pv-page.component';
import  { InverterPVViewModule } from './inverter-pv-view/inverter-pv-view.module'

@NgModule({
  declarations: [ InverterPVPageComponent ],
  exports: [ InverterPVPageComponent ],
  imports: [
    MaterialLoaderModule,
    InverterPVViewModule,
    CommonModule,
    CanvasJsModule,
    SelectorsModule
  ]
})
export class InverterPVPageModule { }
