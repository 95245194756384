import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MatTableDataSource, MatTable } from '@angular/material/table';

interface OutData {
  pow: number,
  soc: number
}

@Component({
  selector: 'soc-table',
  templateUrl: './soc-table.component.html',
  styleUrls: ['./soc-table.component.scss']
})
export class SOCTableComponent implements OnInit {
  @Input() dataEventNext :Observable<any>;
  @Input() idval : string;
  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns : string[]=["pow", "soc"];
  colNames={
    "pow" : "Production",
    "soc" :"SOC"
  }
  dataSource : MatTableDataSource<OutData> = new MatTableDataSource([{"pow":0, "soc":0}]);
  constructor() { }
  ngOnInit() { }

  ngAfterViewInit() {
    this.dataEventNext.subscribe((data)=>{
      if(this.idval==data.graph){
        var datas = data.datas;
        if(datas.length > 0)
          if(datas[0].length > 0){
            let elt  = datas[0][0];
            this.updateTable({
              pow:elt['app_powerout'],
              soc:elt['soc0']
            });
          }
        }
      });
  }

   /** Expand row functions End**/
  updateTable(pac_data:OutData): void{
     this.dataSource.data = [pac_data];
     this.table.renderRows();
   }

}
