import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ConfigLoaderService,Config } from './config-loader.service';

export class NodeJsonBase{
  server_url : string = Config.adress(this.cls);

  constructor(protected http : HttpClient, protected cls : ConfigLoaderService){}

  log(msg : string): void { console.log(msg); }
  protected handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  protected buildOptions(cred:boolean, server_url:string, params:Object){
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': server_url
      }),
      withCredentials: cred,
    };
    if(params!=null){
      httpOptions['params']=params
    }
    return httpOptions;
  }

  has_main_img(){
    return Config.client(this.cls).has_main_img;
  }

  main_img(){
    return Config.client(this.cls).main_img;
  }

  connect_to(){
    return Config.client(this.cls).connect_to;
  }

  sitename(){
    return Config.client(this.cls).sitename;
  }
  
  postRequest(paramlist: any,path:string) : Observable<any> {
    var httpOptions = this.buildOptions(true,this.server_url,paramlist);
    return this.http.post<any>(this.server_url+path,httpOptions).pipe(
      tap((_) => {
        // this.log('fetched datapoint at '+(new Date()));
      }),
      catchError(this.handleError<any>('postDatapoints',[]))
    );
  }

}
