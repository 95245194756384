import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';


interface InvFieldList {
  [name: string]: {
    title: string;
    value: string;
    unit: string;
  }
}

function initLineParams(selects, majors, mode) {
  return { "select": selects, "time": 0, "timetype": "short", "numbers": majors, device: 0, mode: mode }
}

function initMPPTParams(initEvent) {
  initEvent.emit({
    voltagepv: initLineParams(["pv1", "pv2"], ["pv1", "pv2"], "vario/lastvolt"),
    enerprodtd: initLineParams(["pv1td", "pv2td"], ["pv1td", "pv2td"], "vario/lastenerprod"),
  });
}

@Component({
  selector: 'dcdc-list',
  templateUrl: './dcdc-list.component.html',
  styleUrls: ['./dcdc-list.component.scss']
})
export class DcdcListComponent implements OnInit {
  @Output() initRequest: EventEmitter<any> = new EventEmitter<any>();
  @Input() dataEvent: Observable<any>;

  fields: InvFieldList = {
    "voltagepv1": {
      title: "Tension PV1",
      value: "-1",
      unit: "V"
    },
    "voltagepv2": {
      title: "Tension PV2",
      value: "-1",
      unit: "V"
    },
    "enerprodpv1td": {
      title: "Energie PV 1 du jour ",
      value: "-1",
      unit: "kWh"
    },
    "enerprodpv2td": {
      title: "Energie  PV 2 du jour ",
      value: "-1",
      unit: "kWh"
    }
  };

  fieldnames = Object.keys(this.fields);

  constructor() { }

  ngOnInit(): void { 
    initMPPTParams(this.initRequest); 
  }

  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {
      if (event.orig == "dcdclist") {
        console.log(event.orig, " ", event.datas);
        let datas = event.datas;
        if(controlValues(datas)){
          console.log(datas[0][0])
          let keys = Object.keys(this.fields);
          keys.forEach((key) => {
            if (key in datas[0][0]) {
              console.log(key);
              this.fields[key].value = "" + (Math.round((datas[0][0][key] + Number.EPSILON) * 100) / 100) + "" + this.fields[key].unit;
              console.log(this.fields[key].value);
            }
          });
        }
      }
    });
  }

}
function controlValues(datas: any[][]): boolean {
  if (datas == null) return false;
  if (datas.length < 1) return false;
  if (datas[0] == null) return false;
  if (datas[0].length < 1) return false;
  if (datas[0][0] == null) return false;
  return true;

}