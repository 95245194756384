import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';

const VARIO_STATES = ["Nuit", "Sécurité", "Eteint", "Charge", "ChargeU", "ChargeI", "ChargeP", "ChargeIpv", "ChargeT", "---", "Ch.Ibsp" ]
const VARIO_PHASES = [ "Bulk", "Absorpt.", "Equalize", "Floating", "---", "---", "R.float.","Per.abs.", "---", "---", "---", "---" ]
interface InvFieldList {
  [name: string]: {
    title: string;
    value: string;
    unit: string;
  }
}

function initLineParams(selects, majors, mode) {
  return { "select": selects, "time": 0, "timetype": "short", "numbers": majors, device: 0, mode: mode }
}

function initMPPTStateParams(initEvent) {
  initEvent.emit({
    state: initLineParams(["mdpv1", "mdpv2", "varstate", "phase"], ["mdpv1", "mdpv2", "varstate", "phase"], "vario/laststates"),
  });
}

@Component({
  selector: 'dcdc-state',
  templateUrl: './dcdc-state.component.html',
  styleUrls: ['./dcdc-state.component.scss']
})
export class DcdcStateComponent implements OnInit {
  @Output() initRequest: EventEmitter<any> = new EventEmitter<any>();
  @Input() dataEvent: Observable<any>;

  fields: InvFieldList = {
    "statemdpv1": {
      title: "Mode PV1",
      value: "-1",
      unit: ""
    },
    "statemdpv2": {
      title: "Mode PV2",
      value: "-1",
      unit: ""
    },
    "statevarstate": {
      title: "Etat MPPT",
      value: "-1",
      unit: ""
    },
    "statephase": {
      title: "Phase",
      value: "-1",
      unit: ""
    },
    
  };

  fieldnames = Object.keys(this.fields);

  constructor() { }

  ngOnInit(): void { 
    initMPPTStateParams(this.initRequest); 
  }

  getFieldsValue(datas, key){
    if (key == "statemdpv1" || key == "statemdpv2") {
      return  "" + VARIO_STATES[datas[0][0][key]] + "" + this.fields[key].unit;
    }
    if (key == "statephase") {
      return "" + VARIO_PHASES[datas[0][0][key]] + "" + this.fields[key].unit;
    }
    return this.fields[key].value = datas[0][0][key] == 1 ? "On" : "Off";
  }

  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {
      if (event.orig == "dcdcstate") {
        console.log(event.orig, " ", event.datas);
        let datas = event.datas;
        if (controlValues(datas)) {
          console.log(datas[0][0])
          let keys = Object.keys(this.fields);
          keys.forEach((key) => {
            if (key in datas[0][0]) {
              console.log(key);
              this.fields[key].value = this.getFieldsValue(datas,key)
              console.log(this.fields[key].value);
            }
          });
        }
      }
    });
  }

}
function controlValues(datas: any[][]): boolean {
  if (datas == null) return false;
  if (datas.length < 1) return false;
  if (datas[0] == null) return false;
  if (datas[0].length < 1) return false;
  if (datas[0][0] == null) return false;
  return true;

}