import { Component, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'datacenter-vm-head',
  templateUrl: './datacenter-vm-head.component.html',
  styleUrls: ['./datacenter-vm-head.component.scss']
})
export class DatacenterVMHeadComponent implements OnInit {
  @Output() onOpenPanel: EventEmitter<{sel:string,value:any}> = new EventEmitter<{sel:string,value:any}>();
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  openPanel(){
    this.onOpenPanel.emit();
  }

  toEms(){
    this.onGoBack.emit();
  }

}
