import { DoughnutGraph } from './graphs/doughnut-graph';
import { LineGraph } from './graphs/line-graph';
import { BarGraph } from './graphs/bar-graph';
import { GenGraph } from './graphs/gen-graph';

export interface BuildParams {
  name:string,
  mode:string,
  id:string,
  type:string
}

export function BuildGraph(params:BuildParams):GenGraph{
  switch (params.type) {
    case "area":
      return new LineGraph(params.name, params.mode, params.id, params.type);
    case "stepArea":
      return new LineGraph(params.name, params.mode, params.id, params.type);
    case "doughnut":
      return new DoughnutGraph(params.name, params.mode, params.id);
    case "column":
      return new BarGraph(params.name, params.mode, params.id, params.type);
      case "bar":
        return new BarGraph(params.name, params.mode, params.id, params.type);
    default:
      return null;
  }
}
