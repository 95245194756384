import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';

import { PVPageComponent } from './pv-page.component'
import { ParametersViewComponent } from './pv-tables/parameters-view/parameters-view.component'
import { ResumeTableComponent } from './pv-tables/resume-table/resume-table.component'
import { PVConfigDialogComponent } from './pv-dialog/pv-config-dialog/pv-config-dialog.component';
import { PVHeadComponent } from './pv-head/pv-head.component';
import { PVGraphsComponent } from './pv-graphs/pv-graphs.component';
import { PVPanelComponent } from './pv-panel/pv-panel.component'

@NgModule({
  declarations: [ PVPageComponent, ParametersViewComponent, ResumeTableComponent, PVConfigDialogComponent, PVHeadComponent, PVGraphsComponent, PVPanelComponent ],
  exports: [ PVPageComponent ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialLoaderModule,
    CanvasJsModule,
    SelectorsModule,
    CommonModule
  ],
  entryComponents: [
    PVConfigDialogComponent
  ]
})
export class PVPageModule { }
