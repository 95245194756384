
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'websites-panel',
  templateUrl: './websites-panel.component.html',
  styleUrls: ['./websites-panel.component.scss']
})
export class WebsitesPanelComponent implements OnInit {
  @Output() onSelect: EventEmitter < { sel: string, value: any } > = new EventEmitter<{ sel: string, value: any }>();
  @Input() hostUpdate: Observable<any>;
  @Output() onOpenSearch: EventEmitter < any > = new EventEmitter<any>();
  @Output() onLeaveSearch: EventEmitter < any > = new EventEmitter<any>();
  hostlink: string = "";
  guestslinks: string[] = [];
  host: any = { name: "", index: 0 };
  guests: any[] = [];
  _onsearch: boolean = false;
  @Input() set onsearch(value: boolean) { this._onsearch = value; }
  get onsearch() {
    return this._onsearch;
  }
  linkEvent: Subject < any > = new Subject<any>()
  constructor() { }

  ngOnInit(): void {
    this.hostUpdate.subscribe((datas) => {
      var host = datas.host;
      var guests = datas.guests;
      // this.linkEvent.next({ sel: "energy-links", value: [{ name: "Energie", index: 0 }] });
      this.linkEvent.next({ sel: "websites-host-list-links", value: [host] });
      // this.linkEvent.next({ sel: "energy-host-vm-links", value: [host] });
      // this.linkEvent.next({ sel: "energy-guest-vm-links", value: guests });
    });
  }
  openSearch() { this.onOpenSearch.emit(null); }
  leaveSearch() { this.onLeaveSearch.emit(null); }
  onSelectionDone(sel: string, event: any) {
    this.onSelect.emit({ sel: sel, value: event });
  }
}