import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';
import { RouterModule } from '@angular/router';

import { DatacenterOverviewPageComponent } from './datacenter-overview-page.component';
import { DatacenterOverviewHeadComponent } from './datacenter-overview-head/datacenter-overview-head.component';
import { DatacenterOverviewPanelComponent } from './datacenter-overview-panel/datacenter-overview-panel.component';
import { DatacenterOverviewGraphsComponent } from './datacenter-overview-graphs/datacenter-overview-graphs.component';
import { LinkTableModule } from '../link-table/link-table.module';
import { HostLinkTableModule } from '../host-link-table/host-link-table.module';

@NgModule({
  declarations: [
    DatacenterOverviewPageComponent,
    DatacenterOverviewHeadComponent,
    DatacenterOverviewPanelComponent,
    DatacenterOverviewGraphsComponent
  ],
  exports : [ DatacenterOverviewPageComponent ],
  imports: [
    CommonModule,
    MaterialLoaderModule,
    CanvasJsModule,
    SelectorsModule,
    LinkTableModule,
    RouterModule,
    HostLinkTableModule
  ]
})
export class DatacenterOverviewModule { }
