import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'battery-head',
  templateUrl: './battery-head.component.html',
  styleUrls: ['./battery-head.component.scss']
})
export class BatteryHeadComponent implements OnInit {
  @Output() onOpenPanel: EventEmitter<{sel:string,value:any}> = new EventEmitter<{sel:string,value:any}>();
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>()
  constructor() { }

  ngOnInit(): void {
  }
  toEms(){
    this.onGoBack.emit();
  }
  openPanel(){
    this.onOpenPanel.emit();
  }
}
