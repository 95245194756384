import { Component, OnInit, EventEmitter, Output,Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'battery-panel',
  templateUrl: './battery-panel.component.html',
  styleUrls: ['./battery-panel.component.scss']
})
export class BatteryPanelComponent implements OnInit {
  @Output() onSelect: EventEmitter<{ sel: string, value: any }> = new EventEmitter<{ sel: string, value: any }>();
  @Output() onSearchEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() searchEvent: Observable<boolean>;
  
  onsearch:boolean = false;
  constructor() { }

  ngOnInit(): void { 
    this.searchEvent.subscribe((onsearch) => {
      this.onsearch = onsearch;
    });
  }
  openSearch() { this.onSearchEvent.emit(true); }
  leaveSearch() { this.onSearchEvent.emit(false); }
  onSelectionDone(sel: string, event: any) { this.onSelect.emit({ sel: sel, value: event }); 
}
}
