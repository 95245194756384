import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'state-view',
  templateUrl: './state-view.component.html',
  styleUrls: ['./state-view.component.scss']
})
export class StateViewComponent implements OnInit {
  @Input() dataEventNext :Observable<any>;
  @Input() idval : string;
  sys_state:number = 0;
  sys_err:number = 0;
  constructor() { }

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.dataEventNext.subscribe((data)=>{
      if(this.idval==data.graph){
        var datas = data.datas;
        if(datas.length > 0)
          if(datas[0].length > 0){
            let elt  = datas[0][0];
            this.sys_state=elt["sys_state"];
            this.sys_err=elt["sys_err"];
          }
        }
      });
    }
}
