import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebsitesService } from '../websites.service';

import { CanvasList, BuildParams } from '@shared/classes/request-manager';
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';
import * as ParamsMaker from '@shared/classes/parameters-maker';
import * as DevData from '@shared/classes/device-data';

const BWLOAD_GRAPH = {
  y_format: {
    sent: StyleSTD.NUMFORMAT.std,
    recieved: StyleSTD.NUMFORMAT.std,
    sent_enr: StyleSTD.NUMFORMAT.std,
    recieved_enr: StyleSTD.NUMFORMAT.std,
    full_enr: StyleSTD.NUMFORMAT.std
  },
  axis_pos: {
    sent: StyleSTD.AXISPOS.pri,
    recieved: StyleSTD.AXISPOS.sec,
    sent_enr: StyleSTD.AXISPOS.pri,
    recieved_enr: StyleSTD.AXISPOS.pri,
    full_enr: StyleSTD.AXISPOS.pri
  },
  y_axis_format: { valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format: { valueFormatString: StyleSTD.NUMFORMAT.std }
}

function initBWLoadParams(sites) {
  return (mesures, initEvent, device) =>{
    initEvent.emit({
      inout: ParamsMaker.initLineParams(["sent", "recieved"], sites, "bwload", device),
    });
  };  
}
function initBWLoadEnrParams(sites) {
  return (mesures, initEvent, device) =>{
    initEvent.emit({
      bwload_enr: ParamsMaker.initLineParams(["sent_enr", "recieved_enr", "full_enr"], sites, "bwload_enr", device)
    });
  };  
}

function getIndexByNumber(devices,num){
  // devices.forEach((device) => {
    
  // });
  for(let i = 0; i < devices.length; i++){
    if(devices[i].num == num)
      return { devindex: devices[i].index, listindex: i };
  } 
  return {devindex:-1,listindex:-1};
}

@Component({
  selector: 'websites-graphs',
  templateUrl: './websites-graphs.component.html',
  styleUrls: ['./websites-graphs.component.scss']
})
export class WebsitesGraphsComponent extends CanvasList {
  @Input() initWebsites: Observable<any>;

  @Output() serv_devs: EventEmitter<any> = new EventEmitter<any>();
  @Input() devChangeEvent: Observable<any>;
  buildEvent: Subject<any> = new Subject<any>();
  dataEventNext: Subject<any> = new Subject<any>();

  idgraphs = ["inout", "bwload_enr"];
  linebuilds: BuildParams[] = [
    { name: "Débits Entrées/Sortie", mode: "bwload", id: "inout", type: "stepArea" },
    { name: "Energie Entrées/Sortie", mode: "bwload_enr", id: "bwload_enr", type: "area" }
  ]

  constructor(private websites: WebsitesService) {
    super();
  }

  ngOnInit(): void {
    this.initWebsites.subscribe((site_datas)=>{
      console.log(site_datas.site);
      console.log(site_datas.vmnum);
      this.websites.get_bwload_devices().subscribe((result)=>{
        let bwloads = result["bwloads"];
        console.log(bwloads);
        let indexs = getIndexByNumber(bwloads, site_datas.vmnum);
        let devindex = indexs.devindex;
        let listindex = indexs.listindex;
        console.log(devindex);
        ParamsMaker.setLineParams(result, "bwloads", "bwload", BWLOAD_GRAPH, initBWLoadParams([site_datas.site]), this.buildEvent, this.initRequest, devindex, listindex);
        ParamsMaker.setLineParams(result, "bwloads", "bwload_enr", BWLOAD_GRAPH, initBWLoadEnrParams([site_datas.site]), this.buildEvent, this.initRequest, devindex, listindex);
      });
    })
  }

  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {
      this.passDatas(event, this.dataEventNext);
    });
  }


}
