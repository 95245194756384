import { GenGraph } from './gen-graph';
import * as CanvasJs from 'src/assets/js-script/canvasjs/canvasjs.min.js';
import * as StyleSTD from '../../style/style-std';
import { AreaLine, Datapoint } from '../canvas-std';


export class LineGraph extends GenGraph {
  public constructor(name:string,mode:string,id:string,type:string){
    super(name,mode,id,type);
  }


  protected initDatas(data:AreaLine[]):void{
    var keys:string[]=Object.keys(this.entries);
      keys.forEach((key)=>{
        data.push(this.initLine(key));
    });
  }
  
  public updateAxes(time:string){
    let datas = this.chart.options.data;
    let i = 1;
    let datapoints = this.chart.options.data[0].dataPoints;
    while(datapoints.length == 0 && i < datas.length){
      datapoints = datas[i].dataPoints
      i++;
    }
    let size = datapoints.length;
    if(size==0)return;
    let start = datapoints[0].x;
    let end = datapoints[size-1].x;
    this.updateAxesFromTime(start.getTime(),end.getTime());

  }

  public updateAxesFromTime(start:number, end:number){
    /*get time string*/
    let interv = (end - start)/1000;
    var time = 'M';
    if(interv < 604801 ){/*1w*/
      time = 'w'
    }
    if(interv < 86401 ){/*1d*/
      time = 'h'
    }
    var dateformat = StyleSTD.TIMEFORMATLIST[time];
    this.changeHourFormat(dateformat);
    this.changeTipHourFormat(time);
    return;
  }


  protected changeHourFormat(format:string){
    this.chart.options.axisX["valueFormatString"] = format;
  }

  protected changeTipHourFormat(format:string){
    if(format == 'h' || format == 'd')
      this.chart.options.toolTip["contentFormatter"] = this.showTipWithHour;
    if(format == 'w')
      this.chart.options.toolTip["contentFormatter"] = this.showTipWithDay;
    if(format == 'M' || format == 'y')
      this.chart.options.toolTip["contentFormatter"] = this.showTipWithDate;
  }

  protected initLine(key:string) : AreaLine {
    // var line :AreaLine = StyleSTD.STDLINECANVAS();
      let entry = this.entries[key];
      let style = this.styles.line[key];
      var line: AreaLine = StyleSTD.STDLINECANVAS(key,entry.comp,entry.red,entry.unit,this.type,entry.color)
      if(style== null){
        this.updateParams(line,StyleSTD.LINEAREASTD(key, this.mode, "", line.color));
      }else{
        this.updateParams(line, StyleSTD.LINEAREAFORMAT(line.id,style.y_format,style.axis_pos,entry.color));
      }
      return line;
  }

  public updateParams(params,inpars){
    let keys = Object.keys(inpars);
    keys.forEach((key)=>{
      params[key]=inpars[key];
    });
  }

  protected showTipWithHour(e){
     return showTipWithFormat(e,StyleSTD.DATEFORMAT.fullhour);
  }

  protected showTipWithDay(e){
    return showTipWithFormat(e,StyleSTD.DATEFORMAT.day);
  }

  protected showTipWithDate(e){
    return showTipWithFormat(e,StyleSTD.DATEFORMAT.date);
  }

  public initChart(target:string, build:any){
    this.styles = build.input_conf.styles;
    this.entries = build.input_conf.lines;
    let options = StyleSTD.STDCHART();
    options["axisX"]= StyleSTD.STDAXIS('X');
    options["axisY"]=StyleSTD.STDAXIS('Y');
    options["axisY2"]=StyleSTD.STDAXIS('Y');
    options["zoomEnabled"]=true;
    options["toolTip"]={
      shared:false,
      contentFormatter: this.showTipWithHour
    }
    if(this.styles==null){
    this.updateParams(options["axisY"],StyleSTD.STDYAXIS);
    this.updateParams(options["axisY2"],StyleSTD.STDYAXIS);
    }else{
      this.updateParams(options["axisY"],this.styles.y_axis_format);
      this.updateParams(options["axisY2"],this.styles.y2_axis_format);
    }
    this.chart =new CanvasJs.Chart(target,
      options
   );
     if(document.getElementById(target).offsetWidth < 500)
      this.policySize(StyleSTD.FNTSIZE.small);
     else
      this.policySize(StyleSTD.FNTSIZE.small)
     this.initDatas(this.chart.options.data);
  }


  public policySize(fntsize:StyleSTD.FNTSIZE){
    this.chart.options.axisX.labelFontSize=fntsize;
    this.chart.options.axisY.labelFontSize=fntsize;
    this.chart.options.axisY2.labelFontSize=fntsize;
    this.chart.options.legend.fontSize=fntsize;
  }


  protected checkAllNull(datas : Datapoint[],ykey:string):boolean{
    for(let i =0; i < datas.length; i++)
      if(datas[i][ykey] != null) return false;
    return true;
  }

  protected addPoints(line : AreaLine ,xkey : string,ykey : string,datas : Datapoint[]){
    if(this.checkAllNull(datas,ykey)){
      line.showInLegend=false;
      return;
    }
    line.showInLegend=true;
    datas.forEach((data)=>{
      line.dataPoints.push({x:new Date(data[xkey]),y:data[ykey],red:line.red,unit:line.unit});
    });
  }


  public getMode(){
    return this.mode;
  }
}

function showTipWithFormat(e:any,format:string):string{
    var data = e.entries[0].dataPoint;
    var serie = e.entries[0].dataSeries;
    return  "<b> ("+
    CanvasJs.formatDate(data.x,format)+
    ')'+data.red+" </b > : "+
    CanvasJs.formatNumber(data.y,serie.yValueFormatString)+
    ""+data.unit;
}
