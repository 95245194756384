import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { VisGraphModule } from './vis-graph/vis-graph.module';

import { EMSGraphComponent } from './ems-graph.component'
import { SelectActionDialogComponent } from './ems-dialog/select-action-dialog/select-action-dialog.component';
import { SwitchConfirmDialogComponent } from './ems-dialog/switch-confirm-dialog/switch-confirm-dialog.component';
import { ModeSelectComponent } from './mode-select/mode-select.component';

@NgModule({
  declarations: [ EMSGraphComponent, SelectActionDialogComponent, ModeSelectComponent ],
  exports: [ EMSGraphComponent ],
  imports: [
    MaterialLoaderModule,
    VisGraphModule,
    CommonModule
  ],
  entryComponents: [
    SwitchConfirmDialogComponent,
    SelectActionDialogComponent,
  ]
})
export class EMSGraphModule { }
