<div class="datacenter-vm-panel">
  <mat-card class="panel-title">
    <div class="title-div"><div class="txt-div">Détails Machines Virtuelle</div></div>
  </mat-card>
  <div class="panel-link-tab">
      <link-table idlinks="vm-energy-links" title="Données Energie" linkbase="/ems/datacenter/energy" [linkEvent]="linkEvent.asObservable()"></link-table>
  </div>
  <div class="panel-link-tab">
    <host-link-table idlinks="vm-host-list-links" title="Liste Serveurs" linkbase="/ems/datacenter/overview" [linkEvent]="linkEvent.asObservable()"></host-link-table>
  </div>
  <div class="panel-link-tab">
    <link-table idlinks="vm-host-vm-links" title="Détails Serveur" linkbase="/ems/datacenter/vm" [linkEvent]="linkEvent.asObservable()"></link-table>
  </div>
  <div class="panel-link-tab">
      <link-table idlinks="vm-guest-vm-links" title="Machines Virtuelles" linkbase="/ems/datacenter/vm" [linkEvent]="linkEvent.asObservable()"></link-table>
  </div>
  <div class="panel-link-tab">
    <link-table idlinks="vm-sites-links" title="Sites Web" linkbase="/ems/datacenter/vmsites"
      [linkEvent]="linkEvent.asObservable()"></link-table>
  </div>
  <mat-card class="panel-select-card">
    <div class="title-div">
      <div class="txt-div"> Paramètres </div>
    </div>
    
    <div *ngIf="onsearch; then thenSelTimeBlock; else elseSelTimeBlock"></div>
    <ng-template #thenSelTimeBlock></ng-template>
    <ng-template #elseSelTimeBlock>
      <div class="panel-select-div">
        <div class="selector-div">
          <div class="sub-title-div">
            <div class="sub-txt-div"> Période </div>
          </div>
          <div class="selector-obj">
            <time-selector class="selector" (selected)="onSelectionDone('time',$event)">
            </time-selector>
          </div>
        </div>
      </div>
    </ng-template>
    
    <div class="panel-search-div">
      <div class="search-div">
        <div *ngIf="onsearch; then thenSearchBlock; else elseSearchBlock"></div>
        <ng-template #thenSearchBlock>
          <div class="sub-title-div">
            <div class="sub-txt-div"> Recherche En Cours</div>
          </div>
          <div class="search-obj">
            <button class="search-btn" (click)="leaveSearch()"> Stop </button>
          </div>
        </ng-template>
        <ng-template #elseSearchBlock>
          <div class="sub-title-div">
            <div class="sub-txt-div"> Recherche </div>
          </div>
          <div class="search-obj">
            <button class="search-btn" (click)="openSearch()"> Recherche</button>
          </div>
        </ng-template>
      </div>
    </div>
  </mat-card>
</div>
