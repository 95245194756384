import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {  Observable, Subject  } from 'rxjs';

@Component({
  selector: 'datacenter-energy-panel',
  templateUrl: './datacenter-energy-panel.component.html',
  styleUrls: ['./datacenter-energy-panel.component.scss']
})
export class DatacenterEnergyPanelComponent implements OnInit {
  @Output() onSelect: EventEmitter<{sel:string,value:any}> = new EventEmitter<{sel:string,value:any}>();
  @Input() hostUpdate : Observable<any>;
  @Output() onOpenSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLeaveSearch: EventEmitter<any> = new EventEmitter<any>();
  hostlink:string="";
  guestslinks:string[]=[];
  host:any={name:"",index:0};
  guests:any[]=[];
  _onsearch:boolean=false;
  @Input() set onsearch(value: boolean) { this._onsearch = value; }
  get onsearch() {
    return this._onsearch;
  }
  linkEvent : Subject<any> = new Subject<any>()
  constructor() { }

  hostTabToParams(hosts) {
    let selpar = []
    hosts.forEach((host) => {
      selpar.push({ name: host.name, params: { indexvm: host.index } })
    });
    return selpar;
  }


  ngOnInit(): void {
    this.hostUpdate.subscribe((datas)=>{
      var hosts = datas.hosts;
      var guests = datas.guests;
      this.linkEvent.next({ sel: "energy-links", value: this.hostTabToParams([{ name: "Energie", index: 0 }])});
      this.linkEvent.next({sel:"energy-host-list-links",value:this.hostTabToParams(hosts)});
      this.linkEvent.next({sel:"energy-host-vm-links",value:this.hostTabToParams(hosts)});
      this.linkEvent.next({ sel: "energy-guest-vm-links", value: this.hostTabToParams(guests)});
    });
  }
  openSearch() { this.onOpenSearch.emit(null); }
  leaveSearch() { this.onLeaveSearch.emit(null); }
  onSelectionDone(sel:string,event:any){
    this.onSelect.emit({sel:sel,value:event});
  }

}
