import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RequestManager, RequestPage, InitParams } from '@shared/classes/request-manager';
import { DatacenterOverviewService } from './datacenter-overview.service';

@Component({
  selector: 'datacenter-overview-page',
  templateUrl: './datacenter-overview-page.component.html',
  styleUrls: ['./datacenter-overview-page.component.scss']
})
export class DatacenterOverviewPageComponent extends RequestPage {
  opened: boolean = false;
  hostUpdate: Subject<any> = new Subject<any>();

  constructor(private router: Router, protected datacenter_overview: DatacenterOverviewService) {
    super(datacenter_overview);
  }
  selectionDone(event: { sel: string, value: any }) {
    // console.log(event);
    // this.opened = false;
    // switch (event.sel) {
    //   case 'time':
    //     this.manager.selectTime(event.value, (req, requestlist, orig, graphid) => { return true; });
    //     this.manager.requestPoints("main", (req, requestlist, key, key2) => { return true; })
    //     break;
    //   case 'chanel':
    //     this.manager.selectFields(event.value, (req, requestlist, orig, graphid) => {
    //       return req.mode == "conso";
    //     });
    //     this.manager.requestPoints("main", (req, requestlist, key, key2) => { return req.mode == "conso"; })
    //     break;
    //   default: break;
    // }
    // this.setRefresh();
  }


  ngOnInit(): void {
  }

  openPanel() {
    this.opened = true;
  }

  toEms() {
    this.router.navigate(['/ems/graph']).then(e => {
      if (e) { console.log("Navigation success!"); }
      else { console.log('Navigation failed :()'); }
    });
  }

  ngAfterViewInit(){
    this.setRefresh();
  }
  onGetHost(event) {
    this.hostUpdate.next(event);
  }
}
