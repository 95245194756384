import * as Canv from '@shared/modules/canvas-js/types/canvas-entries';
import * as Dev from './device-data';

export function toLineStyleEntry(mesures:Dev.PSQLMesureList,styleconf:any):Canv.LineStyleEntries{
  var styles : Canv.LineStyleEntries = {
    line:{},
    y_axis_format:styleconf.y_axis_format,
    y2_axis_format:styleconf.y2_axis_format
  }
  var mes_keys:string[] = Object.keys(mesures);
  mes_keys.forEach((mes_key) => {
    let mesure : Dev.PSQLMesure = mesures[mes_key];
    let channels: Dev.PSQLChannelList = mesure.channels;
    let chan_keys : string[] = Object.keys(channels);
    chan_keys.forEach((chan_key) => {
      let channel : Dev.PSQLChannel = channels[chan_key];
      let key = mes_key+chan_key;
      styles.line[key]={
        y_format:styleconf.y_format[mes_key],
        axis_pos:styleconf.axis_pos[mes_key]
      }
    });
  });
  return styles;
}

export function psqlMesListToLineEntries(mesures:Dev.PSQLMesureList) : Canv.LineEntries{
  var entries : Canv.LineEntries = {}
  var mes_keys:string[] = Object.keys(mesures);
  mes_keys.forEach((mes_key) => {
    let mesure : Dev.PSQLMesure = mesures[mes_key];
    let channels: Dev.PSQLChannelList = mesure.channels;
    let chan_keys : string[] = Object.keys(channels);
    chan_keys.forEach((chan_key) => {
      let channel : Dev.PSQLChannel = channels[chan_key];
      let psqlcol:Dev.PSQLColor = channel.col;
      var key:string =mes_key+chan_key;
      let entry:Canv.LineEntry= {
        comp:mesure.name+" "+channel.comp,
        red:mesure.red_name+" "+channel.red,
        unit:mesure.unit,
        mes:mes_key,
        chan:chan_key,
        color:"rgb("+psqlcol.r+","+psqlcol.g+","+psqlcol.b+")"
      }
      entries[key]=entry;
    });
  });
  return entries;
}

export function psqlMesListToDoughnutEntries(mesures:Dev.PSQLMesureList) : Canv.DoughnutEntries{
  var entries : Canv.DoughnutEntries = {}
  var mes_keys:string[] = Object.keys(mesures);
  mes_keys.forEach((mes_key) => {
    let mesure : Dev.PSQLMesure = mesures[mes_key];
    let channels: Dev.PSQLChannelList = mesure.channels;
    let chan_keys : string[] = Object.keys(channels);
    chan_keys.forEach((chan_key) => {
      let channel : Dev.PSQLChannel = channels[chan_key];
      let psqlcol:Dev.PSQLColor = channel.col;
      var key:string =mes_key+chan_key;
      let entry:Canv.DoughnutEntry= {
        comp:mesure.name+" "+channel.comp,
        red:mesure.red_name+" "+channel.red,
        unit:mesure.unit,
        mes:mes_key,
        chan:chan_key,
        color:"rgb("+psqlcol.r+","+psqlcol.g+","+psqlcol.b+")"
      }
      entries[key]=entry;
    });
  });
  return entries;
}

function buildNameField(mes:string, chan:string, show_mes:boolean, show_chan:boolean){
  if(!show_chan && !show_mes)return "";
  if(!show_mes) return chan;
  if(!show_chan) return mes;
  return mes+" "+chan;
}

export function psqlMesListToBarEntries(mesures: Dev.PSQLMesureList, style:any): Canv.BarEntries {
  var entries: Canv.BarEntries = {}
  var mes_keys: string[] = Object.keys(mesures);
  mes_keys.forEach((mes_key) => {
    let mesure: Dev.PSQLMesure = mesures[mes_key];
    let channels: Dev.PSQLChannelList = mesure.channels;
    let chan_keys: string[] = Object.keys(channels);
    chan_keys.forEach((chan_key) => {
      let channel: Dev.PSQLChannel = channels[chan_key];
      let psqlcol: Dev.PSQLColor = channel.col;
      var key: string = mes_key + chan_key;
      var showcomp = style.showcomp;
      var showred = style.showred;
      let entry: Canv.BarEntry = {
        comp: buildNameField(mesure.name, channel.comp ,showcomp.mes, showcomp.chan),
        red: buildNameField(mesure.red_name, channel.red ,showred.mes, showred.chan),
        mes:mes_key,
        chan:chan_key,
        unit: mesure.unit,
        color: "rgb(" + psqlcol.r + "," + psqlcol.g + "," + psqlcol.b + ")"
      }
      entries[key] = entry;
    });
  });
  return entries;
}
