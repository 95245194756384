import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NodeJsonBase } from '@shared/modules/config/node-std';
import { ConfigLoaderService } from '@shared/modules/config/config-loader.service';
export interface UserNode {
  name:string,
  password:string
}
export interface ConnectResponse{
  pkm:string
}
@Injectable({
  providedIn: 'root'
})
export class ConnectService extends NodeJsonBase {

  constructor(protected http : HttpClient, protected cls : ConfigLoaderService) {
    super(http,cls)
  }

  connect(user:UserNode) : Observable<ConnectResponse> {
    var httpOptions = this.buildOptions(true,this.server_url,user);
    return this.http.post<ConnectResponse>(this.server_url+'/login', httpOptions).pipe(
      tap(_ => {
        this.log('fetched pokemon');
        console.log('oki');
    }),
      catchError(this.handleError<ConnectResponse>('connect', ))
    );
  }

  logout():Observable<{data:string}>{
    var httpOptions = this.buildOptions(true,this.server_url,null);
    return this.http.get<{data:string}>(this.server_url+'/logout', httpOptions).pipe(
      tap(_ => {
        this.log('fetched pokemon');
        console.log('oki');
    }),
      catchError(this.handleError<{data:string}>('connect', ))
    );
  }
}
