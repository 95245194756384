import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'inverter-pv-page',
  template: '<div class="inverter-pv-page"><inverter-pv-view></inverter-pv-view></div>',
  styles: ['.inverter-pv-page{width:100%; height:100%; overflow:auto;}']

})
export class InverterPVPageComponent implements OnInit {
  idpage:number=4;

  constructor() {}
  ngOnInit() { }
}
