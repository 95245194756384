import { Component, Input } from '@angular/core';
import { Observable, Subject  } from 'rxjs';
import { DCDCService } from '../dcdc.service'
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';
import { CanvasList, RequestBase } from '@shared/classes/request-manager';
import { BuildParams } from '@shared/modules/canvas-js/types/graph-builder';
import * as ParamsMaker from '@shared/classes/parameters-maker';
import * as DevData from '@shared/classes/device-data';

const VARIO_GRAPH = {
  y_format:{
    current:StyleSTD.NUMFORMAT.std,
    power:StyleSTD.NUMFORMAT.std,
    voltage:StyleSTD.NUMFORMAT.std,
    ubat:StyleSTD.NUMFORMAT.std,
    ibat:StyleSTD.NUMFORMAT.std
  },
  axis_pos:{
    current:StyleSTD.AXISPOS.pri,
    power:StyleSTD.AXISPOS.pri,
    voltage:StyleSTD.AXISPOS.pri,
    ubat:StyleSTD.AXISPOS.sec,
    ibat:StyleSTD.AXISPOS.pri
  },
  y_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std }
}

const SUNH_DOUGHNUT = {
  tip_format:"<b>{red}</b>: {y}{unit} (#percent%)",
  y_format: StyleSTD.NUMFORMAT.dec
}

function initVarioParams(mesures, initEvent, device) {
  initEvent.emit({
    power: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures, "power"), ["pv1","pv2"], "vario/pow"),
    current: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures, "current"), ["pv1","pv2"], "vario/cur"),
    voltage: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures, "voltage"), ["pv1","pv2"], "vario/volt"),
    out: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures, "ubat"), ["ubat","ibat"], "vario/out")
  });
}

function initSunHDoughParams(mesures, initEvent,device){
  initEvent.emit({
    pietdsunh: initDoughnutParams(["td"], ["td"],  "vario/lastsunh"),
    pieydsunh: initDoughnutParams(["yd"], ["yd"],  "vario/lastsunh")
  });
}

function initSunHTabParams(mesures, initEvent, device) {
  initEvent.emit({
    tabtdsunh: initDoughnutParams(["td"], ["td"], "vario/lastsunh"),
    tabydsunh: initDoughnutParams(["yd"], ["yd"], "vario/lastsunh")
  });
}
function initDoughnutParams(sel,major,mode){
  return {select:sel,time:0,timetype:"nogroup",numbers:major,device:0,mode:mode}
}

@Component({
  selector: 'dcdc-graphs',
  templateUrl: './dcdc-graphs.component.html',
  styleUrls: ['./dcdc-graphs.component.scss']
})
export class DCDCGraphsComponent extends CanvasList   {
  buildEvent : Subject<any> = new Subject<any>();
  dataEventNext : Subject<any> = new Subject<any>();;
  @Input() selectEvent :Observable<{sel:string,value:any}>;
  idgraphs = ['power', 'current', 'voltage', 'out'];
  iddoughnuts = ['pietdsunh', 'pieydsunh'];
  idtabs = ['tabtdsunh', 'tabydsunh'];

  linebuilds : BuildParams[] = [
    { name: "Puissance PV", mode: "vario", id:'power',type:'area'},
    {name:"Courant PV",mode:"vario",id:'current',type:'area'},
    { name: "Tension PV", mode: "vario", id:'voltage',type:'area'},
    { name: "Sorties MPPT", mode: "vario", id:'out',type:'area'}
  ]
 
  doughnutbuilds : BuildParams[] = [
    { name: "Ensoleillement d'aujourd'hui", mode: "pievario", id:'pietdsunh',type:'doughnut'},
    { name: "Ensoleillement d'hier", mode: "pievario", id:'pieydsunh',type:'doughnut'},
  ]

  mmabuilds: BuildParams[] = [
    { name: "Ensoleillement d'aujourd'hui", mode: "pievario", id: 'tabtdsunh', type: 'tab' },
    { name: "Ensoleillement d'hier", mode: "pievario", id:'tabydsunh',type:'tab'},
  ]


  doughnutreqparams : RequestBase[] ;
  linereqparams : RequestBase[] ;
  mmareqparams : RequestBase[] ;
  constructor( private dcdc : DCDCService){
    super();
  }

  ngOnInit() {
    this.dcdc.get_usr_vario_device().subscribe((varios)=>{
      if (varios["varios"].length > 0){
        ParamsMaker.setLineParams(varios, "varios", "vario", VARIO_GRAPH, initVarioParams, this.buildEvent, this.initRequest, 0);
        ParamsMaker.setTabParams(varios, "varios", "pievario", initSunHTabParams, this.initRequest, 0);
        ParamsMaker.setDoughParams(varios, "varios", "pievario", SUNH_DOUGHNUT, initSunHDoughParams, this.buildEvent, this.initRequest, 0);
      }
    });
  }

  ngAfterViewInit(){
    this.dataEvent.subscribe((event)=>{
      console.log(event);
      this.passDatas(event,this.dataEventNext);
    });
  }
}
