import { GenGraph } from './gen-graph';
import * as CanvasJs from 'src/assets/js-script/canvasjs/canvasjs.min.js';
import * as CanvasSTD from '../canvas-std';
import * as StyleSTD from '../../style/style-std';
export class BarGraph extends GenGraph {
  public constructor(name:string,mode:string,id:string,type:string){
    super(name,mode,id,type);
  }
  protected addPoints(line : CanvasSTD.CanvasLine ,xkey : string,ykey : string,datas : CanvasSTD.Datapoint[]):void{
    datas.forEach((data)=>{
      var names = this.entries[data[xkey]];
      line.dataPoints.push({label:names.red,comp:names.comp,y:data[ykey],color:names.color,red:names.red,unit:names.unit});
    });
    return;
  };

  public initChart(target:string,build:any) : void {
    
    this.styles = build.input_conf.styles;
    this.entries = build.input_conf.lines;
    let options = StyleSTD.STDBARCHART(this.type,this.styles.y_format[this.id]);
    this.chart = new CanvasJs.Chart(target,options);
    this.initDatas(this.chart.options.data);
  }

  protected initLine(key:string) : any {
    if(this.styles!=null){
      return StyleSTD.STDBARFORMAT(key, this.type, this.styles.axisy_pos,this.styles.toolTipContent,this.styles.y_format[this.id],this.styles.index_label);
    }
    return  StyleSTD.STDBARFORMAT(key);
  }

  protected initDatas(data:any[]):void {
      data.push(this.initLine('y'));
  }

  public controlDataValidity(datas : CanvasSTD.Datapoint[][]) : boolean{
    if(super.controlDataValidity(datas)){
      for(var i = 0; i < datas.length; i++){
        for(var j = 0; j < datas[i].length;j++){
          if(datas[i][j].y!=0 && datas[i][j].y!=null)return true;
        }
        return false;
      }
    } else{ return false; }
  }

}
