import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';

import { DCDCPageComponent } from './dcdc-page.component';
import { DCDCGraphsComponent } from './dcdc-graphs/dcdc-graphs.component';
import { DCDCHeadComponent } from './dcdc-head/dcdc-head.component';
import { DCDCPanelComponent } from './dcdc-panel/dcdc-panel.component';
import { DcdcStateComponent } from './dcdc-state/dcdc-state.component';
import { DcdcListComponent } from './dcdc-list/dcdc-list.component';

@NgModule({
  declarations: [DCDCPageComponent, DCDCGraphsComponent, DCDCHeadComponent, DCDCPanelComponent, DcdcStateComponent, DcdcListComponent ],
  exports: [ DCDCPageComponent ],
  imports: [
    MaterialLoaderModule,
    CommonModule,
    CanvasJsModule,
    SelectorsModule
  ]
})
export class DCDCPageModule { }
