import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HostLinkTableComponent } from './host-link-table.component';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HostLinkTableComponent],
  exports: [HostLinkTableComponent],
  imports: [
    CommonModule,
    MaterialLoaderModule,
    RouterModule
  ]
})
export class HostLinkTableModule { }
