export interface LogDatas{
  time:string;
  part:number;
  warn_lvl:number;
  short_desc:string;
  description:string;
}
export const DEVS = [
  'PV', 'MPPT', 'Batterie', 'Onduleur PV',
  'Réseau', 'Transfo Isolement',
  'PAC', 'Electrolyseur', 'Onduleur H2',
  'Datacenter',
  'Borne', 'Prises Scierie', 'Eclairage', 'Refroidissement',
  'Utilité', 'Synapse', 'Synapse', 'EMS'
]

export const SHORTS = {
  desc1 :"a long desc",
  desc2 :"long and short",
  desc3 :"A or B cause"
}

export interface LogEvent{
 warnlvl : number | null;
 part : string;
 shortdesc:string;
 description : string;
}

export class LogMsg {
  date : string | null;
  event :LogEvent;

  constructor (date:string|null,warnlvl:number|null,part:string,shortdesc:string,description:string){
    this.date=date;
    this.event={
      warnlvl:warnlvl,
      part:part,
      description:description,
      shortdesc:shortdesc
    }
  }

  public static VoidLog() : LogMsg{
    return new LogMsg(null,null,"","","");
  }
}
