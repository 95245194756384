import { Component, OnInit } from '@angular/core';
import { SelectorBase,SelectorDefinition } from '../selector-base';
// import { SensorList } from 'src/app/dataviews/const-canvas/const-canvas';

export const STDSENSORLIST: () => SelectorDefinition<string[]> = ()=>{
  return new SelectorDefinition<string[]>({
    "Toutes Mesures": ["tem", "hum", "light", "cov", "co2"],
    "Température": ["tem"],
    "Humidité": ["hum"],
    "Lumière": ["light"],
    "COV": ["cov"],
    "CO2": ["co2"]
  })};


@Component({
  selector: 'sensor-selector',
  templateUrl: './sensor-selector.component.html',
  styleUrls: ['./sensor-selector.component.scss']
})
export class SensorSelectorComponent extends SelectorBase<any> {
  constructor() { super(); }

  ngOnInit() {
    this.list = STDSENSORLIST().tab;
    this.sel = this.list[0];
  }

  getSelected(sel: string): any { STDSENSORLIST().list[sel] }
}
