<div class="page">
  <div class="menu">
    <mat-slide-toggle class="slide-refresh"(change)="onSwitch()" [checked]="refreshOn">Refresh</mat-slide-toggle>
    <div class="space"></div>
    <button mat-card class="logout-btn" (click)="onLogout()"><img class="img-btn" src="assets/img/ico/logout.png" alt="Déconnexion"></button>
  </div>
  <mat-card class="sub-page mat-elevation-z8" layout>
    <div class="ems-card">
      <router-outlet (activate)="onPageChange($event)"></router-outlet>
    </div>
    <div class="log-card">
      <log-view [refreshEvent]="refreshEvent.asObservable()" [pageChangeEvent]="pageChangeEvent.asObservable()"></log-view>
    </div>
  </mat-card>
</div>
