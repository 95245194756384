<div mat-dialog-content class="dialog">
  <h2 mat-dialog-title class="title">Paramètres :</h2>
  <div mat-dialog-content class="view-panel">
    <form [formGroup]="options" class="form-options">
      <mat-form-field>
        <mat-label>Selectinner un mode</mat-label>
        <mat-select formControlName="modh_func">
          <mat-option value="0">Arrêt</mat-option>
          <mat-option value="1">Marche</mat-option>
          <mat-option value="2">PAC</mat-option>
          <mat-option value="3">ELY</mat-option>
          <mat-option value="4">Veille</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="param-div">
        <mat-form-field class="param-field">
          <input class="param-input" matInput placeholder="Consigne" formControlName="modh_value">
        </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="button-part">
    <button mat-button class="btn-yes" (click)="onCancel()">Annuler</button>
    <div class="space"></div>
    <button mat-button class="btn-no" (click)="onApply()">Appliquer</button>
  </div>
</div>
