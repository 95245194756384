import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { H2ParamsDialogComponent } from './h2-dialog/h2-params-dialog/h2-params-dialog.component'
import { H2Service } from './h2.service';
import { RequestPage, buildSearchParams } from '@shared/classes/request-manager';
import { SearchPopUpComponent } from '@shared/modules/dialog/search-pop-up/search-pop-up.component'


@Component({
  selector: 'h2-page',
  templateUrl: './h2-page.component.html',
  styleUrls: ['./h2-page.component.scss']
})
export class H2PageComponent extends RequestPage {
  idpage:number=7;
  modh_func:number = 0;
  modh_value:number = 0;
  opened: boolean = false;
  onsearch: boolean = false;

  constructor(private router :Router, private h2 : H2Service, public dialog : MatDialog){
    super(h2);
  }

  selectionDone({sel:string,value:any}){}

  ngOnInit() {  }
  toEms(){
    this.router.navigate(['/ems/graph']).then(e => {
        if(e){ console.log("Navigation success!"); }
        else{ console.log('Navigation failed :()'); }
      });
  }

  paramsUpdated(event){
    this.modh_func=event.func;
    this.modh_value=event.val;
  }

  leaveSeach(event) {
    this.onsearch = false;
    this.opened = false;
    this.manager.requestPoints("main", (req, requestlist, key, key2) => { return true; });
    this.setRefresh();
  }

  openSearch(event) {
    const dialogRef = this.dialog.open(SearchPopUpComponent, {
      autoFocus: true
    });
    this.stopRefresh();
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.onsearch = true;
        this.opened = false;
        console.log(result);
        var tmppar = buildSearchParams(null, result.time, result.group, null);
        this.manager.selectSearch(tmppar, (req, requestlist, orig, graphid) => {
          return true;
        });
        this.manager.requestPoints("search", (req, requestlist, key, key2) => { return true; });
      }
      else {
        console.log("cancel");
      }
    });
  }

  changeParams(){
    const dialogRef = this.dialog.open(H2ParamsDialogComponent, {
      autoFocus:true,
      data:{modh_func:this.modh_func+"",modh_value:this.modh_value}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result!=null){
        console.log(result);
        this.h2.setH2Param(result).subscribe((resp)=>{});
      }
      else{
        console.log("cancel");
      }
    });
  }
  ngAfterViewInit(){
    this.setRefresh();
  }

  openPanel(){
    this.opened=true;
  }
}
