<!-- <div mat-dialog-content class="select-dialog"> -->
<div class="select-dialog">
  <div class="dialog-view">
    <div class="dialog-title">Interrupteur :</div>
    <div class="switch-view">
      <div>ID : {{data.id}}</div>
      <div>Nom : {{data.Nom}}</div>
    </div>
    <div class="switch-button">
      <button mat-button class="btn-open" (click)="onOpen()">Ouvrir</button>
      <div class="space"></div>
      <button mat-button class="btn-close" (click)="onClose()">Fermer</button>
    </div>
  </div>
</div>
