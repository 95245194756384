import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphLoaderService } from './graph-conf/graph-loader.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    GraphLoaderService, {
      provide : APP_INITIALIZER,
      useFactory : (config)=>{return ()=>config.ensureInit();},
      deps : [GraphLoaderService, HttpClient],
      multi:true
    }
  ],
})
export class DataviewsModule { }
