import { Component, OnInit,Input } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { GenTab } from './types/gen-tab'

interface BuildParams {
  name:string,
  mode:string,
  id:string,
  type:string
}

interface RequestBase {
  select:string[],
  time:number,
  timetype:string,
  devices:number[]
}
@Component({
  selector: 'mma-table',
  templateUrl: './mma-table.component.html',
  styleUrls: ['./mma-table.component.scss']
})
export class MmaTableComponent implements OnInit {
  @Input() build : BuildParams;//target mmatab
  @Input() dataEventNext :Observable<any>;
  @Input() idval : string;
  mmatab : GenTab;
  displayedColumns: string[] = ['min', 'moy', 'max'];
  min = "0";
  mean = "0";
  max = "0";
  constructor() { }
  ngOnInit() {
    this.mmatab = new GenTab(this.build.name,this.build.mode,this.build.id);
  }

  updateDatas(min,mean,max){
      this.mmatab.updateTab(min,mean,max);
      this.min = this.mmatab.min;
      this.mean = this.mmatab.mean;
      this.max = this.mmatab.max;
  }

  ngAfterViewInit() {
    this.dataEventNext.subscribe((data)=>{
      if(this.idval==data.graph){
        if(this.mmatab.controlDataValidity(data.datas)){
          let value =data.datas[0][0];
          this.updateDatas(value.min,value.mean,value.max);
        }else{
          // console.log("No data recieved");
          this.mmatab.noDataPoints();
        }
      }
    });
  }
}
