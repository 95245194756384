<div class="battery-state">
    <div class="state-field-list">
        <div class="state-field-div" *ngFor="let field of fieldnames">
            <div class="field-title">
                <div class="fieldtxt"> {{fields[field].title}} </div>
            </div>
            <div class="field-value">
                <div class="valuetxt" [ngStyle]="{'background-color': getStateColor(fields[field])}"></div>
            </div>
        </div>
    </div>
</div>
