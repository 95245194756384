<div class="elec-plugs page">
  <mat-sidenav-container class="elec-plugs-container">
    <mat-sidenav class="elec-plugs-sidenav" mode="over" [(opened)]="opened">
      <elec-plugs-panel (onSearchEvent)="onSearch($event)" (onSelect)="selectionDone($event)" [searchEvent]="searchEvent.asObservable()">
      </elec-plugs-panel>
    </mat-sidenav>
    <mat-sidenav-content class="elec-plugs-content">
      <div class="sub-header">
        <elec-plugs-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></elec-plugs-head>
      </div>
      <div class="page-content">
        <elec-plugs-graphs [idlist]="'lines'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('lines',$event)">
        </elec-plugs-graphs>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
