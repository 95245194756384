import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NodeJsonBase } from '@shared/modules/config/node-std';
import { ConfigLoaderService } from '@shared/modules/config/config-loader.service';
import { SwitchDatas } from './vis-graph/vis-sys/emstypes';

interface NumNode{
  numnode:number;
}


@Injectable({
  providedIn: 'root'
})
export class EMSService extends NodeJsonBase {
 
 
  constructor(protected http : HttpClient, protected cls : ConfigLoaderService) {
    super(http,cls)
  }

  postSwitch(swh:{id:number,order:boolean}) : Observable<boolean[]> {
    var httpOptions = this.buildOptions(false,this.server_url,{swh:swh});
    return this.http.post<boolean[]>(this.server_url+'/switch', httpOptions).pipe(
      tap(_ => { }),
      catchError(this.handleError<boolean[]>('postNumNode', ))
    );
  }

  getSwitch() : Observable<{switches:SwitchDatas[]}> {
    var httpOptions = this.buildOptions(false,this.server_url,null);
    return this.http.get<{switches:SwitchDatas[]}>(this.server_url+'/switch', httpOptions).pipe(
      tap(_ => {}),
      catchError(this.handleError<{switches:SwitchDatas[]}>('postNumNode', ))
    );
  }

  postNode(num:number) : Observable<NumNode> {
    var httpOptions = this.buildOptions(false,this.server_url,{numnode:num});
    return this.http.post<NumNode>(this.server_url+'/node', httpOptions).pipe(
      tap(_ => { }),
      catchError(this.handleError<NumNode>('postNumNode', ))
    );
  }

  postModeSet(mode:string){
    var httpOptions = this.buildOptions(false, this.server_url, { mode: mode });
    return this.http.post<NumNode>(this.server_url + '/synmode', httpOptions).pipe(
      tap(_ => { }),
      catchError(this.handleError<NumNode>('postNumNode',))
    );
  }

  getModeGet() {
    var httpOptions = this.buildOptions(false, this.server_url, null);
    return this.http.get<NumNode>(this.server_url + '/emsconf/getmode', httpOptions).pipe(
      tap(_ => { }),
      catchError(this.handleError<NumNode>('postNumNode',))
    );
  }

  check_rights(id: number) {
    var httpOptions = this.buildOptions(false, this.server_url, { swh: id });
    return this.http.post<boolean[]>(this.server_url + '/rights/switch/check', httpOptions).pipe(
      tap(_ => { }),
      catchError(this.handleError<boolean[]>('check_rights',))
    );
  }

  check_node(node: string) {
    var httpOptions = this.buildOptions(false, this.server_url, {place:"Avignon", part:node});
    return this.http.post<any[]>(this.server_url + '/siterights/node/check', httpOptions).pipe(
      tap(_ => { }),
      catchError(this.handleError<any[]>('check_node',))
    );
  }
}
