import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';
import { RouterModule } from '@angular/router';
import { DatacenterVMPageComponent } from './datacenter-vm-page.component';
import { DatacenterVMHeadComponent } from './datacenter-vm-head/datacenter-vm-head.component';
import { DatacenterVMPanelComponent } from './datacenter-vm-panel/datacenter-vm-panel.component';
import { DatacenterVMGraphsComponent } from './datacenter-vm-graphs/datacenter-vm-graphs.component';
import { LinkTableModule } from '../link-table/link-table.module';
import { HostLinkTableModule } from '../host-link-table/host-link-table.module';

@NgModule({
  declarations: [
    DatacenterVMPageComponent,
    DatacenterVMHeadComponent,
    DatacenterVMPanelComponent,
    DatacenterVMGraphsComponent
  ],
  exports : [ DatacenterVMPageComponent ],
  imports: [
    CommonModule,
    MaterialLoaderModule,
    CanvasJsModule,
    SelectorsModule,
    RouterModule,
    LinkTableModule,
    HostLinkTableModule
  ]
})
export class DatacenterVMModule { }
