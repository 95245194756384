import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';

const REDSTATE:string = "rgb(255, 80, 80)";
const GREENSTATE:string = "rgb(102, 255, 153)";
const ORANGESTATE:string = "rgb(255, 136, 77)";
const GRAYSTATE:string = "rgb(166, 166, 166)";

interface InvFieldList{
  [name: string]: {
    title:string;
    value:boolean;
    coloron:string;
    coloroff:string;
  }
}


function initLineParams(selects, majors, mode) {
  return { "select": selects, "time": 0, "timetype": "nogroup", "numbers": majors, device: 0, mode: mode }
}

function initBatteryParams(initEvent) {
  initEvent.emit({
    state: initLineParams([0, 1, 2, 3], ["0"], "batterylast_state")
  });
}

@Component({
  selector: 'battery-state',
  templateUrl: './battery-state.component.html',
  styleUrls: ['./battery-state.component.scss']
})
export class BatteryStateComponent implements OnInit {
  @Output() initRequest: EventEmitter<any> = new EventEmitter<any>();
  @Input() dataEvent: Observable<any>;
  @Input() idlist:string;
  fields: InvFieldList = {
    "stateempty": {
      title: "Batterie Vide",
      value: false,
      coloron:ORANGESTATE,
      coloroff:GRAYSTATE
    },

    "statefull": {
      title: "Batterie Pleine",
      value: false,
      coloron:ORANGESTATE,
      coloroff:GRAYSTATE
    },

    "statecharging": {
      title: "En Charge",
      value: false,
      coloron:GREENSTATE,
      coloroff:GRAYSTATE
    },

    "stateerror": {
      title: "Erreur",
      value: false,
      coloron:REDSTATE,
      coloroff:GRAYSTATE
    }
  }
  fieldnames = Object.keys(this.fields);

  constructor() {
  }

  getStateColor(field) {
    return  field["value"] ? field["coloron"] : field["coloroff"];
  }

  ngOnInit(): void { initBatteryParams(this.initRequest); }

  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {
      if (event.orig == "batstate") {
        console.log(event.datas);
        let datas = event.datas;
        if (datas != null) {
          if (datas.length > 0) {
            if (datas[0] != null) {
              if (datas[0].length > 0) {
                if (datas[0][0] != null) {
                  console.log(datas[0][0]);
                  let keys = Object.keys(this.fields);
                  keys.forEach((key) => {
                    if (key in datas[0][0]) {
                      this.fields[key].value = datas[0][0][key];
                    }
                  });
                }
              }
            }
          }
        }
      }
    });
  }
}
