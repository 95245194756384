export interface Mes {
  mes : string,
  comp:string,
  red: string,
  unit: string
}

export interface ShowName {
  mes : boolean,
  num : boolean
}

export interface Num {
  num : number,
  comp : string,
  red : string,
  col? : {
    [mes:string]:string
  }
}

export interface MesList {
  [mes:string]:Mes
}

export interface NumList {
  [num:string]:Num
}

export interface Line{
  comp :string,
  red : string,
  mes : string,
  maj : number,
  color : string,
  unit : string
}

export interface LineList {
  [line:string]:Line
}

function buildName(n1:string,n2:string,has_mes:boolean,has_num:boolean) : string{
  if(!has_mes && !has_num)return null;
  if(has_mes && !has_num)return n1;
  if(!has_mes && has_num)return n2;
  return n1+' '+n2;
}

function build( datas : { mes:Mes , num:Num },has_mes:boolean,has_num:boolean) : Line {
  let mes = datas.mes;
  let num = datas.num;
  let line : Line = {
    comp : buildName(mes.comp,num.comp,has_mes,has_num),//regle
    red : buildName(mes.red,num.red,has_mes,has_num),//regle
    mes : mes.mes,
    maj : num.num,
    color : num.col[mes.mes] == null ? 'rgb(0, 0, 0)' : num.col[mes.mes],
    unit : mes.unit
  }
  return line;
}

export function buildAll(list:LineList,datas : {showname : ShowName, mes : MesList, num : NumList}){
  let mesList = datas.mes;
  let numList = datas.num;
  let mesKeys : string[] = Object.keys(mesList);
  let numKeys : string[]  = Object.keys(numList);
  let has_mes:boolean = datas.showname.mes;
  let has_num:boolean = datas.showname.num;

  mesKeys.forEach((mkey)=>{
    let mes : Mes = mesList[mkey];
    numKeys.forEach((nkey)=>{
      let num : Num = numList[nkey];
      list[mkey+nkey]=build({mes,num},has_mes,has_num);
    })
  })
}

export interface LineBundle {
  linenames : LineList,
  [name:string]:{ num: NumList, mes: MesList } | LineList

}
