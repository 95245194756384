import { OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { RequestParam } from '@shared/classes/request-params';
import { GroupPar } from '@shared/classes/time-interfaces';
import { NodeJsonBase } from '@shared/modules/config/node-std';

export function buildSearchParams(fields, time, group, numbers){
  return {
      fields:fields,
      time:time,
      group:group,
      numbers:numbers
  }
}

export interface InitParams {
  [graphid:string]:RequestBase
}

export interface RequestBase {
  select:string[],
  time:number,
  timetype:string,
  numbers:string[],
  device:number,
  mode:string
}

interface GraphRequests{
  mode:string,
  timetype:string,
  main:RequestParam,
  update:RequestParam,
  search:RequestParam
}

export interface DataEvent{
  orig:string,
  graph:string,
  set_mode:string,
  datas:any
}

interface GraphListRequests{ [graphid:string]:GraphRequests }

interface RequestParamsList{ [orig:string]:GraphListRequests }


export interface BuildParams {
  name:string,
  mode:string,
  id:string,
  type:string,
}

@Directive()
export abstract class CanvasList implements OnInit {
  @Output() initRequest: EventEmitter<InitParams> = new EventEmitter<InitParams>();
  @Input() idlist :string;
  @Input() dataEvent :Observable<any>;

  constructor() { }
  ngOnInit() {}

  ngAfterViewInit(){}

  passDatas(event:DataEvent,subject:Subject<any>){
    if(this.idlist==event.orig){
      subject.next({graph:event.graph, set_mode:event.set_mode, datas:event.datas});
    }
  }
}

@Directive()
export abstract class RequestPage implements OnInit {
  dataEvent : Subject<any> = new Subject<any>();
  manager : RequestManager;
  refresh: any;

  ngOnInit() { }
  constructor(protected node : NodeJsonBase ) {
    this.manager = new RequestManager (node,this.dataEvent);
  }

  protected abstract selectionDone(event:{sel:string,value:any});

  public initRequest(orig:string,params:InitParams){
    this.manager.initRequestParam(orig, params);
    this.manager.requestPoints("main", (req, requestlist, key, key2)=>{
      return key2 in params;
    });
  }

  protected setRefresh(){
    this.refresh = setInterval(()=>{
      this.manager.refreshUpdates();
      this.manager.requestPoints("update",(req, requestlist, orig, graphid)=>{ return true; });
    },30000);
  }

  protected stopRefresh(){ clearInterval(this.refresh); }

  ngOnDestroy(){ this.stopRefresh(); }

}

export class RequestManager{
  private static _self : RequestManager;
  requestlists:RequestParamsList={};
  dataEvent : Subject<DataEvent>;

  constructor(private influx : NodeJsonBase,   dataEvent : Subject<DataEvent>){
    this.dataEvent = dataEvent;
  }

  public static self(influx : NodeJsonBase,   dataEvent : Subject<DataEvent>): RequestManager {
        if (!RequestManager._self) { RequestManager._self = new RequestManager(influx, dataEvent); }
        return RequestManager._self;
    }

  initRequestParam(orig:string, params:InitParams):void{
    var keys = Object.keys(params);
    if (!(orig in this.requestlists))
      this.requestlists[orig]={}
    keys.forEach((key)=>{
      let req_list = this.requestlists[orig];
      let param = params[key];
      let req = RequestParam.initRequestParam(param.select,param.time,param.timetype,param.numbers,param.device);
      req_list[key]={
        mode:param.mode,
        timetype:param.timetype,
        main:req,
        update:req.cpy(),
        search:req.cpy()
      }
    });
  }

  selectSearch(pars,test){
   let keys = Object.keys(this.requestlists);
   keys.forEach((key)=>{
     let requestlist = this.requestlists[key]
     let keys2 = Object.keys(requestlist);
     keys2.forEach((key2)=>{
       let req= requestlist[key2];
       if(test(req, requestlist, key, key2)){
         let fields = pars.fields == null ? req.main.fields :  pars.fields;
         let time =  pars.time == null ? req.main.time :  pars.time;
         let group;
         if (req.timetype == "nogroup"){
           group = { unit: "s", value: 0 }
         }
         else {
           group = calculateGroup(pars.time)
         }
        //  let group =  pars.group == null ? req.main.group : makeGroup(pars.group,req.timetype);

         let numbers =  pars.numbers == null ? req.main.numbers :  pars.numbers;
         req.search =new RequestParam(fields, time, group, req.main.interval, numbers, req.main.device);
       }
     });
   });
  }

  selectTime(params:{time:string,group:GroupPar},test:any) :void {
    let keys = Object.keys(this.requestlists);
    keys.forEach((key)=>{
      let requestlist = this.requestlists[key]
      let keys2 = Object.keys(requestlist);
      keys2.forEach((key2)=>{
        let req= requestlist[key2];
        if(test(req, requestlist, key, key2)){
          let time = params.time;
          let group = makeGroup(params.group,req.timetype)
          req.main.setTimePar(time,group);
          req.update.setTimePar(time,group);
        }
      });
    });
  }

  selectFields(params:string[],test:any) :void {
    let keys = Object.keys(this.requestlists);
    keys.forEach((key)=>{
      let requestlist = this.requestlists[key]
      let keys2 = Object.keys(requestlist);
      keys2.forEach((key2)=>{
        let req= requestlist[key2];
        if(test(req, requestlist, key, key2)){
          req.main.setFields(params);
          req.update.setFields(params);
        }
      });
    });
  }

  selectMajor(params:string[],test:any) :void {
    let keys = Object.keys(this.requestlists);
    keys.forEach((key)=>{
      let requestlist = this.requestlists[key]
      let keys2 = Object.keys(requestlist);
        keys2.forEach((key2)=>{
          let req= requestlist[key2];
          if(test(req, requestlist, key, key2)){
            req.main.setNumbers(params);
            req.update.setNumbers(params);
          }
        });
      });
  }

  selectDevice(params:number,test:any) :void {
    let keys = Object.keys(this.requestlists);
    keys.forEach((key)=>{
      let requestlist = this.requestlists[key]
      let keys2 = Object.keys(requestlist);
        keys2.forEach((key2)=>{
          let req= requestlist[key2];
          if(test(req, requestlist, key, key2)){
            req.main.setDevice(params);
            req.update.setDevice(params);
        }
      });
    });
  }


  refreshUpdates(){
    let keys = Object.keys(this.requestlists);
    keys.forEach((key)=>{
      let requestlist = this.requestlists[key]
      let keys2 = Object.keys(requestlist);
      keys2.forEach((key2)=>{
        requestlist[key2].update.setRefreshTime();;
      });
    });
  }

  requestPoints(req_type:string, test){
    let keys = Object.keys(this.requestlists);
    keys.forEach((key)=>{
      let requestlist = this.requestlists[key]
      let keys2 = Object.keys(requestlist);
      keys2.forEach((key2)=>{
        let req = requestlist[key2];
        if(test(req, requestlist, key, key2)){
          this.postDatapoints(req[req_type],req.mode,key,key2,req_type);
        }
      });
    });
  }


  postDatapoints(params : RequestParam, mode:string, orig:string, graph:string, set_mode:string) : void {
    this.influx.postRequest(params,'/reqpoints/'+mode).subscribe((datas)=>{
      this.dataEvent.next({orig:orig, graph:graph, set_mode:set_mode,datas:datas});
    });
  }

}

function makeGroup(group:GroupPar,type:string):GroupPar{
  return type == "nogroup" ? {unit:group.unit,value:0} : group;
}

function calculateGroup(timepars){
  let dtime = Math.round((timepars.end - timepars.start)/(720 * 1000));
  let time = dtime > 5 ? dtime : 5; 
  return {unit:"s", value:time};
}