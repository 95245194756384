import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RequestPage } from '@shared/classes/request-manager';
import { SearchPopUpComponent } from '@shared/modules/dialog/search-pop-up/search-pop-up.component';
import { ChargingPointService } from './charging-point.service';

function buildPars(fields, time, group, numbers){
  return {
      fields:fields,
      time:time,
      group:group,
      numbers:numbers
  }
}

@Component({
  selector: 'charging-point-page',
  templateUrl: './charging-point-page.component.html',
  styleUrls: ['./charging-point-page.component.scss']
})
export class ChargingPointPageComponent extends RequestPage {
  idpage:number=11;
  opened : boolean = false;
  searchEvent: Subject<any> = new Subject<any>();

  constructor(private router :Router,protected chargingPoint :ChargingPointService, public dialog : MatDialog) {
    super(chargingPoint);
  }
  ngOnInit() {}
  ngOnDestroy(){
    this.leaveSearch();
  }
  selectionDone(event:{sel:string,value:any}){
    console.log(event.value);
    this.stopRefresh();
    this.opened = false;

    switch(event.sel){
      case 'time' :
        this.manager.selectTime(event.value,(req, requestlist, orig, graphid)=>{ return true; });
        this.manager.requestPoints("main",(req, requestlist, key, key2)=>{return true;})
        break;
    default:break;
    }
    this.setRefresh();
  }
  toEms(){
    this.router.navigate(['/ems/graph']).then(e => {
        if(e){
          console.log("Navigation success!");
        }
        else{
          console.log('Navigation failed :()');
        }
      });
  }

  openSearch(){
    const dialogRef = this.dialog.open(SearchPopUpComponent, {
      autoFocus: true
    });
    this.stopRefresh();
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.searchEvent.next(true);
        console.log(result);
        var tmppar = buildPars(null, result.time, result.group, null);
        this.manager.selectSearch(tmppar, (req, requestlist, orig, graphid) => { return true; });
        this.manager.requestPoints("search", (req, requestlist, key, key2) => { return true; })
      }
      else {

        console.log("cancel");
      }
      this.opened = false;
    });
  }

  ngAfterViewInit(){
    this.setRefresh();
  }


  onSearch(event){
    console.log(event);
    if(event){
      this.openSearch();
    }
    else{
      this.leaveSearch();
    }
  }

  openPanel(){
    this.opened = true;
  }

  leaveSearch(){
    this.searchEvent.next(false);
    this.manager.requestPoints("main",(req, requestlist, key, key2)=>{return true;});
    this.setRefresh();
  }
}
