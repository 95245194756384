import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';

import { SolidStateIsoPageComponent } from './solid-state-iso-page.component'

@NgModule({
  declarations: [ SolidStateIsoPageComponent ],
  exports: [ SolidStateIsoPageComponent ],
  imports: [
    MaterialLoaderModule,
    CommonModule
  ]
})
export class SolidStateIsoPageModule { }
