import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { MmaTableModule } from '@shared/modules/mma-table/mma-table.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';


import { BatteryPageComponent } from './battery-page.component';
import { BatteryHeadComponent } from './battery-head/battery-head.component';
import { BatteryGraphsComponent } from './battery-graphs/battery-graphs.component';
import { BatteryPanelComponent } from './battery-panel/battery-panel.component';
import { BatteryStateComponent } from './battery-state/battery-state.component';
import { BatteryListComponent } from './battery-list/battery-list.component';

@NgModule({
  declarations: [ BatteryPageComponent, BatteryHeadComponent, BatteryGraphsComponent, BatteryPanelComponent, BatteryStateComponent, BatteryListComponent ],
  exports: [ BatteryPageComponent ],
  imports: [
    MaterialLoaderModule,
    CanvasJsModule,
    MmaTableModule,
    SelectorsModule,
    CommonModule
  ]
})
export class BatteryPageModule { }
