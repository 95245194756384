import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { CanvasList } from '@shared/classes/request-manager';
import { BatteryService } from '../battery.service';
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';
import * as ParamsMaker from '@shared/classes/parameters-maker';
import { BuildParams } from '@shared/modules/canvas-js/types/graph-builder';


function initLineParams(selects, majors, mode) {
  return { "select": selects, "time": 0, "timetype": "short", "numbers": majors, device: 0, mode: mode }
}


const BATTERY_GRAPH = {
  y_format: {
    power: StyleSTD.NUMFORMAT.std,
    capacitance: StyleSTD.NUMFORMAT.std,
    voltage: StyleSTD.NUMFORMAT.std,
    current_mes: StyleSTD.NUMFORMAT.std,
    soc: StyleSTD.NUMFORMAT.std,
    nbrcycle: StyleSTD.NUMFORMAT.std
  },
  axis_pos: {
    power: StyleSTD.AXISPOS.pri,
    capacitance: StyleSTD.AXISPOS.sec,
    voltage: StyleSTD.AXISPOS.pri,
    current_mes: StyleSTD.AXISPOS.sec,
    soc: StyleSTD.AXISPOS.pri,
    nbrcycle: StyleSTD.AXISPOS.pri
  },
  y_axis_format: { valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format: { valueFormatString: StyleSTD.NUMFORMAT.std }
}

function initBatteryParams(mesures, initEvent, device) {
  initEvent.emit({
    batterypowcapa: initLineParams([0, 1], ["0"], "battery_powcapa"),
    batteryvoltcur: initLineParams([0, 1], ["0"], "battery_voltcur"),
    batterysoc: initLineParams([0], ["0"], "battery_soc"),
    batterycycle: initLineParams([0], ["0"], "battery_nbrcycle")
  });
}
@Component({
  selector: 'battery-graphs',
  templateUrl: './battery-graphs.component.html',
  styleUrls: ['./battery-graphs.component.scss']
})
export class BatteryGraphsComponent extends CanvasList {

  buildEvent: Subject<any> = new Subject<any>();
  dataEventNext: Subject<any> = new Subject<any>();;

  idgraphs = ["batterypowcapa", "batteryvoltcur", "batterysoc", "batterycycle"];

  linebuilds: BuildParams[] = [
    { name: "Puissance & Capacité", mode: "battery", id: 'batterypowcapa', type: 'area' },
    { name: "Tension & Courant", mode: "battery", id: 'batteryvoltcur', type: 'area' },
    { name: "Etat de Charge", mode: "battery", id: 'batterysoc', type: 'area' },
    { name: "Cycles", mode: "battery", id: 'batterycycle', type: 'area' }
  ]
  constructor(private battery: BatteryService) {
    super();
  }

  ngOnInit(): void {
    this.battery.get_usr_battery_device().subscribe((batteries) => {
      ParamsMaker.setLineParams(batteries, "batteries", "battery", BATTERY_GRAPH, initBatteryParams, this.buildEvent, this.initRequest, 0);
    });
  }

  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {
      this.passDatas(event, this.dataEventNext);
    });
  }
}