<div class="dcdc-page">
  <mat-sidenav-container class="dcdc-container">
    <mat-sidenav class="dcdc-sidenav" mode="over" [(opened)]="opened" >
      <dcdc-panel (onSelect)="selectionDone($event)" [(onsearch)]="onsearch" (onOpenSearch)="openSearch($event)" (onLeaveSearch)="leaveSeach($event)">
      </dcdc-panel>
    </mat-sidenav>
    <mat-sidenav-content class="dcdc-content">
      <div class="sub-header">
        <dcdc-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></dcdc-head>
      </div>
      <div class="page-content">
          <div class="state-labels">
            <dcdc-state [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('dcdcstate',$event)"></dcdc-state>
          </div>
          <div class="pv-values">
            <dcdc-list [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('dcdclist',$event)"></dcdc-list>
          </div>
          <dcdc-graphs [idlist]="'dcdcgraphs'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('dcdcgraphs',$event)">
          </dcdc-graphs>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
