import { Directive, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { Network } from 'vis';
import { EMSGRAPH,SWOFFVERIMG,SWONVERIMG,SWOFFHORIMG,SWONHORIMG } from './vis-sys/vis-graph-builder';
import { EMSDest, EMSElements } from './vis-sys/ems-const';
import { Observable } from 'rxjs';
import { EMSService } from '../ems.service';

const  clicks = {
  doubletime : 0,
  doubleAt : 500,
}
@Directive({
  selector: '[appVisGraph]'
})
export class VisGraphDirective {

  private network:Network;

  constructor( private el: ElementRef,private ems : EMSService) {}
    @Output() elementClicked: EventEmitter<{elt:string,value:any}> = new EventEmitter<{elt:string,value:any}>();
    @Input() elementEvent :Observable<{elt:string,value:any}>;
    elementSubject:any

  onNetworkInit(){
      this.elementClicked.emit({elt:"init",value:null});
  }

  checkNode(datas) {
    if (datas.length == 0) return false;
    return "node" in datas[0];
  }
  onEmsDoubleClick(id:number){
    let dest = EMSDest[id-1];
    if(id > -1 && id < EMSDest.length){
      this.ems.check_node(EMSElements[dest]).subscribe((datas)=>{
        if (this.checkNode(datas)){
        if(datas[0]["node"]){
          this.elementClicked.emit({ elt: "nav", value: '/ems/' + dest });
        }
        }
      });
    }
  }

  ngAfterContentInit(){

    var options = {  physics:false };

    this.network = new Network(this.el.nativeElement, EMSGRAPH, options);
    this.network["graphDirective"]=this;
    this.network["getNodeById"]=getNodeById;
    this.network["getNodes"]=getNodes;
    this.network["setSwitches"]=setSwitches;
    this.network["updateNode"]=updateNode;
    this.network["imageUpdate"]=imageUpdate;
    this.network["simpleClick"]=simpleClick;
    this.network.on( 'click', onNetworkClick);
    this.network.on( 'doubleClick', onNetworkDoubleClick);
    this.elementClicked.emit({elt:"init",value:null});
    this.elementSubject = this.elementEvent.subscribe((elementPars) => {
      let elt = elementPars.elt;
      let value = elementPars.value;
      if(elt="switch"){
        this.network.setSwitches(value);
      }
    })
  }
}

function onNetworkDoubleClick(properties){
  clicks.doubletime = new Date().getTime();
  var nodeids = properties.nodes;
  var edgeids = properties.edges;
  if(nodeids.length!=0){
    let nodecliked = this.getNodeById(nodeids[0]);
    let nodetype = nodecliked.options.type;
    if( nodetype == 'emspart' ){
      this.graphDirective.onEmsDoubleClick(nodeids[0]);;
    }
  }
}

function simpleClick(properties){
  var nodeids = properties.nodes;
  var edgeids = properties.edges;
  if(nodeids.length!=0){
    let nodecliked = this.getNodeById(nodeids[0]);
    let nodetype = nodecliked.options.type;
    if( nodetype == 'emspart' ){
      this.graphDirective.elementClicked.emit({elt:"node",value:nodeids[0]});
    }
    if( nodetype == 'switch' ){
      this.graphDirective.elementClicked.emit({elt:"switch",value:{name:nodecliked.options.label,id:nodeids[0],state:nodecliked.options.state}})
    }
  }
  if(edgeids.length!=0){}
}

/**Network Functions**/
function onNetworkClick(properties){
  var first = new Date().getTime();
  let network = this;
   if (first - clicks.doubletime > clicks.doubleAt) {
       setTimeout( () => {
           if (first - clicks.doubletime > clicks.doubleAt) {
             network.simpleClick(properties);
           }
       },clicks.doubleAt);
   }
  }

function getNodes(){
  return this.body.nodes;
}

function getNodeById(id:number){
  return this.body.nodes[id];
}

function updateNode(id:number,data:Object){
  data["id"]=id;
  this.body.data.nodes.update(data);
}

function setSwitches(switches){
  switches.forEach((swh)=>{
    let state = swh.relay_state;
    let uid = swh.uid;
    if((uid > 500 && uid < 510) || (uid > 516 && uid < 525)){
      this.updateNode(uid,{state:state,image: this.imageUpdate(uid,state)})
    }
  });
}

function imageUpdate(id:number,state:boolean) : string {
  let vertical = this.getNodeById(id).options.vertical;
  if(vertical  && state)return SWONVERIMG;
  if(vertical  && !state)return SWOFFVERIMG;
  if(!vertical  && state)return SWONHORIMG;
  if(!vertical  && !state)return SWOFFHORIMG;
  return "";
}
