export const EMSElements = {
  pv:'PV',
  dcdc:'MPPT',
  batterie:'Batterie',
  onduleurpv:'Onduleur PV',
  reseau:'Secteur',
  transfoiso:'Transfo. Isolement',
  pac:'PAC',
  electrolyseur:'Electrolyseur',
  onduleur:'Onduleur H2',
  datacenter:'Datacenter',
  borne:'Borne',
  prisescierie:'Prises Scierie',
  eclairage:'Eclairage',
  refroidissement:'Refroidissement',
  utilite:'Utilité',
  synapse: 'Synapse',
  router:'Router'
}
export const EMSDest = Object.keys(EMSElements);
