<mat-card-content class="dev-panel">
  <mat-card class="dev-header">
    <mat-card class="title-card">
      <span>Paramètres</span>
    </mat-card>
    <div class="space"></div>
    <button mat-button (click)="onModifyClick(params)">Modifier</button>
  </mat-card>
  <table mat-table [dataSource]="dataSource" class="params-tab">
    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
      <th class="elt-{{column}}" mat-header-cell *matHeaderCellDef> {{colNames[column]}} </th>
      <td class="elt-{{column}}" mat-cell *matCellDef="let element"> {{element[column]}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-card-content>
