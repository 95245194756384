<mat-card-content class="dev-panel">
    <mat-card class="title-card"><span>Résumé</span></mat-card>
    <table mat-table [dataSource]="dataSource" class="resume-tab">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th class="elt-{{column}}" mat-header-cell *matHeaderCellDef> {{colNames[column]}} </th>
        <td class="elt-{{column}}" mat-cell *matCellDef="let element"> {{element[column]}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-card-content>
