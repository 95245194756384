import { TimeListNoGroup, TimeList, LongTimeList, PrevTimeList, AlarmTimeList } from '@shared/classes/const-canvas';
import { getTimePar, TimePar, GroupPar } from '@shared/classes/time-interfaces'

export class RequestParam {
    fields: string[];
    time: TimePar;
    group: GroupPar;
    interval?: string;
    numbers?: string[];
    device?: number;

    constructor(fields: string[], time: TimePar, group: GroupPar, interv: string, numbers: string[], device: number) {
        this.fields = fields;
        this.time = time;
        this.group = group;
        this.interval = interv;
        this.numbers = numbers;
        this.device = device;
    }
    public setFields(fields) {
        this.fields = fields;
    }

    public static getTimeParams(timetype, timenum) {
        switch (timetype) {
            case "short": return TimeList.get(timenum);
            case "nogroup": return TimeListNoGroup.get(timenum);
            case "longtime": return LongTimeList.get(timenum);
            case "prev": return PrevTimeList.get(timenum);
            case "alarm": return AlarmTimeList.get(timenum);
            default: TimeList.get(0);
        }
    }

    public static initRequestParam(fields: string[], timeNum: number, timetype: string, numbers: string[], device: number) {
        let timepar = RequestParam.getTimeParams(timetype, timeNum);
        return new RequestParam(fields, getTimePar(timepar.time), timepar.group, timepar.time, numbers, device);
    }

    public setTimePar(time: string, group: GroupPar) {
        var timepar = getTimePar(time);
        this.time = timepar;
        this.interval = time;
        this.group = group;
    }

    public setTimeParNoGroup(time: string, group: GroupPar) {
        var timepar = getTimePar(time);
        this.time = timepar;
        this.interval = time;
        this.group = { unit: this.group.unit, value: 0 };
    }

    public setRefreshTime() {
        this.time = getTimePar(this.interval);
    }

    public setNumbers(numbers: string[]) {
        this.numbers = numbers;
    }

    public setDevice(device: number) {
        return this.device;
    }

    public cpy() {
        return new RequestParam(this.fields, this.time, this.group, this.interval, this.numbers, this.device);
    }
}
