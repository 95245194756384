<div class="h2-tables">
  <mat-list class="table-list">
    <mat-list-item class="tab-list-item">
      <div class="data-table">
        <soc-table idval="{{idsoc}}" [dataEventNext]="dataEventNext.asObservable()"></soc-table>
      </div>
    </mat-list-item>
    <mat-list-item class="tab-list-item">
      <div class="data-table">
        <pac-table idval="{{idpac}}" [dataEventNext]="dataEventNext.asObservable()"></pac-table>
      </div>
    </mat-list-item>
    <mat-list-item class="tab-list-item">
      <div class="data-table">
        <ely-table idval="{{idely}}" [dataEventNext]="dataEventNext.asObservable()"></ely-table>
      </div>
    </mat-list-item>
  </mat-list>
</div>
