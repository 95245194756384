import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
/**Main Gui components**/
import { ConnectPanelComponent } from '@shared/modules/connect-panel/connect-panel.component';
import { PageRoutingModule } from 'src/app/gui/main-page/page-routing.module';

/**Part pages components**/

const routes: Routes = [
  { path: '', redirectTo: '/connect', pathMatch: 'full' },
  { path: 'connect', component: ConnectPanelComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    PageRoutingModule
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
