import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Observable } from 'rxjs';
import { LogService } from './log.service';
import { LogDatas, DEVS, LogMsg } from './log-std';

const PAGE_TO_TXT = {
  "ems":"EMS",
  "pv":"PV",
  "inverter-pv":"Onduleur PV",
  "battery":"Batterie",
  "reseau":"Secteur",
  "h2":"H2",
  "datacenter":"Datacenter",
  "notfound":"N/A"
}

const PAGE_TO_PART = {
  "inverter-pv":3,
  "h2":6
}

@Component({
  selector: 'log-view',
  styleUrls: ['log-view.component.scss'],
  templateUrl: 'log-view.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class LogViewComponent implements OnInit {
  @Input() pageChangeEvent :Observable<string>;
  @Input() refreshEvent :Observable<boolean>;
  @ViewChild(MatTable) table: MatTable<any>;
  refreshSubject:any
  currentPage:string="ems";
  logs : LogMsg[] = [];
  dataSource : MatTableDataSource<LogMsg>= new MatTableDataSource(this.logs);
  columnsToDisplay = ['date', 'event'];
  columnNames = {
    date: "Date",
    event: "Evenement"
  }
  min_warn:number = 0;
  sel_part:number= null;
  expandedElement: LogMsg | null;
  refresh:any;
  ico_path : string[] = ['assets/img/ico/info.png', 'assets/img/ico/warning.png', 'assets/img/ico/alert.png'];

  constructor(private log : LogService){}
  ngOnInit(){
    this.startRefresh();

    this.refreshSubject = this.refreshEvent.subscribe((refreshOn) => {
      if(refreshOn)this.startRefresh();
      else this.stopRefresh();
    });

  }


  get_button_txt(page){
    return page in PAGE_TO_TXT ? PAGE_TO_TXT[page] : null;
  }

  setMinWarn(min_warn:number){
    this.min_warn = min_warn;
    this.postLogsGet();
  }

  setDeviceFilter(page){
    console.log(page);
    this.sel_part = page in PAGE_TO_PART ? PAGE_TO_PART[page] : null;
    console.log(this.sel_part);
    this.postLogsGet();
  }
  makeLog(data:LogDatas):LogMsg{
    return new LogMsg(
      data.time,
      data.warn_lvl,
      DEVS[data.part],
      data.short_desc,
      data.description
    );
  }

  updateTable(): void{
    this.dataSource.data = this.logs;
    this.table.renderRows();
  }


  checkDatas(datas: { [id: string]: any }){
    if(datas == null )return false;
    if(!("logs" in datas))return false;
    if(datas["logs"]==null)return false;
    return true;
  }

  postLogsGet(){
    // console.log("params "+this.min_warn+" "+this.sel_part);
    this.log.postLogsGet(this.min_warn,this.sel_part).subscribe(
      (datas: { [id: string]: any })=>{
        this.logs = []
        if(this.checkDatas(datas)){
          datas["logs"].forEach((data) => {
            this.logs.push(this.makeLog(data));
          });
        }
        this.updateTable();        
      });
  }

  startRefresh(){
    this.refresh = setInterval(()=>{
      this.postLogsGet();
    },5000);
  }

  stopRefresh(){
    clearInterval(this.refresh);
  }

  ngOnDestroy(){
    this.stopRefresh();
  }


  ngAfterViewInit(){
    this.pageChangeEvent.subscribe((page)=>{
      this.currentPage = page;
    });
    if(this.currentPage != "notfound"){

    }
  }
}

function isExistingMsg(time:string,list:LogMsg[]):boolean{
  for(let i = 0; i < list.length; i++)
    if(time==list[i].date)return true;
  return false;
}

function isExistingData(time:string,list:LogDatas[]):boolean{
  for(let i = 0; i < list.length; i++)
    if(time==list[i].time)return true;
  return false;
}
