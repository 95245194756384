<div class="pv page">
  <mat-sidenav-container class="pv-container">
    <mat-sidenav class="pv-sidenav" mode="over" [(opened)]="opened">
      <pv-panel (onSelect)="selectionDone($event)">
      </pv-panel>
    </mat-sidenav>
    <mat-sidenav-content class="pv-content">
      <div class="sub-header">
        <pv-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></pv-head>
      </div>
      <div class="page-content">
        <div class="resume-card">
          <resume-table></resume-table>
        </div>
        <pv-graphs [idlist]="'lines'" [dataEvent]="dataEvent.asObservable()"
          (initRequest)="initRequest('lines',$event)">
        </pv-graphs>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>