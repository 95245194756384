import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NodeJsonBase } from '@shared/modules/config/node-std';
import { ConfigLoaderService } from '@shared/modules/config/config-loader.service';
import { LogDatas } from './log-std';
@Injectable({
  providedIn: 'root'
})
export class LogService extends NodeJsonBase {

  constructor(protected http : HttpClient, protected cls : ConfigLoaderService) {
    super(http, cls)
  }

  // postLogsGet(warn_lvl,part) : Observable<LogDatas[]> {
  //   var httpOptions = this.buildOptions(true,this.server_url,{"warn_lvl":warn_lvl, "part":part});
  //   return this.http.post<LogDatas[]>(this.server_url+'/logs', httpOptions).pipe(
  //     tap(_ => { }),
  //     catchError(this.handleError<LogDatas[]>('postLogsGet', ))
  //   );
  // }

  postLogsGet(warn_lvl, part): Observable<any> {
    var httpOptions = this.buildOptions(true, this.server_url, { "warn_lvl": warn_lvl, "part": part});
    return this.http.post<any>(this.server_url + '/logs', httpOptions).pipe(
      tap(_ => {
        // this.log('fetched pokemon');
        // console.log('oki');
      }),
      catchError(this.handleError<any>('postLogsGet',))
    );
  }
}
