import { DataSet } from 'vis';
const NODESIZE: number = 20;
const NODEFONTSIZE: number = 12;
const SWITCHSIZE: number = 7;
export const SWOFFVERIMG: string = "assets/img/switch/switchoffver.png";
export const SWOFFHORIMG: string = "assets/img/switch/switchoffhor.png";
export const SWONVERIMG: string = "assets/img/switch/switchonver.png";
export const SWONHORIMG: string = "assets/img/switch/switchonhor.png";

interface VisNode {
  id: number,
  type: string,
  label: string,
  image?: string,
  shape: string,
  size: number,
  x: number,
  y: number,
  state?: boolean,
  vertical?: boolean,
  font: { size: number }
}

interface VisEdge {
  id: string,
  from: number,
  to: number,
  smooth: boolean
}

interface VisGraph {
  nodes: DataSet,
  edges: DataSet
}

function NewEdge(from: number, to: number): VisEdge {
  return {
    id: "f" + from + "t" + to,
    from: from,
    to: to,
    smooth: false
  };
}

function SwitchNodes(id: number, label: string, vertical: boolean, pos: { x: number, y: number }): VisNode {
  let image = vertical ? SWOFFVERIMG : SWOFFHORIMG;
  return {
    id: id,
    type: "switch",
    label: label,
    shape: "image",
    image: image,
    vertical: vertical,
    size: SWITCHSIZE,
    x: pos.x,
    y: pos.y,
    state: false,
    font: {
      size: NODEFONTSIZE
    }
  }
}

function EmsNodes(id: number, label: string, pos: { x: number, y: number }): VisNode {
  return {
    id: id,
    type: "emspart",
    label: label,
    shape: "square",
    size: NODESIZE,
    x: pos.x,
    y: pos.y,
    font: {
      size: NODEFONTSIZE
    }
  }
}

function FakeNodes(id: number, label: string, pos: { x: number, y: number }): VisNode {
  return {
    id: id,
    type: "fake",
    label: label,
    shape: "dot",
    size: 0,
    x: pos.x,
    y: pos.y,
    font: {
      size: 0
    }
  }
}



export const EMSNODES: DataSet = new DataSet([
  /** NODES BLOC PV**/
  EmsNodes(1, 'PV', { x: 0, y: 75 }),
  EmsNodes(2, 'MPPT', { x: 100, y: 75 }),
  EmsNodes(3, 'Batterie', { x: 200, y: 75 }),
  EmsNodes(4, 'Onduleur PV', { x: 300, y: 75 }),
  /** NODES BLOC Réseau **/
  EmsNodes(5, 'Secteur', { x: 0, y: 175 }),
  EmsNodes(6, 'Transfo Isolement', { x: 100, y: 175 }),
  /** NODES BLOC H2 **/
  EmsNodes(7, 'PAC', { x: 0, y: 275 }),
  EmsNodes(8, 'Electrolyseur', { x: 0, y: 375 }),
  EmsNodes(9, 'Onduleur H2', { x: 200, y: 325 }),
  /** NODE BLOC DATACENTER **/
  EmsNodes(17, 'Router', { x: 320, y: 470 }),
  EmsNodes(10, 'Datacenter', { x: 410, y: 470 }),
  /** NODE BLOC USAGE **/
  EmsNodes(11, 'Borne', { x: 470, y: 470 }),
  EmsNodes(12, 'Prise Scierie', { x: 560, y: 470 }),
  EmsNodes(13, 'Eclairage', { x: 650, y: 470 }),
  EmsNodes(14, 'Refroidissement', { x: 740, y: 470 }),
  EmsNodes(15, 'Utilité', { x: 830, y: 470 }),

  EmsNodes(16, 'Non Affecté', { x: 920, y: 470 }),
  EmsNodes(18, 'Non Affecté', { x: 1010, y: 470 }),
  EmsNodes(19, 'Non Affecté', { x: 1100, y: 470 }),

  /** BUNDLE NODES**/

  FakeNodes(101, 'B1', { x: 100, y: 325 }),
  FakeNodes(102, 'B2', { x: 650, y: 350 }),

  /*FAKE NODE */
  FakeNodes(201, 'F1', { x: 410, y: 0 }),
  FakeNodes(202, 'F2', { x: 410, y: 75 }),
  FakeNodes(203, 'F3', { x: 410, y: 175 }),
  FakeNodes(204, 'F4', { x: 410, y: 325 }),
  FakeNodes(205, 'F5', { x: 650, y: 50 }),
  FakeNodes(206, 'F6', { x: 650, y: 150 }),
  FakeNodes(207, 'F7', { x: 650, y: 300 }),

  /*SQUARE NODE */
  FakeNodes(301, 'SQ1', { x: 100, y: 275 }),
  FakeNodes(302, 'SQ2', { x: 100, y: 375 }),
  FakeNodes(303, 'SQ3', { x: 470, y: 350 }),
  FakeNodes(304, 'SQ4', { x: 560, y: 350 }),
  FakeNodes(305, 'SQ5', { x: 740, y: 350 }),
  FakeNodes(306, 'SQ6', { x: 830, y: 350 }),

  FakeNodes(307, 'SQ7', { x: 830, y: 350 }),
  FakeNodes(308, 'SQ8', { x: 830, y: 350 }),
  FakeNodes(309, 'SQ9', { x: 830, y: 350 }),

  FakeNodes(310, 'SQ10', { x: 920, y: 350 }),
  FakeNodes(311, 'SQ11', { x: 1010, y: 350 }),
  FakeNodes(312, 'SQ12', { x: 1100, y: 350 }),
  FakeNodes(313, 'SQ13', { x: 320, y: 350 }),

  /** INTER NODE**/
  FakeNodes(401, 'I1', { x: 350, y: 50 }),
  FakeNodes(402, 'I2', { x: 410, y: 50 }),
  FakeNodes(403, 'I3', { x: 350, y: 75 }),
  FakeNodes(404, 'I4', { x: 350, y: 150 }),
  FakeNodes(405, 'I5', { x: 410, y: 150 }),
  FakeNodes(406, 'I6', { x: 350, y: 175 }),
  FakeNodes(407, 'I7', { x: 350, y: 300 }),
  FakeNodes(408, 'I8', { x: 410, y: 300 }),
  FakeNodes(409, 'I9', { x: 350, y: 325 }),
  FakeNodes(410, 'I10', { x: 410, y: 350 }),
  /*NODE INTERUPTEUR*/
  SwitchNodes(501, 'KM1', false, { x: 50, y: 75 }),
  SwitchNodes(502, 'KM2', false, { x: 150, y: 75 }),
  SwitchNodes(503, 'KM3', false, { x: 250, y: 75 }),
  SwitchNodes(504, 'KM4', false, { x: 380, y: 75 }),

  SwitchNodes(505, 'KM5', false, { x: 380, y: 175 }),
  SwitchNodes(506, 'KM6', false, { x: 380, y: 325 }),
  SwitchNodes(507, 'KM7', false, { x: 540, y: 50 }),
  SwitchNodes(508, 'KM8', false, { x: 540, y: 150 }),

  SwitchNodes(509, 'KM9', false, { x: 540, y: 300 }),
  // SwitchNodes(510,'KM10',true,{x:410,y:400}),
  SwitchNodes(517, 'KM17', true, { x: 470, y: 400 }),
  SwitchNodes(518, 'KM18', true, { x: 560, y: 400 }),

  SwitchNodes(519, 'KM19', true, { x: 650, y: 400 }),
  SwitchNodes(520, 'KM20', true, { x: 740, y: 400 }),
  SwitchNodes(521, 'KM21', true, { x: 830, y: 400 }),

  SwitchNodes(522, 'KM22', true, { x: 920, y: 400 }),
  SwitchNodes(523, 'KM23', true, { x: 1010, y: 400 }),
  SwitchNodes(524, 'KM24', true, { x: 1100, y: 400 })

]);

export const EMSEDGES: DataSet = new DataSet([
  /**BASE EDGE **/
  NewEdge(5, 6),
  /*BUNDLE B1*/
  NewEdge(101, 9),
  /*BUNDLE B2*/
  /* FAKES */
  NewEdge(205, 206),
  NewEdge(206, 207),
  NewEdge(207, 102),
  /* SQUARE */
  NewEdge(7, 301),
  NewEdge(8, 302),
  NewEdge(301, 101),
  NewEdge(302, 101),

  NewEdge(304, 303),
  NewEdge(102, 304),
  NewEdge(102, 305),
  NewEdge(305, 306),

  /*INTER*/
  NewEdge(403, 401),
  NewEdge(4, 403),

  NewEdge(401, 402),
  NewEdge(201, 402),
  NewEdge(402, 202),

  NewEdge(404, 405),
  NewEdge(406, 404),
  NewEdge(6, 406),
  NewEdge(405, 203),
  NewEdge(202, 405),

  NewEdge(407, 408),
  NewEdge(409, 407),
  NewEdge(9, 409),
  NewEdge(408, 204),
  NewEdge(203, 408),
  /*SWITCH*/
  NewEdge(1, 501),
  NewEdge(501, 2),
  NewEdge(2, 502),
  NewEdge(502, 3),
  NewEdge(3, 503),
  NewEdge(503, 4),

  NewEdge(403, 504),
  NewEdge(504, 202),

  NewEdge(406, 505),
  NewEdge(505, 203),

  NewEdge(409, 506),
  NewEdge(506, 204),

  NewEdge(402, 507),
  NewEdge(507, 205),

  NewEdge(405, 508),
  NewEdge(508, 206),

  NewEdge(408, 509),
  NewEdge(509, 207),

  // NewEdge(204, 10),

  NewEdge(303, 517),
  NewEdge(517, 11),
  NewEdge(304, 518),
  NewEdge(518, 12),
  NewEdge(102, 519),
  NewEdge(519, 13),
  NewEdge(305, 520),
  NewEdge(520, 14),
  NewEdge(306, 521),
  NewEdge(521, 15),

  NewEdge(310, 522),
  NewEdge(522, 16),

  NewEdge(311, 523),
  NewEdge(523, 18),

  NewEdge(312, 524),
  NewEdge(524, 19),

  NewEdge(306, 310),
  NewEdge(310, 311),
  NewEdge(311, 312),
  NewEdge(17, 313),
  NewEdge(313, 410),
  NewEdge(204, 410),
  NewEdge(410, 10),
]);

export const EMSGRAPH: VisGraph = {
  nodes: EMSNODES,
  edges: EMSEDGES
}
