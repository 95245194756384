<div class="router page">
    <mat-sidenav-container class="router-container">
        <mat-sidenav class="router-sidenav" mode="over" [(opened)]="opened">
            <router-panel (onSearchEvent)="onSearch($event)" (onSelect)="selectionDone($event)"
                [searchEvent]="searchEvent.asObservable()">
            </router-panel>
        </mat-sidenav>
        <mat-sidenav-content class="router-content">
            <div class="sub-header">
                <router-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></router-head>
            </div>
            <div class="page-content">
                <router-graphs [idlist]="'lines'" [dataEvent]="dataEvent.asObservable()"
                    (initRequest)="initRequest('lines',$event)">
                </router-graphs>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>