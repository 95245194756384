
export interface Device{
  name:string,
  uid:string,
  num:string,
  type:string,
  ip:string,
  project:string,
  client:string,
  mission:string,
  pingtab:string,
  nb_mesures:number,
  mesures:Mesure[]
}

export interface Mesure {
  id:string,
  name:string,
  red_name :string,
  types:string[],
  unit:string,
  nb_chan:number,
  influxtab:string,
  channels:Channel[]
}

export interface Channel{
  name:string,
  id:string,
  red_name :string,
  request:string,
  r:number,
  g:number,
  b:number,
}

export interface PSQLDevice{
  name:string,
  uid:string,
  num:string,
  type:string,
  ip:string,
  project:string,
  client:string,
  mission:string,
  pingtab:string,
  // influxtab:string,
  mesures:PSQLMesureList
}

export interface PSQLMesureList {
  [id:string]:PSQLMesure
}



export interface PSQLChannelList {
  [id:string]:PSQLChannel
}

export interface PSQLMesure {
  name:string,
  red_name:string,
  types:string[],
  unit:string,
  influxtab:string,
  channels:PSQLChannelList
}

export interface PSQLChannel{
  comp:string,
  red:string,
  request:string,
  col:PSQLColor
}

export interface PSQLColor{
  r:number,
  g:number,
  b:number
}

export function listPSQLChan(mesures:PSQLMesureList,mesname:string):string[]{
  return Object.keys(mesures[mesname].channels);
}

export function PSQLToDevice(psqldev:PSQLDevice):Device{
  let psqlmes = psqldev.mesures;
  let keys = Object.keys(psqlmes);
  let mesures = [];
  keys.forEach((key)=>{ mesures.push(PSQLToMesure(psqlmes[key], key)); });
  return {
    name:psqldev.name,
    uid:psqldev.uid,
    num:psqldev.num,
    type:psqldev.type,
    ip:psqldev.ip,
    project:psqldev.project,
    client:psqldev.client,
    mission:psqldev.mission,
    pingtab:psqldev.pingtab,
    nb_mesures:mesures.length,
    mesures:mesures
  }
}

export function PSQLToMesure(psqlmes:PSQLMesure, uid:string):Mesure{
  let psqlchan = psqlmes.channels;
  let keys = Object.keys(psqlchan);
  let channels = []
  keys.forEach((key)=>{
    channels.push(PSQLToChannel(psqlchan[key],key));
  });
  let mes = {
    id:uid,
    name:psqlmes.name,
    red_name:psqlmes.red_name,
    types:psqlmes.types,
    unit:psqlmes.unit,
    influxtab:psqlmes.influxtab,
    nb_chan:channels.length,
    channels:channels
  }
  return mes;
}

export function PSQLToChannel(psqlchan:PSQLChannel, uid:string):Channel{
  return {
    name:psqlchan.comp,
    id:uid,
    red_name :psqlchan.red,
    request:psqlchan.request,
    r:psqlchan.col.r,
    g:psqlchan.col.g,
    b:psqlchan.col.b
  }
}

export function mesureToPSQL(mesure:Mesure){
  let channels:Channel[] = mesure.channels;
  let psqlchannels: PSQLChannelList = {}
  channels.forEach((channel)=>{
    psqlchannels[channel.id]=channelToPSQL(channel);
  })
  return{
    name:mesure.name,
    red_name:mesure.red_name,
    types:mesure.types,
    unit:mesure.unit,
    influxtab:mesure.influxtab,
    channels:psqlchannels
  }
}

export function channelToPSQL(channel:Channel):PSQLChannel{
  return {
    comp:channel.name,
    red :channel.red_name,
    request:channel.request,
    col : {r:channel.r, g:channel.g, b:channel.b}
  }
}

export function deviceToPSQL(device:Device):PSQLDevice{
  let mesures = device.mesures;
  let psqlmesures: PSQLMesureList = {};
  mesures.forEach((mesure)=>{
    psqlmesures[mesure.id]=mesureToPSQL(mesure);
  })
  return {
    name:device.name,
    uid:device.uid,
    num:device.num,
    type:device.type,
    ip:device.ip,
    project:device.project,
    client:device.client,
    mission:device.mission,
    pingtab:device.pingtab,
    mesures:psqlmesures
  }
}
