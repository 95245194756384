import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';

@Component({
  selector: 'inverter-pv-panel',
  templateUrl: './inverter-pv-panel.component.html',
  styleUrls: ['./inverter-pv-panel.component.scss']
})
export class InverterPVPanelComponent implements OnInit {
  @Output() onSelect: EventEmitter<{ sel: string, value: any }> = new EventEmitter<{ sel: string, value: any }>();
  @Output() onOpenSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLeaveSearch: EventEmitter<any> = new EventEmitter<any>();
  @Input() set onsearch(value: boolean) { this._onsearch = value; }
  get onsearch() {
    return this._onsearch;
  }
  _onsearch: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  openSearch() { this.onOpenSearch.emit(null); }
  leaveSearch() { this.onLeaveSearch.emit(null); }
  onSelectionDone(sel: string, event: any) {
    this.onSelect.emit({ sel: sel, value: event });
  }
}
