import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NodeJsonBase } from '@shared/modules/config/node-std';
import { ConfigLoaderService } from '@shared/modules/config/config-loader.service';
@Injectable({
  providedIn: 'root'
})
export class ElecPlugsService extends NodeJsonBase {

  constructor(protected http : HttpClient, protected cls : ConfigLoaderService) {
    super(http, cls);
  }
  
  get_usr_linc_device() : Observable<any> {
    var httpOptions = this.buildOptions(true,this.server_url,null);
    return this.http.get<any>(this.server_url+'/devices/usrlinc', httpOptions).pipe(
      tap(_ => {
        this.log('fetched pokemon');
        console.log('oki');
      }),
      catchError(this.handleError<any>('get_usr_linc_device', ))
    );
  }
}
