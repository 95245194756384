import { Component } from '@angular/core';
import {  Subject  } from 'rxjs';
import { CanvasList } from '@shared/classes/request-manager';
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';

import { BuildParams } from '@shared/modules/canvas-js/types/graph-builder';
import * as ParamsMaker from '@shared/classes/parameters-maker';
import { ElecPlugsService } from '../elec-plugs.service';

function initLineParams(selects,majors,mode){
  return {"select":selects,"time":0,"timetype":"short","numbers":majors,device:0,mode:mode}
}

const ENER_GRAPH = {
  y_format:{
    app_power:StyleSTD.NUMFORMAT.std,
    current:StyleSTD.NUMFORMAT.std,
    voltage:StyleSTD.NUMFORMAT.std
  },
  axis_pos:{
    app_power:StyleSTD.AXISPOS.pri,
    current:StyleSTD.AXISPOS.pri,
    voltage:StyleSTD.AXISPOS.sec
  },
  y_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std }
}

function initLincsParams(mesures, initEvent,device){
  initEvent.emit({
    apppower:initLineParams(["2"], ["0"], "app_power"),
    curvolt:initLineParams(["2"], ["0"], "curvolt")
  });
}
@Component({
  selector: 'elec-plugs-graphs',
  templateUrl: './elec-plugs-graphs.component.html',
  styleUrls: ['./elec-plugs-graphs.component.scss']
})
export class ElecPlugsGraphsComponent extends CanvasList {
  buildEvent : Subject<any> = new Subject<any>();
  dataEventNext : Subject<any> = new Subject<any>();;
  idgraphs=["apppower","curvolt"];
  linebuilds : BuildParams[] = [
    {name:"Puissance Apparente", mode:"conso", id:'apppower', type:'area'},
    {name:"Courant & Tension", mode:"conso", id:'curvolt', type:'area'},
  ]


  constructor( private elecPlugs : ElecPlugsService) {
    super();
  }
  ngOnInit(): void {
    this.elecPlugs.get_usr_linc_device().subscribe((psqllincs)=>{
      console.log(psqllincs);
      ParamsMaker.setLineParams(psqllincs, "psqllincs", "conso", ENER_GRAPH, initLincsParams, this.buildEvent, this.initRequest, 0);
    });
  }

  ngAfterViewInit(){
    this.dataEvent.subscribe((event)=>{
      this.passDatas(event,this.dataEventNext)
    });
  }
}
