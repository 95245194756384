import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';

import { ChanelSelectorComponent } from './chanel-selector/chanel-selector.component'
import { LongtimeSelectorComponent } from './longtime-selector/longtime-selector.component'
import { MajorSelectorComponent } from './major-selector/major-selector.component'
import { SensorSelectorComponent } from './sensor-selector/sensor-selector.component'
import { TimeSelectorComponent } from './time-selector/time-selector.component';
import { ServerSelectorComponent } from './server-selector/server-selector.component';
import { TimeextSelectorComponent } from './timeext-selector/timeext-selector.component';

@NgModule({
  declarations: [
    ChanelSelectorComponent,
    LongtimeSelectorComponent,
    MajorSelectorComponent,
    SensorSelectorComponent,
    TimeSelectorComponent,
    ServerSelectorComponent,
    TimeextSelectorComponent
  ],
  exports: [
    ChanelSelectorComponent,
    LongtimeSelectorComponent,
    MajorSelectorComponent,
    SensorSelectorComponent,
    TimeSelectorComponent,
    ServerSelectorComponent,
    TimeextSelectorComponent
  ],
  imports: [
    MaterialLoaderModule,
    CommonModule
  ]
})
export class SelectorsModule { }
