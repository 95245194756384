import { Component, OnInit } from '@angular/core';
import { SelectorBase } from '../selector-base';
import { TimeList } from '@shared/classes/const-canvas';
@Component({
  selector: 'time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss']
})
export class TimeSelectorComponent extends SelectorBase<any> {
  constructor() { super(); }

  ngOnInit() {
    this.list = TimeList.tab;
    this.sel = this.list[0];
  }

  getSelected(sel:string):any{ return TimeList.list[sel] }
}
