import { Component, OnInit } from '@angular/core';
import { SelectorBase } from '../selector-base';
import {GraphConfig, GraphLoaderService} from 'src/app/dataviews/graph-conf/graph-loader.service'

@Component({
  selector: 'chanel-selector',
  templateUrl: './chanel-selector.component.html',
  styleUrls: ['./chanel-selector.component.scss']
})
export class ChanelSelectorComponent extends SelectorBase<any> {
  chanelSelection = GraphConfig.select(this.gls).ener

  constructor(private gls : GraphLoaderService) { super(); }

  ngOnInit() {
    this.list = this.chanelSelection.tab;
    this.sel = this.list[0];
  }

  getSelected(sel:string):any{
    return this.chanelSelection.list[sel];
  }
}
