import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-h2-params-dialog',
  templateUrl: './h2-params-dialog.component.html',
  styleUrls: ['./h2-params-dialog.component.scss']
})
export class H2ParamsDialogComponent implements OnInit {
  public options : FormGroup;
  constructor(
    public dialogRef: MatDialogRef<H2ParamsDialogComponent>,
    public fb : FormBuilder
    , @Inject(MAT_DIALOG_DATA) public data: {  modh_func: string,  modh_value: number}
  ) {
  if(data == null){
    this.options = fb.group({
      modh_func: "0",
      modh_value:0
    });
  }else{
    this.options = fb.group({
      modh_func: data.modh_func,
      modh_value:data.modh_value
    });
  }
}

  ngOnInit() { }
  onApply(){ this.dialogRef.close(this.options.value); }
  onCancel(){ this.dialogRef.close(null); }
}
