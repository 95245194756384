<div class="inverter-pv page">
    <mat-sidenav-container class="inverter-pv-container">
        <mat-sidenav class="inverter-pv-sidenav" mode="over" [(opened)]="opened">
            <inverter-pv-panel (onSelect)="selectionDone($event)" [(onsearch)]="onsearch" (onOpenSearch)="openSearch($event)" (onLeaveSearch)="leaveSeach($event)">
            </inverter-pv-panel>
        </mat-sidenav>
        <mat-sidenav-content class="inverter-pv-content">
            <div class="sub-header">
                <inverter-pv-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></inverter-pv-head>
            </div>
            <div class="page-content">
                <div class="invpv-panels">
                    <div class="invpv-pan-state invpvpan"><inverter-pv-state-panel (initRequest)="initRequest('invstate',$event)" [dataEvent]="dataEvent.asObservable()"></inverter-pv-state-panel></div>
                    <div class="invpv-pan-ac invpvpan"><inverter-pv-acin (initRequest)="initRequest('invacin',$event)" [dataEvent]="dataEvent.asObservable()" ></inverter-pv-acin></div>
                    <!-- <div class="invpv-pan-ac invpvpan"><inverter-pv-acsync (initRequest)="initRequest('invacsync',$event)" [dataEvent]="dataEvent.asObservable()" ></inverter-pv-acsync></div> -->
                    <div class="invpv-pan-dc invpvpan"><inverter-pv-dc-panel (initRequest)="initRequest('invdc',$event)" [dataEvent]="dataEvent.asObservable()"></inverter-pv-dc-panel></div>
                </div>
                <div class="invpv-graphs">
                  <inverter-pv-graphs [idlist]="'invpvlines'" [dataEvent]="dataEvent.asObservable()"
                      (initRequest)="initRequest('invpvlines',$event)">
                  </inverter-pv-graphs>
                </div>

            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
