<div class="datacenter-overview-graphs">
  <mat-list class="barlist energy">
    <mat-list-item class="baritem" *ngFor="let build of energybuilds; let i = index"> 
     <div class="bar-div">
            <canvas-js idval="{{energybuilds[i].id}}" [build]="energybuilds[i]" [buildEvent]="buildEvent.asObservable()"
            [dataEventNext]="dataEventNext.asObservable()"></canvas-js>
          </div>
    </mat-list-item>
  </mat-list>
  <mat-list class="barlist guest">
    <mat-list-item class="baritem" *ngFor="let build of guestbuilds; let i = index">
      <div class="bar-div">
        <canvas-js idval="{{guestbuilds[i].id}}" [build]="guestbuilds[i]" [buildEvent]="buildEvent.asObservable()"
              [dataEventNext]="dataEventNext.asObservable()"></canvas-js>
          </div>
    </mat-list-item>
  </mat-list>
  <mat-list class="barlist host">
    <mat-list-item class="baritem" *ngFor="let build of hostbuilds; let i = index">
      <div class="bar-div">
        <canvas-js idval="{{hostbuilds[i].id}}" [build]="hostbuilds[i]" [buildEvent]="buildEvent.asObservable()"
              [dataEventNext]="dataEventNext.asObservable()"></canvas-js>
          </div>
    </mat-list-item>
  </mat-list>
</div>
