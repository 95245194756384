import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';

import { ElecPlugsPageComponent } from './elec-plugs-page.component';
import { ElecPlugsHeadComponent } from './elec-plugs-head/elec-plugs-head.component';
import { ElecPlugsPanelComponent } from './elec-plugs-panel/elec-plugs-panel.component';
import { ElecPlugsGraphsComponent } from './elec-plugs-graphs/elec-plugs-graphs.component'

@NgModule({
  declarations: [ ElecPlugsPageComponent, ElecPlugsHeadComponent, ElecPlugsPanelComponent, ElecPlugsGraphsComponent ],
  exports: [ ElecPlugsPageComponent ],
  imports: [
    MaterialLoaderModule,
    SelectorsModule,
    CanvasJsModule,
    CommonModule
  ]
})
export class ElecPlugsModule { }
