import { Component, Input, OnInit } from '@angular/core';
import { GenGraph } from './types/graphs/gen-graph';
import { Observable } from 'rxjs';
import { BuildParams, BuildGraph } from './types/graph-builder';

@Component({
  selector: 'canvas-js',
  templateUrl: './canvas-js.component.html',
  styleUrls: ['./canvas-js.component.scss']
})
export class CanvasJsComponent implements OnInit {
  constructor() { }
  @Input() buildEvent :Observable<any>;
  @Input() dataEventNext :Observable<any>;
  @Input() idval : string;//id of target graph
  @Input() build : BuildParams;//target graph
  graph : GenGraph;

  ngOnInit() {
    this.graph = BuildGraph(this.build);
    this.buildEvent.subscribe((conf)=>{
      if(this.build.mode in conf){
        if(!this.graphIsNull()){
          this.graph.initChart(this.idval, conf[this.build.mode]);
        }else{
          console.log("graph is null")
        }
      }
    });
  }

  graphIsNull():boolean{
    return this.graph == null;
  }

  ngAfterViewInit() {
    this.dataEventNext.subscribe((data)=>{
      if(this.graph.getChart()!=null){
        if(this.idval==data.graph){
          if(this.graph.controlDataValidity(data.datas)){
            switch (data.set_mode) {
              case "main":
                this.graph.setLines(data.datas,this.graph.getType());
                break;
              case "update":
                this.graph.updateGraph(data.datas,this.graph.getType());
                break;
              case "search":
                this.graph.setLines(data.datas,this.graph.getType());
                break;
              default:
                break;
          }
          }else{
            // console.log("No data recieved");
            this.graph.removeAllPoints();
          }
          this.graph.updateAxes("a");
          this.graph.updateDisplay();
        }
      }
    });

  }
}
