import { RequestBase } from './request-manager';
import * as Dev from './device-data';
import * as CanvInter from './canvas-interface';
import * as CanvEnt from '@shared/modules/canvas-js/types/canvas-entries';

export function readLinePSQLConf(devmes:Dev.PSQLMesureList, mode:string, style:any, event:any){
  var inputs: CanvEnt.LineGraphEntry = {
    lines: CanvInter.psqlMesListToLineEntries(devmes),
    styles:CanvInter.toLineStyleEntry(devmes,style)
  };
  console.log(inputs);
  var elt = {}
  elt[mode]={config:null,mesures:null,style:null,input_conf:inputs}
  event.next(elt);
}

export function readDoughPSQLConf(devmes:Dev.PSQLMesureList, mode:string, style:any, event:any){
  var inputs: CanvEnt.DoughnutGraphEntry = {
    lines: CanvInter.psqlMesListToDoughnutEntries(devmes),
    styles:style
  };
  var elt = {}
  elt[mode]={config:null,mesures:null,style:null,input_conf:inputs}
  event.next(elt);
}

export function readBarPSQLConf(devmes: Dev.PSQLMesureList, mode: string, style: any, event: any) {
  var inputs: CanvEnt.BarGraphEntry = {
    lines: CanvInter.psqlMesListToBarEntries(devmes, style),
    styles: style
  };
  var elt = {}
  elt[mode] = { config: null, mesures: null, style: null, input_conf: inputs }
  event.next(elt);
}

export function initLineParams(selects:string[], majors:string[], mode:string, device:number=0):RequestBase{
  return {"select":selects, "time":0, "timetype":"short", "numbers":majors, device:device, mode:mode}
}

export function initBarParams(selects: string[], time:number, majors: string[], mode: string, device: number = 0): RequestBase {
  return { "select": selects, "time": 0, "timetype": "nogroup", "numbers": majors, device: device, mode: mode }
}


export function setLineParams(datas, src:string, mode:string, style, initParams, buildEvent, initRequest,device:number=0,res_num:number=0){
  let mesures = datas[src][res_num].mesures;
  readLinePSQLConf( mesures, mode, style, buildEvent);
  initParams(mesures, initRequest, device);
}

export function setDoughParams(datas, src:string, mode:string, style, initParams, buildEvent, initRequest,device:number=0){
  let mesures = datas[src][0].mesures;
  readDoughPSQLConf( mesures, mode, style, buildEvent);
  initParams(mesures, initRequest, device);
}
export function setBarParams(datas, src: string, mode: string, style, initParams, buildEvent, initRequest, device: number[] = [0], res_num: number = -1) {
  let mesures = datas[src][res_num].mesures;
  readBarPSQLConf(mesures, mode, style, buildEvent);
  initParams(mesures, initRequest, device);
}

export function setTabParams(datas, src:string, mode:string, initParams, initRequest, device:number=0){
  let mesures = datas[src][0].mesures;
  initParams(mesures, initRequest, device);
}
