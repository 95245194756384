import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'switch-confirm-dialog',
  templateUrl: './switch-confirm-dialog.component.html',
  styleUrls: ['./switch-confirm-dialog.component.scss']
})
export class SwitchConfirmDialogComponent implements OnInit {

    ngOnInit() {}

    constructor(
      public dialogRef: MatDialogRef<SwitchConfirmDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    onYesClick(){
      this.dialogRef.close(this.data);
    }
    onNoClick(){
      this.dialogRef.close(null);
    }
}
