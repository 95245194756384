import { SelectorDefinition } from '@shared/modules/selectors/selector-base';

export const SensorList : SelectorDefinition<string[]>
  = new SelectorDefinition({
    "Toutes Mesures" : ["tem","hum","light","cov","co2"],
    "Température" : ["tem"],
    "Humidité" : ["hum"],
    "Lumière" : ["light"],
    "COV" : ["cov"],
    "CO2" : ["co2"]
  });

export const FIELDPIESENSOR : string[] = ['tem','hum','cov','co2']

export const TimeList : SelectorDefinition<any>
  = new SelectorDefinition({
    "Heure":{time:"h",group:{unit:"s",value:5}},
    "Jour":{time:"d",group:{unit:"m",value:2}},
    "Semaine":{time:"w",group:{unit:"m",value:15}},
    "Mois":{time:"M",group:{unit:"h",value:1}},
    "Année":{time:"y",group:{unit:"d",value:1}}
  });

export const TimeExtList : SelectorDefinition<any>
  = new SelectorDefinition({
    "Heure":{time:"h",group:{unit:"s",value:5}},
    "Jour":{time:"d",group:{unit:"m",value:2}},
    "Semaine":{time:"w",group:{unit:"m",value:15}},
    "7 Jours": { time: "7d", group: { unit: "m", value: 15 } },
    "Mois":{time:"M",group:{unit:"h",value:1}},
    "30 Jours": { time: "30d", group: { unit: "h", value: 1 } },
    "Année":{time:"y",group:{unit:"d",value:1}}
  });

export const TimeListNoGroup: SelectorDefinition<any>
  = new SelectorDefinition({
    "Heure":{time:"h",group:{unit:"s",value:0}},
    "Jour":{time:"d",group:{unit:"m",value:0}},
    "Semaine":{time:"w",group:{unit:"m",value:0}},
    "Mois":{time:"M",group:{unit:"h",value:0}},
    "Année":{time:"y",group:{unit:"d",value:0}}
  });

export const PrevTimeList: SelectorDefinition<any>
  = new SelectorDefinition({
    "Heure": { time: "ph", group: { unit: "s", value: 0 } },
    "Jour": { time: "pd", group: { unit: "m", value: 0 } },
    "Semaine": { time: "pw", group: { unit: "m", value: 0 } },
    "Mois": { time: "pM", group: { unit: "h", value: 0 } },
    "Année": { time: "py", group: { unit: "d", value: 0 } }
  });

export const AlarmTimeList: SelectorDefinition<any>
  = new SelectorDefinition({
    "Minute": { time: "m", group: { unit: "s", value: 0 } }
  });

export const LongTimeList: SelectorDefinition<any>
    = new SelectorDefinition({
      "Jour":{time:"d",group:{unit:"h",value:1}},
      "Semaine":{time:"w",group:{unit:"h",value:1}},
      "Mois":{time:"M",group:{unit:"h",value:1}},
      "Année":{time:"y",group:{unit:"d",value:1}}
  });

export const NGTIMELIST = {
  "Heure":{time:"h",group:{unit:"s",value:0}},
  "Jour":{time:"d",group:{unit:"m",value:0}},
  "Semaine":{time:"w",group:{unit:"m",value:0}},
  "Mois":{time:"M",group:{unit:"h",value:0}},
  "Année":{time:"y",group:{unit:"d",value:0}}
}

export const PeriodList: SelectorDefinition<any>
  = new SelectorDefinition({
    "Jour":'d',
    "Semaine":'w',
    "Mois":'M',
    "Année":'y'
  });

export const MonthList: SelectorDefinition<any>
  = new SelectorDefinition({
    "Janvier":0, "Février":1, "Mars":2, "Avril":3,
    "Mai":4, "Juin":5, "Juillet":6, "Août":7,
    "Septembre":8, "Octobre":9, "Novembre":10, "Décembre":11
  });

export const YEARS : number[] = [2018,2019];

export const GROUPLIST = {
  "h":{unit:"s",value:5},
  "d":{unit:"m",value:2},
  "w":{unit:"m",value:15},
  "M":{unit:"h",value:1},
  "y":{unit:"d",value:12},
  "no":{unit:"d",value:0}
}

export function getDaysOfMonth(year:number,month:number):number[]{
  var d = new Date(year,month+1,0);
  var num =  d.getDate();
  var ds :number[] = [];
  for(var i = 1; i < num+1;i++)
    ds.push(i);
  return ds;
}
