<div class="inverter-pv-dc">
    <div class="title-div">
        <div class="txt-div">Bus DC</div>
    </div>
    <div class="dc-field-list">

        <div class="dc-field-div" *ngFor="let field of fieldnames">
            <div class="field-title">
                <div class="fieldtxt"> {{fields[field].title}} </div>
            </div>
            <div class="field-value">
                <div class="valuetxt">
                    {{fields[field].value}}
                </div>
            </div>
        </div>
    </div>
</div>
