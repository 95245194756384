<div class="mma-div">
  <table>
    <tr>
      <th>Min</th>
      <th>Moy</th>
      <th>Max</th>
    </tr>
    <tr>
      <td>{{min}}</td>
      <td>{{mean}}</td>
      <td>{{max}}</td>
    </tr>
  </table>
</div>
