import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject  } from 'rxjs';
import { DatacenterEnergyService } from '../datacenter-energy.service';

import { CanvasList, BuildParams } from '@shared/classes/request-manager';
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';
import * as ParamsMaker from '@shared/classes/parameters-maker';
import * as DevData from '@shared/classes/device-data';

const ENER_GRAPH = {
  y_format:{
    app_power:StyleSTD.NUMFORMAT.std,
    real_power:StyleSTD.NUMFORMAT.std,
    current:StyleSTD.NUMFORMAT.std,
    voltage:StyleSTD.NUMFORMAT.std
  },
  axis_pos:{
    app_power:StyleSTD.AXISPOS.pri,
    real_power:StyleSTD.AXISPOS.pri,
    current:StyleSTD.AXISPOS.pri,
    voltage:StyleSTD.AXISPOS.pri
  },
  y_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std }
}

function initLincParams(mesures, initEvent,device){
  initEvent.emit({
    apppower: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"app_power"),["0"],"app_power"),
    realpower: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"real_power"),["0"],"real_power"),
    voltage: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"voltage"),["0"],"voltage"),
    current: ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"current"),["0"],"current")
  });
}

@Component({
  selector: 'datacenter-energy-graphs',
  templateUrl: './datacenter-energy-graphs.component.html',
  styleUrls: ['./datacenter-energy-graphs.component.scss']
})
export class DatacenterEnergyGraphsComponent extends CanvasList {
  @Output() serv_devs : EventEmitter<any> = new EventEmitter<any>();
  @Input() devChangeEvent : Observable<any>;
  buildEvent : Subject<any> = new Subject<any>();
  dataEventNext : Subject<any> = new Subject<any>();

  idgraphs=["apppower", "realpower", "voltage", "current"];
  linebuilds : BuildParams[] = [
      {name:"Puissance Apparente" ,mode:"conso" ,id:"apppower", type:"area"},
      {name:"Puissance Réelle" ,mode:"conso" ,id:"realpower", type:"area"},
      {name:"Tension" ,mode:"conso" ,id:"voltage", type:"area"},
      {name:"Courant" ,mode:"conso" ,id:"current", type:"area"}
  ]

  constructor(private datacenter_energy : DatacenterEnergyService) {
    super();
  }

  ngOnInit(): void {
    this.datacenter_energy.get_usr_linc_device().subscribe((psqllincs)=>{
      ParamsMaker.setLineParams(psqllincs, "psqllincs", "conso", ENER_GRAPH, initLincParams, this.buildEvent, this.initRequest, 0);
    });
    this.datacenter_energy.get_usr_hosts_device().subscribe((hosts_list)=>{
      var hosts = hosts_list["hosts"];
      this.datacenter_energy.post_usr_guests_device(hosts[0].index).subscribe((guests_list)=>{
        var guests = guests_list["guests"];
        this.serv_devs.emit({hosts:hosts, guests:guests});
      })
    });

  }

  ngAfterViewInit(){
    this.dataEvent.subscribe((event)=>{
      this.passDatas(event,this.dataEventNext);
    });
  }


}
