<div class="switch-dialog">
  <div class="dialog-view">
    <div class="dialog-title">Interrupteur :</div>
    <div class="switch-view">
      <div>ID : {{data.id}}</div>
      <div>Nom : {{data.Nom}}</div>
    </div>
    <div class="switch-button">
      <button mat-button class="btn-no" (click)="onNoClick()">Non</button>
      <button mat-button class="btn-yes" (click)="onYesClick()">Oui</button>
    </div>
  </div>
</div>
