import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { RequestPage, buildSearchParams } from '@shared/classes/request-manager';
import { DatacenterVMService } from './datacenter-vm.service';
import { SearchPopUpComponent } from '@shared/modules/dialog/search-pop-up/search-pop-up.component'
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'datacenter-vm-page',
  templateUrl: './datacenter-vm-page.component.html',
  styleUrls: ['./datacenter-vm-page.component.scss']
})
export class DatacenterVMPageComponent extends RequestPage {
  opened:boolean=false;
  initVMEvent : Subject<any> = new Subject<any>();
  updateSelectorEvent : Subject<any> = new Subject<any>();
  hostUpdate : Subject<any> = new Subject<any>();
  sitesUpdate : Subject<any> = new Subject<any>();
  onsearch:boolean=false;
  constructor(private actroute: ActivatedRoute, private router: Router, protected datacenter_vm: DatacenterVMService, private dialog: MatDialog) {

    super(datacenter_vm);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    // this.name = params['name'];
    this.actroute.queryParams.subscribe(params => {
      // console.log(params);
      this.initVMEvent.next(params);
      
    });
    this.setRefresh();
  }

  onVMSites(event){
    console.log(event);
    this.sitesUpdate.next(event);
  }

  onGetHost(event){
    // console.log(event);
    this.hostUpdate.next(event);
  }

  leaveSeach(event) {
    this.onsearch = false;
    this.opened = false;
    this.manager.requestPoints("main", (req, requestlist, key, key2) => { return true; });
    this.setRefresh();
  }

  openSearch(event) {
    const dialogRef = this.dialog.open(SearchPopUpComponent, {
      autoFocus: true
    });
    this.stopRefresh();
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.onsearch = true;
        this.opened = false;
        console.log(result);
        var tmppar = buildSearchParams(null, result.time, result.group, null);
        this.manager.selectSearch(tmppar, (req, requestlist, orig, graphid) => {
          return true;
        });
        this.manager.requestPoints("search", (req, requestlist, key, key2) => { return true; });
      }
      else {
        console.log("cancel");
      }
    });
  }

  selectionDone(event:{sel:string,value:any}){
    this.opened=false;
    switch(event.sel){
      case 'time' :
        this.manager.selectTime(event.value,(req, requestlist, orig, graphid)=>{ return true; });
        this.manager.requestPoints("main",(req, requestlist, key, key2)=>{return true;})
        break;
      case 'channel' :
        this.manager.selectFields(event.value,(req, requestlist, orig, graphid)=>{
          return req.mode=="conso";
        });
        this.manager.requestPoints("main",(req, requestlist, key, key2)=>{return req.mode=="conso";})
        break;
      default:break;
    }
    this.setRefresh();
  }

  initSelectors(datas){
    var initsel = {}
    datas.val.forEach((data) => {
      initsel[data["name"]]=data["index"];
    });
    this.updateSelectorEvent.next({sel:datas.sel,val:initsel});
  }

  toEms(){
    this.router.navigate(['/ems/graph']).then(e => {
      if(e){ console.log("Navigation success!"); }
      else{ console.log('Navigation failed :()'); }
    });
  }

  openPanel(){
    this.opened=true;
  }
  ngOnDestroy(){ this.stopRefresh(); }

}
