import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MatTableDataSource, MatTable } from '@angular/material/table';

interface ELYData {
  pow: number,
  tem: number,
  pre: number
}

@Component({
  selector: 'ely-table',
  templateUrl: './ely-table.component.html',
  styleUrls: ['./ely-table.component.scss']
})
export class ELYTableComponent implements OnInit {
  @Input() dataEventNext :Observable<any>;
  @Input() idval : string;
  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns : string[]=["pow", "tem", "pre"];
  colNames={
    "pow" : "Production",
    "tem" : "Température",
    "pre" : "Pression"
  }

  dataSource : MatTableDataSource<ELYData> = new MatTableDataSource([{"pow" :0, "tem" : 0, "pre" : 0}]);
  constructor() { }
  ngOnInit() { }

  ngAfterViewInit() {
    this.dataEventNext.subscribe((data)=>{
      if(this.idval==data.graph){
        var datas = data.datas;
        if(datas.length > 0)
          if(datas[0].length > 0){
            let elt  = datas[0][0];
            this.updateTable({
              pow:elt['app_powerely'],
              pre:elt['pressureely'],
              tem:elt['temely']
            });
          }
        }
      });
    }

  updateTable(pac_data:ELYData): void{
     this.dataSource.data = [pac_data];
     this.table.renderRows();
   }

}
