import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanvasJsComponent } from './canvas-js.component';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';

@NgModule({
  declarations: [CanvasJsComponent],
  exports: [ CanvasJsComponent ],
  imports: [ CommonModule, MaterialLoaderModule ]
})
export class CanvasJsModule { }
