import { GenGraph } from './gen-graph';
import * as CanvasJs from 'src/assets/js-script/canvasjs/canvasjs.min.js';
import * as StyleSTD from '../../style/style-std';
import { Datapoint, DoughnutLine } from '../canvas-std';
import { DoughnutGraphEntry } from '../canvas-entries';

export class DoughnutGraph extends GenGraph {
  public constructor(name:string,mode:string,id:string){
    super(name,mode,id,'doughnut');
  }

  protected initDatas(data:DoughnutLine[]):void {
      data.push(this.initLine('y'));
  }


  public initChart(target:string,build:any) : void {
    
    this.styles = build.input_conf.styles;
    this.entries = build.input_conf.lines;
    let options = StyleSTD.STDCHART();
    this.chart = new CanvasJs.Chart(target,options);
    this.initDatas(this.chart.options.data);
  }

  protected initLine(key:string) : DoughnutLine {
    if(this.styles!=null){
      return StyleSTD.DOUGHNUTFORMAT(key,this.styles.tip_format,this.styles.y_format)
    }
    return  StyleSTD.DOUGHNUTSTD(key,this.mode);
  }

  protected addPoints(line : DoughnutLine ,xkey : string,ykey : string,datas : Datapoint[]) : void{
      datas.forEach((data)=>{
        var names = this.entries[data[xkey]];
        line.dataPoints.push({comp:names.comp,y:data[ykey],color:names.color,red:names.red,unit:names.unit});
      });
      ;
    return;
  }


  public controlDataValidity(datas : Datapoint[][]) : boolean{
    if(super.controlDataValidity(datas)){
      for(var i = 0; i < datas.length; i++){
        for(var j = 0; j < datas[i].length;j++){
          if(datas[i][j].y!=0 && datas[i][j].y!=null)return true;
        }
        return false;
      }
    } else{ return false; }
  }

}
