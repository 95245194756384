import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { InverterPVService } from '../../inverter-pv.service';
import { BuildParams } from '@shared/modules/canvas-js/types/graph-builder';

interface InvFieldList{
  [name: string]: {
    title:string;
    value:string;
    unit:string;
  }
}

function initLineParams(selects, majors, mode) {
  return { "select": selects, "time": 0, "timetype": "short", "numbers": majors, device: 0, mode: mode }
}

function initPhoenixParams(initEvent) {
  initEvent.emit({
    power_accomsump: initLineParams([1], ["0"], "phoenixlast_ac"),
    voltageuinv: initLineParams([2], ["0"], "phoenixlast_volt"),
    currentiinv: initLineParams([2], ["0"], "phoenixlast_cur")
  });
}

@Component({
  selector: 'inverter-pv-acin',
  templateUrl: './inverter-pv-acin.component.html',
  styleUrls: ['./inverter-pv-acin.component.scss']
})
export class InverterPvACINComponent implements OnInit {
  @Output() initRequest : EventEmitter<any> = new  EventEmitter<any>();
  @Input() dataEvent: Observable<any>;

  fields: InvFieldList = {
    "voltageuinv": {
      title: "Tension",
      value: "-1",
      unit: "V"
    },
    "currentiinv": {
      title: "Courant",
      value: "-1",
      unit:"A"
    },
    "power_accomsump":{
      title : "Puissance Apparente",
      value: "-1",
      unit:"VA"
    }
  };

  fieldnames = Object.keys(this.fields);

  constructor() { }

  ngOnInit(): void { initPhoenixParams(this.initRequest); }

  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {

      if(event.orig=="invacin"){
        console.log(event.orig," ",event.datas);
        let datas = event.datas;
        if(datas!=null){
          if(datas.length > 0){
            if(datas[0] != null){
              if(datas[0].length > 0){
                if(datas[0][0]!=null){
                  console.log(datas[0][0])
                  let keys = Object.keys(this.fields);
                  keys.forEach((key) => {
                    if(key in datas[0][0]){
                      console.log(key);
                      this.fields[key].value = ""+(Math.round(( datas[0][0][key] + Number.EPSILON) * 100) / 100)+""+this.fields[key].unit;
                      console.log(this.fields[key].value);
                    }
                  });
                }
              }
            }
          }
        }
      }
    });
  }

}
