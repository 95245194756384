import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PVConfigDialogComponent } from '../../pv-dialog/pv-config-dialog/pv-config-dialog.component'
import { PVService } from '../../pv.service';
interface PVDatas{
  warn_temp:number;
  alert_temp:number;
  module_name:string;
  uid:string;
  cable_section:string;
}

function init_params() : PVDatas[] {
  return [{
  uid:"",
  warn_temp:0,
  alert_temp:0,
  module_name:"",
  cable_section:""
}]};

@Component({
  selector: 'parameters-view',
  templateUrl: './parameters-view.component.html',
  styleUrls: ['./parameters-view.component.scss']
})
export class ParametersViewComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any>;

  displayedColumns : string[]=["warn_temp", "alert_temp", "module_name", "cable_section"];
  colNames = {
    "warn_temp" : "Température Warning",
    "alert_temp" : "Température Sécuritée Incendie",
    "module_name" : "Nom Module",
    "cable_section" : "Section Cable"
  }
  dataSource : MatTableDataSource<PVDatas> = new MatTableDataSource(init_params());
  params: PVDatas;
  constructor(private router : Router,public pv : PVService, public dialog : MatDialog) { }

  ngOnInit() {
    this.getParams();
  }

  getParams(){
    this.pv.getParam().subscribe((data)=>{
      this.params = data["params"][0];
      this.params=init_params()[0];
      this.updateTable();
    });
  }
  updateTable(): void{
    this.dataSource.data = [this.params];
    this.table.renderRows();
  }

  onModifyClick(event:PVDatas){
    const dialogRef = this.dialog.open(PVConfigDialogComponent, {
      autoFocus:true,
      data: this.params
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result!=null){
        this.pv.setParam(result).subscribe((data)=>{
          this.params = data["params"][0];
          this.updateTable();
        });
      }
      console.log("close");
    });
  }
}
