import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';
import { ChargingPointHeadComponent } from './charging-point-head/charging-point-head.component';
import { ChargingPointPanelComponent } from './charging-point-panel/charging-point-panel.component';
import { ChargingPointGraphsComponent } from './charging-point-graphs/charging-point-graphs.component';
import { ChargingPointPageComponent } from './charging-point-page.component';



@NgModule({
  declarations: [ChargingPointHeadComponent, ChargingPointPanelComponent, ChargingPointGraphsComponent, ChargingPointPageComponent],
  imports: [
    MaterialLoaderModule,
    SelectorsModule,
    CanvasJsModule,
    CommonModule
  ]
})
export class ChargingPointModule { }
