import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'elec-plugs-head',
  templateUrl: './elec-plugs-head.component.html',
  styleUrls: ['./elec-plugs-head.component.scss']
})
export class ElecPlugsHeadComponent  implements OnInit {
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenPanel: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {}

  toEms(){
    this.onGoBack.emit();
  }

  openPanel(){
    this.onOpenPanel.emit();
  }
}
