import { Component } from '@angular/core';
import { Subject  } from 'rxjs';

import { CanvasList } from '@shared/classes/request-manager';
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';
import { BuildParams } from '@shared/modules/canvas-js/types/graph-builder';
import * as ParamsMaker from '@shared/classes/parameters-maker';

import { H2Service } from '../h2.service';


function initLineParams(selects,majors,mode){
  return {"select":selects,"time":0,"timetype":"short","numbers":majors,"device":0,mode:mode}
}

const H2_GRAPH = {
  y_format:{
    app_power:StyleSTD.NUMFORMAT.std,
    tem:StyleSTD.NUMFORMAT.std,
    pressure:StyleSTD.NUMFORMAT.std,
    soc:StyleSTD.NUMFORMAT.std
  },
  axis_pos:{
    app_power:StyleSTD.AXISPOS.pri,
    tem:StyleSTD.AXISPOS.pri,
    pressure:StyleSTD.AXISPOS.pri,
    soc:StyleSTD.AXISPOS.pri
  },
  y_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std }
}

function initH2Params(mesures, initEvent,device){
  initEvent.emit({
    greenpowac:initLineParams([0],["0"],"greenbox_pow"),
    greensoc:initLineParams([0],["0"],"greenbox_soc"),
    greenpow:initLineParams([1, 2],["0"],"greenbox_pow"),
    greentem:initLineParams([0, 1],["0"],"greenbox_tem"),
    greenpre:initLineParams([0, 1],["0"],"greenbox_pre")
  });
}
@Component({
  selector: 'h2-graphs',
  templateUrl: './h2-graphs.component.html',
  styleUrls: ['./h2-graphs.component.scss']
})
export class H2GraphsComponent extends CanvasList {
  buildEvent : Subject<any> = new Subject<any>();
  dataEventNext : Subject<any> = new Subject<any>();

  idgraphs=["greenpowac", "greensoc", "greenpow", "greentem", "greenpre"];
  linebuilds : BuildParams[] = [
      {name:"Puissance AC", mode:"greenbox", id:'greenpowac', type:'area'},
      {name:"State of Charge", mode:"greenbox", id:'greensoc', type:'area'},
      {name:"Puissance PAC et ELY", mode:"greenbox", id:'greenpow', type:'area'},
      {name:"Température PAC et ELY", mode:"greenbox", id:'greentem', type:'area'},
      {name:"Pression PAC et ELY", mode:"greenbox", id:'greenpre', type:'area'},
  ]

  constructor( private h2 : H2Service) {
    super();
  }

  ngOnInit() {
    this.h2.get_usr_greenbox_device().subscribe((greenboxs)=>{
      ParamsMaker.setLineParams(greenboxs, "greenboxs", "greenbox", H2_GRAPH, initH2Params, this.buildEvent, this.initRequest, 0);
    });
  }

  ngAfterViewInit(){
    this.dataEvent.subscribe((event)=>{
      this.passDatas(event,this.dataEventNext);
    });
  }


}
