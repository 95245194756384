import { Component, Input, Output, EventEmitter } from '@angular/core';
import {  Observable, Subject  } from 'rxjs';
import { CanvasList, BuildParams  } from '@shared/classes/request-manager';
import { DatacenterVMService } from '../datacenter-vm.service'
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';

import * as ParamsMaker from '@shared/classes/parameters-maker';
import * as DevData from '@shared/classes/device-data';

const RESLOG_GRAPH = {
  y_format:{

    cpu:StyleSTD.NUMFORMAT.std,
    ram:StyleSTD.NUMFORMAT.std+"G",
    disk:StyleSTD.NUMFORMAT.std,
    usage:StyleSTD.NUMFORMAT.std,
    eneruse:StyleSTD.NUMFORMAT.std
  },
  axis_pos:{
    cpu:StyleSTD.AXISPOS.pri,
    ram:StyleSTD.AXISPOS.pri,
    disk:StyleSTD.AXISPOS.pri,
    usage:StyleSTD.AXISPOS.pri,
    eneruse:StyleSTD.AXISPOS.pri
  },
  y_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std }
}

function initServUsagesParams(mesures, initEvent,device){
  initEvent.emit({
    cpu:ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"cpu"), ["0"], "cpu",device),
    ram:ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"ram"), ["0"], "ram",device),
    disk:ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"disk"), ["0"], "disk",device),
    usage:ParamsMaker.initLineParams( DevData.listPSQLChan(mesures,"usage"), ["0"], "usage",device),
    eneruse:ParamsMaker.initLineParams(DevData.listPSQLChan(mesures,"eneruse"), ["0"], "eneruse",device)
  });
}


@Component({
  selector: 'datacenter-vm-graphs',
  templateUrl: './datacenter-vm-graphs.component.html',
  styleUrls: ['./datacenter-vm-graphs.component.scss']
})
export class DatacenterVMGraphsComponent extends CanvasList {
  @Input() initVMEvent : Observable<any>;
  buildEvent : Subject<any> = new Subject<any>();
  dataEventNext : Subject<any> = new Subject<any>();
  @Output() serv_devs : EventEmitter<any> = new EventEmitter<any>();
  @Output() vm_sites : EventEmitter<any> = new EventEmitter<any>();

  idgraphs=["cpu", "disk", "ram", "usage", "eneruse"];
  linebuilds : BuildParams[] = [
      {name:"Charge CPU" ,mode:"reslog" ,id:"cpu", type:"area"},
      {name:"Charge Disk" ,mode:"reslog" ,id:"disk", type:"area"},
      {name:"Charge RAM" ,mode:"reslog" ,id:"ram", type:"area"},
      {name:"Usages Service" ,mode:"reslog" ,id:"usage", type:"area"},
      {name:"Usages Energy" ,mode:"reslog" ,id:"eneruse", type:"area"}
  ]



  constructor( private datacenter_vm : DatacenterVMService) {
    super();
  }

  ngOnInit() {
    this.initVMEvent.subscribe((event)=>{
      console.log(event)
      let indexvm=event.indexvm;
      let hostindex = event.hostindex;
      this.datacenter_vm.post_usr_target_device(indexvm).subscribe((reslogs)=>{
        console.log(reslogs)
        ParamsMaker.setLineParams(reslogs, "target", "reslog", RESLOG_GRAPH, initServUsagesParams, this.buildEvent, this.initRequest, indexvm);
        this.datacenter_vm.post_usr_vmsys(indexvm).subscribe((datas) => {
          var vm = datas["vmsys"][0];
          this.vm_sites.emit({ sites: vm["vm_sys"],vmnum:reslogs["target"][0]["num"] });
        });
      });

      this.datacenter_vm.get_usr_hosts_device().subscribe((hosts_list) => {
        var hosts = hosts_list["hosts"];
        this.datacenter_vm.post_usr_guests_device(hosts[hostindex].index).subscribe((guests_list) => {
          var guests = guests_list["guests"];
          this.serv_devs.emit({ hosts: hosts, guests: guests, hostindex: hostindex });
        })
      });
    });
  }

  ngAfterViewInit(){
    this.dataEvent.subscribe((event)=>{
      this.passDatas(event,this.dataEventNext);
    });
  }

}
