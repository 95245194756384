<div class="reseau page">
  <mat-sidenav-container class="reseau-container">
    <mat-sidenav class="reseau-sidenav" mode="over" [(opened)]="opened">
      <reseau-panel (onSearchEvent)="onSearch($event)" (onSelect)="selectionDone($event)" [searchEvent]="searchEvent.asObservable()">
      </reseau-panel>
    </mat-sidenav>
    <mat-sidenav-content class="reseau-content">
      <div class="sub-header">
        <reseau-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></reseau-head>
      </div>
      <div class="page-content">
        <reseau-graph [idlist]="'lines'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('lines',$event)">
        </reseau-graph>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
