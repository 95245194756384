import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { InitParams, DataEvent, CanvasList, RequestBase, BuildParams } from '@shared/classes/request-manager';
import { DatacenterOverviewService } from '../datacenter-overview.service'
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';
import * as ParamsMaker from '@shared/classes/parameters-maker';
import * as DevData from '@shared/classes/device-data';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

const BAR_GRAPH = {
  y_format:{
    cpuguestuse:StyleSTD.NUMFORMAT.std,
    ramguestuse:StyleSTD.NUMFORMAT.std,
    spaceguestuse:StyleSTD.NUMFORMAT.std,
    enrguestuse:StyleSTD.NUMFORMAT.kilo,
    prevenrguestuse:StyleSTD.NUMFORMAT.kilo,
    ratecpuuse:StyleSTD.NUMFORMAT.std,
    rateramuse:StyleSTD.NUMFORMAT.std,
    ratespaceuse:StyleSTD.NUMFORMAT.std,
  },
  toolTipContent:"{label}: {y}{unit}",
  index_label: "",
  showcomp:{mes:false, chan:true},
  showred:{mes:false, chan:true}
}


function initMGCPUParams(mesures, initEvent, guests_index) {
  var params = {
    cpuguestuse: ParamsMaker.initBarParams(guests_index, 3,["0"], "mg_cpuuse"),
    ramguestuse: ParamsMaker.initBarParams(guests_index, 3,["0"], "mg_ramuse"),
    spaceguestuse: ParamsMaker.initBarParams(guests_index, 3,["0"], "mg_diskspace"),
    enrguestuse: ParamsMaker.initBarParams(guests_index, 3,["0"], "mg_eneruse"),
    prevenrguestuse: { "select": guests_index, "time": 0, "timetype": "prev", "numbers": ["0"], device: 0, mode: "mg_eneruse" },
    ratecpuuse: ParamsMaker.initBarParams(guests_index, 3,["0"], "vm_cpurate"),
    rateramuse: ParamsMaker.initBarParams(guests_index, 3,["0"], "vm_ramrate"),
    ratespaceuse: ParamsMaker.initBarParams(guests_index, 3,["0"], "vm_diskspace"),
  }
  var keys = Object.keys(params);
  keys.forEach((key) => {
    params[key].time=3;
  });
  initEvent.emit(params);
}

@Component({
  selector: 'datacenter-overview-graphs',
  templateUrl: './datacenter-overview-graphs.component.html',
  styleUrls: ['./datacenter-overview-graphs.component.scss']
})
export class DatacenterOverviewGraphsComponent extends CanvasList {
  @Output() serv_devs: EventEmitter<any> = new EventEmitter<any>();
  
  buildEvent: Subject<any> = new Subject<any>();
  dataEventNext: Subject<any> = new Subject<any>();
  constructor(private datacenter_overview: DatacenterOverviewService, private route : ActivatedRoute) {
    super();
  }
  
  energybuilds: BuildParams[] = [
    { name: "Consommation du mois", mode: "reslog", id: "enrguestuse", type: "bar" },
    { name: "Consommation du mois précédent", mode: "reslog", id: "prevenrguestuse", type: "bar" }
  ]
  guestbuilds: BuildParams[] = [
    { name: "Part Des Usages CPU", mode: "reslog", id: "cpuguestuse", type: "bar" },
    { name: "Utilisation RAM", mode: "reslog", id: "ramguestuse", type: "bar" },
    { name: "Utilisation Disque", mode: "reslog", id: "spaceguestuse", type: "bar" }
  ]
  hostbuilds: BuildParams[] = [
    { name: "Taux d'Utilisation CPU", mode: "reslog", id: "ratecpuuse", type: "bar" },
    { name: "Taux d'Utilisation RAM", mode: "reslog", id: "rateramuse", type: "bar" },
    { name: "Taux d'Utilisation Disque", mode: "reslog", id: "ratespaceuse", type: "bar" }
  ]

  loadHostAt(hosts, hostindex, next){
    this.datacenter_overview.post_usr_guests_device(hosts[hostindex].index).subscribe((guests_list) => {
      var guests = guests_list["guests"];
      this.serv_devs.emit({ hosts: hosts, guests: guests, hostindex:hostindex });
      var guests_index = []
      guests.forEach((guest) => {
        guests_index.push([guest.index]);
      });
      next(guests_index);
      // ParamsMaker.setBarParams(hosts_list, "hosts", "reslog", BAR_GRAPH, initMGCPUParams, this.buildEvent, this.initRequest, guests_index);
    });
  }

  ngOnInit(): void {
    

    this.route.url.subscribe((url) => {
      var routeParams = this.route.snapshot.paramMap;
      var hostindex = Number(routeParams.get('indexvm'));
      this.datacenter_overview.get_usr_hosts_device().subscribe((hosts_list) => {
        var hosts = hosts_list["hosts"];
        console.log(hosts);
        this.loadHostAt(hosts, hostindex, (guests_index) => {
          ParamsMaker.setBarParams(hosts_list, "hosts", "reslog", BAR_GRAPH, initMGCPUParams, this.buildEvent, this.initRequest, guests_index, hostindex);
        });
      });
    });
  }

  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {
      this.passDatas(event, this.dataEventNext);
    });
  }
}
