<div class="page-pac page">
  <mat-sidenav-container class="h2-container">
    <mat-sidenav class="h2-sidenav" mode="over" [(opened)]="opened">
      <!-- <datacenter-vm-panel (onSelect)="selectionDone($event)" [updateSelectorEvent]="updateSelectorEvent.asObservable()" ></datacenter-vm-panel> -->
      <h2-panel (onSelect)="selectionDone($event)" [(onsearch)]="onsearch" (onOpenSearch)="openSearch($event)"
        (onLeaveSearch)="leaveSeach($event)"></h2-panel>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="sub-header">
        <div class="sub-header">
          <h2-head (onGoBack)="toEms()" (onChangeParams)="changeParams()" (onOpenPanel)="openPanel()"> </h2-head>
        </div>
      </div>
      <div class="page-content">
        <div class="page-views">
          <h2-views [idlist]="'views'" [dataEvent]="dataEvent.asObservable()"
            (initRequest)="initRequest('views',$event)" (onParamsUpdated)="paramsUpdated($event)"></h2-views>
        </div>
        <div class="page-tables">
          <h2-tables [idlist]="'tables'" [dataEvent]="dataEvent.asObservable()"
            (initRequest)="initRequest('tables',$event)">
          </h2-tables>
        </div>

        <div class="page-graphs">
          <h2-graphs [idlist]="'lines'" [dataEvent]="dataEvent.asObservable()"
            (initRequest)="initRequest('lines',$event)">
          </h2-graphs>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <!-- <div class="sub-header">
    <h2-head (onGoBack)="toEms()" (onChangeParams)="changeParams()" (onOpenPanel)="openPanel()"> </h2-head>
  </div> -->
  <!-- <div class="page-content">
    
    <div class="page-views">
      <h2-views [idlist]="'views'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('views',$event)" (onParamsUpdated)="paramsUpdated($event)" ></h2-views>
    </div>
    <div class="page-tables">
      <h2-tables [idlist]="'tables'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('tables',$event)"></h2-tables>
    </div>

    <div class="page-graphs">
      <h2-graphs [idlist]="'lines'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('lines',$event)"></h2-graphs>
    </div>
  </div> -->
</div>