import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { LogViewModule }  from 'src/app/gui/page-components/log-view/log-view.module'
import { PageRoutingModule } from './page-routing.module';
import { MainPageComponent } from './main-page.component';
import { DataviewsModule } from 'src/app/dataviews/dataviews.module';

@NgModule({
  declarations: [ MainPageComponent ],
  exports: [ MainPageComponent ],
  imports: [
    CommonModule,
    MaterialLoaderModule,
    LogViewModule,
    DataviewsModule,
    PageRoutingModule
  ]
})
export class MainPageModule { }
