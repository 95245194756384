<div class="host-link-table">
    <table mat-table [dataSource]="dataSource" class="host-link-tab mat-elevation-z8">
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef> {{title}} </th>
            <td mat-cell *matCellDef="let element; let i = index">
                <!-- <button class="host-link-btn" mat-button routerLink="{{makeLink(i)}}" routerLinkActive="active"> {{element.name}} </button> -->
                <button class="host-link-btn" mat-button [routerLink]="makeLink(i)" routerLinkActive="active"> {{element.name}} </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>