import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ConnectService } from '@shared/modules/connect-panel/connect.service';

const EMSElements = {
  0:"ems",
  1:"pv",
  2:"dcdc",
  3:"battery",
  4:"inverter-pv",
  5:"reseau",
  7:"h2",
  10:"datacenter",
  404:"notfound"
}

@Component({
  selector: 'main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  refreshEvent : Subject<boolean> = new Subject<boolean>();
  pageChangeEvent : Subject<boolean> = new Subject<boolean>();
  constructor(private router : Router, private server : ConnectService) { }
  refreshOn:boolean=true;
  ngOnInit() {  }

  onSwitch(){
    this.refreshOn=!this.refreshOn;
    this.refreshEvent.next(this.refreshOn);
  }

  onLogout(){
    this.server.logout().subscribe(()=>{
      this.router.navigate(['/connect']).then(e => {
        if(e){ console.log("Navigation success!"); }
        else{ console.log('Navigation failed :()'); }
      });
    });
  }

  onPageChange(event){
    let page = EMSElements[event.idpage];
    console.log(page);
    this.pageChangeEvent.next(page);
  }

}
