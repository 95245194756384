import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'placeholder-page',
  templateUrl: './placeholder-page.component.html',
  styleUrls: ['./placeholder-page.component.scss']
})
export class PlaceholderPageComponent implements OnInit {
  idpage:number=404;
  constructor(private router :Router) { }

  ngOnInit() {
  }
  toEms(){
    this.router.navigate(['/ems/graph']).then(e => {
        if(e){
          console.log("Navigation success!");
        }
        else{
          console.log('Navigation failed :()');
        }
      });
  }
}
