import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { EMSService } from './ems.service';
import { SelectActionDialogComponent } from './ems-dialog/select-action-dialog/select-action-dialog.component';
import { SwitchConfirmDialogComponent } from './ems-dialog/switch-confirm-dialog/switch-confirm-dialog.component';
const elt_size: number = 20;

@Component({
  selector: 'ems-graph',
  templateUrl: './ems-graph.component.html',
  styleUrls: ['./ems-graph.component.scss']
})
export class EMSGraphComponent implements OnInit {
  idpage: number = 0;
  elementEvent: Subject<{ elt: string, value: any }> = new Subject<{ elt: string, value: any }>();
  emsModeEvent: Subject<any> = new Subject<any>();;

  refresh: any;
  constructor(private router: Router, public ems: EMSService, public dialog: MatDialog) { }

  ngOnInit() {
    this.setRefesh();
  }

  ngOnDestroy() {
    this.stopRefresh();
  }

  checkRelay(datas) {
    if (datas.length == 0) return false;
    return "relay" in datas[0];
  }
  onSwitchClicked(swh: { id: number, name: string }): void {
    this.ems.check_rights(swh.id).subscribe((datas) => {
      if (this.checkRelay(datas)) {
        if (datas[0]["relay"]) {
          const dialogRef = this.dialog.open(SelectActionDialogComponent, {
            autoFocus: false,
            data: swh
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result != null)
              this.getConfirm(result);
          });
        };
      }
    });
  }


  getConfirm(value: { id: number, order: boolean }) {
    const dialogRef = this.dialog.open(SwitchConfirmDialogComponent, {
      autoFocus: false,
      data: value
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != null)
        this.ems.postSwitch(result).subscribe((data) => { });
    })
  }

  postNode(value: number) {
    this.ems.postNode(value).subscribe((data) => {
      console.log("clicked on : ", data);
    });
  }

  setRefesh() {
    this.refresh = setInterval(() => {
      this.getSwitch();
      this.getModeGet();
    }, 1000);
  }
  stopRefresh() {
    clearInterval(this.refresh);
  }

  getSwitch() {
    this.ems.getSwitch().subscribe((data) => {
      this.elementEvent.next({ elt: "switch", value: data["switches"] });
    });
  }

  getModeGet() {
    this.ems.getModeGet().subscribe((data) => {
      this.emsModeEvent.next(data);
    });
  }

  emsModeSelect(event) {
    this.ems.postModeSet(event).subscribe((datas) => { });
  }

  goTo(value: string) {
    this.router.navigate([value]).then(e => {
      if (e) {
        console.log("Navigation success!");
      }
      else {
        console.log('Navigation failed :()');
      }
    });
  }

  onElementClicked(event: { elt: string, value: any }) {
    let elt: string = event.elt;
    let value: any = event.value;
    switch (elt) {
      case "ems": this.postNode(value); break;
      case "switch": this.onSwitchClicked(value); break;
      case "init": this.getSwitch(); break;
      case "nav": this.goTo(value); break;
      default:
        console.log(elt, " not implemented");
        break;
    }
  }
}
