import { Component, OnInit } from '@angular/core';
import { SelectorBase } from '../selector-base';
import { TimeExtList } from '@shared/classes/const-canvas';

@Component({
  selector: 'timeext-selector',
  templateUrl: './timeext-selector.component.html',
  styleUrls: ['./timeext-selector.component.scss']
})
export class TimeextSelectorComponent extends SelectorBase<any> {
  constructor() { super(); }

  ngOnInit() {
    this.list = TimeExtList.tab;
    this.sel = this.list[0];
  }

  getSelected(sel: string): any { return TimeExtList.list[sel] }
}
