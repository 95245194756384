<mat-card-content>
  <form [formGroup]="options">
    <!-- START DATE SELECTION -->
    <div class="start-sel">
      <mat-form-field class="date-selector">
        <input matInput [matDatepicker]="pickerstart" (dateChange)="onDateChange('start_date')" placeholder="Date" formControlName="start_date">
        <!-- <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="pickerstart" (dateChange)="onDateChange('start_date')" placeholder="Date" formControlName="start_date"> -->
        <mat-datepicker-toggle matSuffix [for]="pickerstart"></mat-datepicker-toggle>
        <mat-datepicker   #pickerstart></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="time-selector">
        <mat-label>Heure</mat-label>
        <mat-select formControlName="start_hour">
          <mat-option *ngFor="let hour of hours; let i = index" [value]="i">{{hour}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="time-selector">
        <mat-label>Minutes</mat-label>
        <mat-select formControlName="start_minutes">
          <mat-option *ngFor="let minute of minutes; let i = index" [value]="i">{{minute}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- END DATE SELECTION -->
    <div class="end-sel">
      <!-- <mat-form-field class="date-selector">
        <input matInput [matDatepicker]="pickerend" (dateChange)="onDateChange('end_date')" placeholder="Date" formControlName="end_date">
        <mat-datepicker-toggle matSuffix [for]="pickerend"></mat-datepicker-toggle>
        <mat-datepicker #pickerend></mat-datepicker>
      </mat-form-field> -->

      <mat-form-field class="date-selector">
        <input matInput [matDatepicker]="pickerend" (dateChange)="onDateChange('end_date')" placeholder="Date"
          formControlName="end_date">
        <!-- <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="pickerend" (dateChange)="onDateChange('end_date')" placeholder="Date" formControlName="end_date"> -->
        <mat-datepicker-toggle matSuffix [for]="pickerend"></mat-datepicker-toggle>
        <mat-datepicker #pickerend></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="time-selector">
        <mat-label>Heure</mat-label>
        <mat-select formControlName="end_hour">
          <mat-option *ngFor="let hour of hours; let i = index" [value]="i">{{hour}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="time-selector">
        <mat-label>Minutes</mat-label>
        <mat-select formControlName="end_minutes">
          <mat-option *ngFor="let minute of minutes; let i = index" [value]="i">{{minute}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- START OR CANCEL -->
    <div mat-dialog-actions class="button-part">
      <button mat-button class="btn-cancel" (click)="onCancelClick()">Annuler</button>
      <div class="space"></div>
      <button mat-button class="btn-search" (click)="onSearchClick()">Rechercher</button>
    </div>
  </form>
</mat-card-content>
