import { Component, OnInit } from '@angular/core';
import { SelectorBase } from '../selector-base';
import {GraphConfig,GraphLoaderService} from 'src/app/dataviews/graph-conf/graph-loader.service';
import * as ConstSTD from '@shared/classes/const-canvas';

@Component({
  selector: 'major-selector',
  templateUrl: './major-selector.component.html',
  styleUrls: ['./major-selector.component.scss']
})
export class MajorSelectorComponent extends SelectorBase<any> {
  majorSelection = GraphConfig.select(this.gls).major

  constructor(private gls : GraphLoaderService) { super(); }

  ngOnInit() {
    this.list = this.majorSelection.tab;
    this.sel = this.list[0];
  }

  getSelected(sel:string):any{ return this.majorSelection.list[this.sel]; }

}
