import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'charging-point-head',
  templateUrl: './charging-point-head.component.html',
  styleUrls: ['./charging-point-head.component.scss']
})
export class ChargingPointHeadComponent  implements OnInit {
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenPanel: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {}

  toEms(){
    this.onGoBack.emit();
  }

  openPanel(){
    this.onOpenPanel.emit();
  }
}
