import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { InitParams, DataEvent, CanvasList } from '@shared/classes/request-manager';
import { InverterPVService } from '../../inverter-pv.service';
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';
import * as ParamsMaker from '@shared/classes/parameters-maker';
import * as DevData from '@shared/classes/device-data';
import { BuildParams } from '@shared/modules/canvas-js/types/graph-builder';


function initLineParams(selects, majors, mode) {
  return { "select": selects, "time": 0, "timetype": "short", "numbers": majors, device: 0, mode: mode }
}


const PHOENIX_GRAPH = {
  y_format: {
    power_ac: StyleSTD.NUMFORMAT.std,
    power_dc: StyleSTD.NUMFORMAT.std,
    voltage: StyleSTD.NUMFORMAT.std,
    current: StyleSTD.NUMFORMAT.std
  },
  axis_pos: {
    power_ac: StyleSTD.AXISPOS.pri,
    power_dc: StyleSTD.AXISPOS.pri,
    voltage: StyleSTD.AXISPOS.pri,
    current: StyleSTD.AXISPOS.sec
  },
  y_axis_format: { valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format: { valueFormatString: StyleSTD.NUMFORMAT.std }
}

// ACDC_GRAPH

function initPhoenixParams(mesures, initEvent, device) {
  initEvent.emit({
    phoenixpower: initLineParams([0, 1, 2], ["0"], "phoenix_power"),
    phoenixacin: initLineParams([0, 1], ["0"], "phoenix_acin"),
    phoenixacsync: initLineParams([0, 1], ["0"], "phoenix_acsync"),
    phoenixdc: initLineParams([0, 1], ["0"], "phoenix_dc")
  });
}

@Component({
  selector: 'inverter-pv-graphs',
  templateUrl: './inverter-pv-graphs.component.html',
  styleUrls: ['./inverter-pv-graphs.component.scss']
})
export class InverterPVGraphsComponent extends CanvasList {

  buildEvent: Subject<any> = new Subject<any>();
  dataEventNext: Subject<any> = new Subject<any>();;

  idgraphs = ["phoenixpower", "phoenixdc", "phoenixacin", "phoenixacsync"];

  linebuilds: BuildParams[] = [
    { name: "Puissances", mode: "phoenix", id: 'phoenixpower', type: 'area' },
    { name: "Bus DC", mode: "phoenix", id: 'phoenixdc', type: 'area' },
    { name: "Bus AC", mode: "phoenix", id: 'phoenixacin', type: 'area' },
    { name: "Bus AC Sync", mode: "phoenix", id: 'phoenixacsync', type: 'area' }
  ]
  constructor(private inverterpv: InverterPVService) {
    super();
  }

  ngOnInit(): void {
    this.inverterpv.get_usr_phoenix_device().subscribe((phoenixs) => {
      ParamsMaker.setLineParams(phoenixs, "phoenixs", "phoenix", PHOENIX_GRAPH, initPhoenixParams, this.buildEvent, this.initRequest, 0);
    });
  }

  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {
      this.passDatas(event, this.dataEventNext);
    });
  }
}
