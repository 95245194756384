import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'pv-config-dialog',
  templateUrl: './pv-config-dialog.component.html',
  styleUrls: ['./pv-config-dialog.component.scss']
})
export class PVConfigDialogComponent implements OnInit {

  options : FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PVConfigDialogComponent>,
    public fb : FormBuilder
    , @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  this.options = fb.group({
    warn_temp : this.data.warn_temp,
    alert_temp : this.data.alert_temp,
    module_name : this.data.module_name,
    cable_section : this.data.cable_section
  });
}

  ngOnInit() {}
  onApply(){
    let vals= this.options.value;
    let tmp = {
      uid:this.data.uid,
      warn_temp : vals.warn_temp,
      alert_temp : vals.alert_temp,
      module_name : vals.module_name,
      cable_section : vals.cable_section
    };
    this.dialogRef.close(tmp);
  }

  onCancel(){
    this.dialogRef.close(null);
  }
}
