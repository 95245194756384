<mat-card class="ely-table">
    <div class="title-card"><span>Electrolyseur</span></div>
    <table mat-table [dataSource]="dataSource" class="h2-table">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th class="elt-{{column}}" mat-header-cell *matHeaderCellDef> {{colNames[column]}} </th>
        <td class="elt-{{column}}" mat-cell *matCellDef="let element"> {{element[column]}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-card>
