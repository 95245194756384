import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
// import {moment} from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'search-pop-up',
  templateUrl: './search-pop-up.component.html',
  styleUrls: ['./search-pop-up.component.scss']
})
export class SearchPopUpComponent {
    hours : string[] = makeTwoDigits(24);
    minutes : string[] = makeTwoDigits(60);
    options : FormGroup;

    constructor(
      public dialogRef: MatDialogRef<SearchPopUpComponent>,
      public fb : FormBuilder
    ) {
    this.options = fb.group({
      start_date: moment().utc().startOf('day'),
      start_hour : 0,
      start_minutes : 0,
      end_date: moment().utc().startOf('day'),
      end_hour : 0,
      end_minutes : 0
    });
  }


    onDateChange(dpiker:string){
      // this.options.value[dpiker]=this.options.value[dpiker].toDate();
    }

    onCancelClick(): void {
      this.dialogRef.close(null);
    }

    onSearchClick(): void {
      console.log(this.options.value);
      let datas = {}
      let p = this.options.value;
      let start_date = p.start_date.toDate();
      let end_date = p.end_date.toDate();
      let sdate = new Date(start_date.getFullYear(),start_date.getMonth(),start_date.getDate(),p.start_hour,p.start_minutes,0).getTime();
      let edate = new Date(end_date.getFullYear(),end_date.getMonth(),end_date.getDate(),p.end_hour,p.end_minutes,0).getTime();
      let interv = Math.round((edate - sdate)/(1000*200));
      datas["time"] = { start:sdate, end:edate }
      datas["grp"] = {unit:"s", value:interv}
      datas["fields"] = null;
      datas["numbers"] = null;
      this.dialogRef.close(datas);
    }
    numbers(size:number):number[]{
      let nbs:number[]=[];
      for(let i = 0; i < size;i++)nbs.push(i);
      return nbs;
    }
}

function makeTwoDigits(size:number):string[]{
  let tab : string[] = [];
  for(let i = 0; i < 10; i++)
    tab.push("0"+i);
  for(let i = 10; i < size; i++)
    tab.push(""+i);
  return tab;
}
