import { Component, OnInit, Input } from '@angular/core';
import {  Observable, Subject, of  } from 'rxjs';


@Component({
  selector: 'datacenter-overview-panel',
  templateUrl: './datacenter-overview-panel.component.html',
  styleUrls: ['./datacenter-overview-panel.component.scss']
})
export class DatacenterOverviewPanelComponent implements OnInit {
  hostlink:string="";
  guestslinks:string[]=[];
  host:any={name:"",index:0};
  guests:any[]=[];
  @Input() hostUpdate : Observable<any>;
  linkEvent : Subject<any> = new Subject<any>()
  constructor() { }

  hostTabToParams(hosts, hostindex) {
    let selpar = []
    hosts.forEach((host) => {
      selpar.push({ name: host.name, params: { indexvm: host.index, hostindex: hostindex } })
    });
    return selpar;
  }

  hostTabToHostList(hosts) {
    let selpar = []
    for(let i = 0; i < hosts.length; i++){
      selpar.push({ name: hosts[i].name, params: { indexvm: hosts[i].index, hostindex: i } })

    }
    return selpar;
  }


  ngOnInit(): void {
    this.hostUpdate.subscribe((datas)=>{
      var hosts = datas.hosts;
      var guests = datas.guests;
      var hostindex = datas.hostindex;
      console.log(hosts);
      this.linkEvent.next({ sel: "over-energy-links", value: this.hostTabToParams([{ name: "Energie", index: 0 }], hostindex)});
      this.linkEvent.next({ sel: "over-host-list-links", value: this.hostTabToParams(hosts, hostindex)});
      this.linkEvent.next({ sel: "over-host-vm-links", value: this.hostTabToHostList(hosts)});
      this.linkEvent.next({ sel: "over-guest-vm-links", value: this.hostTabToParams(guests, hostindex)});
    });
  }

  ngAfterViewInit(): void { }

}
