import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkTableComponent } from './link-table.component';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [LinkTableComponent],
  exports: [LinkTableComponent],
  imports: [
    CommonModule,
    MaterialLoaderModule,
    RouterModule
  ]
})
export class LinkTableModule { }
