/**================== BASE ANGULAR IMPORT =====================**/


import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
/**===================== MATERIAL IMPORT =======================**/
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
/**======================= PAGE IMPORT ========================**/
import { MainPageModule } from 'src/app/gui/main-page/main-page.module';
import { ConnectPanelModule } from '@shared/modules/connect-panel/connect-panel.module';
import { PartPageModule }from 'src/app/gui/sub-page/part-page/part-page.module';
import { EMSGraphModule } from 'src/app/gui/sub-page/ems-graph/ems-graph.module';
import { DialogModule } from '@shared/modules/dialog/dialog.module';
/**======================= PAGE IMPORT ========================**/
// import { SearchPopUpComponent } from 'src/app/gui/page-components/dialog/search-pop-up/search-pop-up.component'

/**======================= CONFIG IMPORT ========================**/
import { ConfigModule } from '@shared/modules/config/config.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialLoaderModule,
    AppRoutingModule,
    PartPageModule,
    MainPageModule,
    EMSGraphModule,
    ConfigModule,
    DialogModule,
    ConnectPanelModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
