import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MatTableDataSource, MatTable } from '@angular/material/table';

interface PACData {
  pow: number,
  tem: number,
  pre: number
}


@Component({
  selector: 'pac-table',
  templateUrl: './pac-table.component.html',
  styleUrls: ['./pac-table.component.scss']
})
export class PACTableComponent implements OnInit {
  @Input() dataEventNext :Observable<any>;
  @Input() idval : string;
  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns : string[]=["pow", "tem", "pre"];
  colNames={
    "pow" : "Production",
    "tem" : "Température",
    "pre" : "Pression"
  }

  dataSource : MatTableDataSource<PACData> = new MatTableDataSource([{"pow" :0, "tem" : 0, "pre" : 0}]);
  constructor() { }
  ngOnInit() { }

  updateTable(pac_data:PACData): void{
     this.dataSource.data = [pac_data];
     this.table.renderRows();
   }

  ngAfterViewInit() {
    this.dataEventNext.subscribe((data)=>{
      if(this.idval==data.graph){
        var datas = data.datas;
        if(datas.length > 0)
        if(datas[0].length > 0){
          let elt  = datas[0][0];
          this.updateTable({
            pow:elt['app_powerpac'],
            pre:elt['pressurepac'],
            tem:elt['tempac']
          });
        }
      }
    });
  }

}
