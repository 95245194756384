import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';
import { RouterModule } from '@angular/router';
import { LinkTableModule } from '../link-table/link-table.module';
import { HostLinkTableModule } from '../host-link-table/host-link-table.module';

import { WebsitesHeadComponent } from './websites-head/websites-head.component';
import { WebsitesPanelComponent } from './websites-panel/websites-panel.component';
import { WebsitesPageComponent } from './websites-page.component';
import { WebsitesGraphsComponent } from './websites-graphs/websites-graphs.component';



@NgModule({
  declarations: [WebsitesPageComponent, WebsitesHeadComponent, WebsitesPanelComponent, WebsitesGraphsComponent],
  exports: [WebsitesPanelComponent ],
  imports: [
    CommonModule,
    MaterialLoaderModule,
    CanvasJsModule,
    SelectorsModule,
    RouterModule,
    LinkTableModule,
    HostLinkTableModule

  ]
})
export class WebsitesModule { }
