<div class="charging-point-panel">
    <mat-card class="panel-title">
        <div class="title-div"> Onduleur PV </div>
    </mat-card>

    <mat-card class="panel-select-card">
        <div class="panel-sel-title">
            <div class="title-div">
                Paramètres
            </div>
        </div>
        <div class="panel-select-div">
            <div class="selector-div">
                <div class="select-header">
                  <div>
                    Période
                </div>
              </div>
                <div class="selector-div">
                    <longtime-selector class="selector" (selected)="onSelectionDone('time',$event)">
                    </longtime-selector>
                </div>
            </div>
        </div>
    </mat-card>
</div>
