import { Component } from '@angular/core';
import { CanvasList } from '@shared/classes/request-manager';
import { H2Service } from "../h2.service";
import {  Subject } from 'rxjs';

import * as ParamsMaker from '@shared/classes/parameters-maker';

function initH2Params(mesures, initEvent,device){
  initEvent.emit({
    soctab:initNoGroupParams(["0","1"],["0"],"greenbox_out"),
    pactab:initNoGroupParams(["0","1","2"],["0"],"greenbox_pac"),
    elytab:initNoGroupParams(["0","1","2"],["0"],"greenbox_ely"),
  });
}
function initNoGroupParams(selects,majors,mode){
  return {"select":selects,"time":0,"timetype":"nogroup","numbers":majors,"device":0,mode:mode}
}

@Component({
  selector: 'h2-tables',
  templateUrl: './h2-tables.component.html',
  styleUrls: ['./h2-tables.component.scss']
})

export class H2TablesComponent extends CanvasList {
  buildEvent : Subject<any> = new Subject<any>();
  dataEventNext : Subject<any> = new Subject<any>();
  idsoc="soctab";
  idpac="pactab";
  idely="elytab";

  constructor( private h2 : H2Service) {
    super();
  }

  ngOnInit() {
    this.h2.get_usr_greenbox_device().subscribe((greenboxs)=>{
      ParamsMaker.setTabParams(greenboxs, "greenboxs", "greenbox", initH2Params, this.initRequest, 0);
    });
  }

  ngAfterViewInit(){
    this.dataEvent.subscribe((event)=>{
      this.passDatas(event,this.dataEventNext);
    });
  }

}
