<div class="dcdc-head">
  <div class="menu-div">
    <button mat-button class="menu-btn" (click)="openPanel()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="title-card">
    <span>PV & MPPT</span>
  </div>
  <div class="go-back-div">
    <button class="go-back-btn" mat-button (click)='toEms()'>
      <img class="btn-ico" src="assets/img/ico/goback.png" alt="Vue Générale">
    </button>
  </div>
</div>
