import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

interface Fakes {
  pow:number,
  volt:number,
  cur:number,
  temp_p:number,
  lum:number,
  temp_a:number
}
const fake_datas : Fakes[] = [{
  pow:40,
  volt:300,
  cur:60,
  temp_p:53,
  lum:1202,
  temp_a:41
}];


@Component({
  selector: 'resume-table',
  templateUrl: './resume-table.component.html',
  styleUrls: ['./resume-table.component.scss']
})
export class ResumeTableComponent implements OnInit {
  displayedColumns : string[]=["pow","volt","cur","temp_p","temp_a"];
  colNames={
    "pow" : "Production",
    "volt" :"Tension",
    "cur" :"Courant",
    "temp_p" :"Température Panneaux",
    "temp_a":"Température Ambiante"
  }
  dataSource : MatTableDataSource<Fakes> = new MatTableDataSource(fake_datas);
  constructor() { }

  ngOnInit() {
  }

}
