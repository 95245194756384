import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MmaTableComponent } from './mma-table.component'


@NgModule({
  declarations: [MmaTableComponent],
  exports: [MmaTableComponent],
  imports: [
    CommonModule
  ]
})
export class MmaTableModule { }
