import { Component, OnInit, Input } from '@angular/core';
import {  Observable, Subject, of  } from 'rxjs';
import { SelectorBase, SelectorDefinition, SelectorEntry } from '../selector-base';

@Component({
  selector: 'server-selector',
  templateUrl: './server-selector.component.html',
  styleUrls: ['./server-selector.component.scss']
})
export class ServerSelectorComponent extends SelectorBase<any> {
  @Input() sel_name:number;
  @Input() updateSelectorEventNext : Observable<any>;

  sel_def : SelectorDefinition<number>;

  constructor() { super(); }

  ngOnInit(): void {
    this.updateSelectorEventNext.subscribe((datas)=>{
      if(this.sel_name == datas.sel)
        this.sel_def = new SelectorDefinition<number>(datas.val);
        this.list = this.sel_def.tab;
        this.sel = this.list[0];
    });
  }
  getSelected(sel:string):any{ return this.sel_def.list[sel]; }
}
