<div class="inverter-pv-acsync">
  <div class="title-div">
    <div class="txt-div">Bus AC Sync</div>
  </div>
  <div class="acsync-field-list">

    <div class="acsync-field-div" *ngFor="let field of fieldnames">
      <div class="field-title">
        <div class="fieldtxt"> {{fields[field].title}} </div>
      </div>
      <div class="field-value">
        <div class="valuetxt">
          {{fields[field].value}}
        </div>
      </div>
    </div>
  </div>
</div>
