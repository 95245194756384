import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';

interface InvFieldList {
  [name: string]: {
    title: string;
    value: string;
    unit: string;
  }
}

function initLineParams(selects, majors, mode) {
  return { "select": selects, "time": 0, "timetype": "short", "numbers": majors, device: 0, mode: mode }
}

function initBatteryParams(initEvent) {
  initEvent.emit({
    lastvalues: initLineParams([0, 1, 2, 3, 4, 5, 6], ["0"], "batterylast_values"),
  });
}

@Component({
  selector: 'battery-list',
  templateUrl: './battery-list.component.html',
  styleUrls: ['./battery-list.component.scss']
})
export class BatteryListComponent implements OnInit {
  @Output() initRequest: EventEmitter<any> = new EventEmitter<any>();
  @Input() dataEvent: Observable<any>;
  @Input() idlist:string;
  fields: InvFieldList = {
    "voltage0": {
      title: "Tension",
      value: "-1",
      unit: "V"
    },
    "current_mes0": {
      title: "Courant Mesuré",
      value: "-1",
      unit: "A"
    },
    "current_calc0": {
      title: "Courant Calculé",
      value: "-1",
      unit: "A"
    },
    "power0": {
      title: "Puissance",
      value: "-1",
      unit: "W"
    },
    "soc0": {
      title: "Etat de Charge",
      value: "-1",
      unit: "%"
    },
    "nbrcycle0": {
      title: "Nombre de Cycle",
      value: "-1",
      unit: ""
    },

    "capacitance0": {
      title: "Capacité",
      value: "-1",
      unit: "Ah"
    }
  };

  fieldnames:string[] = Object.keys(this.fields);
  enrfieldsname = this.fieldnames.slice(0,4);
  batfieldsname = this.fieldnames.slice(4,this.fieldnames.length);

  constructor() { }

  ngOnInit(): void { initBatteryParams(this.initRequest); }

  setFieldValue(value: any) {
    this.fieldnames.forEach((key) => {
      if (key in value) {
        this.fields[key].value = "" + (Math.round((value[key] + Number.EPSILON) * 100) / 100) + "" + this.fields[key].unit;
      }
    });
  }


  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {

      if (event.orig == "batlist") {
        console.log(event.orig, " ", event.datas);
        let datas = event.datas;
        let value = getDatas(datas);
        if(value != null){
          this.setFieldValue(value);
        }
      }
    });
  }
}


function getDatas(datas:any[][]):any{
  if (datas == null)return null;
  if (datas.length  < 1) return null;
  if (datas[0] == null) return null;
  if (datas[0].length < 1) return null;
  if (datas[0][0] == null) return null;  
  return datas[0][0];
}
