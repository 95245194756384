import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';

import { LogViewComponent } from './log-view.component';


@NgModule({
  declarations: [ LogViewComponent ],
  exports : [ LogViewComponent ],
  imports: [
    CommonModule,
    MaterialLoaderModule
  ]
})
export class LogViewModule { }
