import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisGraphDirective } from './vis-graph.directive'


@NgModule({
  declarations: [ VisGraphDirective ],
  exports: [ VisGraphDirective ],
  imports: [
    CommonModule
  ]
})
export class VisGraphModule { }
