<div mat-dialog-content class="dialog">
  <h2 mat-dialog-title class="title">Interupteur :</h2>
  <div mat-dialog-content class="view-panel">
    <form [formGroup]="options" class="form-options">
      <div class="param-div">
        <mat-form-field class="param-field">
          <input class="param-input" matInput placeholder="Nom Module" formControlName="module_name">
        </mat-form-field>
        <mat-form-field class="param-field">
          <input class="param-input" matInput placeholder="Section Cable" formControlName="cable_section">
        </mat-form-field>
      </div>
      <div class="param-div">
        <mat-form-field class="param-field">
          <input class="param-input" matInput placeholder="Température Warning" formControlName="warn_temp">
        </mat-form-field>
        <mat-form-field class="param-field">
          <input class="param-input" matInput placeholder="Température Séc. Inc." formControlName="alert_temp">
        </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="button-part">
    <button mat-button class="btn-yes" (click)="onCancel()">Annuler</button>
    <div class="space"></div>
    <button mat-button class="btn-no" (click)="onApply()">Appliquer</button>
  </div>
</div>
