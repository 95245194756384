import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RequestPage, buildSearchParams } from '@shared/classes/request-manager';
import { DatacenterEnergyService } from './datacenter-energy.service';
import { SearchPopUpComponent } from '@shared/modules/dialog/search-pop-up/search-pop-up.component'
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'datacenter-energy-page',
  templateUrl: './datacenter-energy-page.component.html',
  styleUrls: ['./datacenter-energy-page.component.scss']
})
export class DatacenterEnergyPageComponent extends RequestPage  {
  hostUpdate : Subject<any> = new Subject<any>();
  opened:boolean=false;
  onsearch = false;

  constructor(private router: Router, protected datacenter_energy: DatacenterEnergyService, private dialog :MatDialog) {
    super(datacenter_energy);
  }

  ngOnInit() { }

  leaveSeach(event) {
    this.onsearch = false;
    this.opened = false;
    this.manager.requestPoints("main", (req, requestlist, key, key2) => { return true; });
    this.setRefresh();
  }

  openSearch(event) {
    const dialogRef = this.dialog.open(SearchPopUpComponent, {
      autoFocus: true
    });
    this.stopRefresh();
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.onsearch = true;
        this.opened = false;
        console.log(result);
        var tmppar = buildSearchParams(null, result.time, result.group, null);
        this.manager.selectSearch(tmppar, (req, requestlist, orig, graphid) => {
          return true;
        });
        this.manager.requestPoints("search", (req, requestlist, key, key2) => { return true; });
      }
      else {
        console.log("cancel");
      }
    });
  }


  selectionDone(event:{sel:string,value:any}){
    console.log(event);
    this.opened=false;
    switch(event.sel){
      case 'time' :
        this.manager.selectTime(event.value,(req, requestlist, orig, graphid)=>{ return true; });
        this.manager.requestPoints("main",(req, requestlist, key, key2)=>{return true;})
        break;
      case 'chanel' :
        this.manager.selectFields(event.value,(req, requestlist, orig, graphid)=>{
          return req.mode=="conso";
        });
        this.manager.requestPoints("main",(req, requestlist, key, key2)=>{return req.mode=="conso";})
        break;
      default:break;
    }
    this.setRefresh();
  }

  onInitRequest(event:{sel:string,value:any}){
    console.log("on init req");
    this.initRequest(event.sel, event.value);
  }

  onGetHost(event){
    this.hostUpdate.next(event);
  }

  toEms(){
    this.router.navigate(['/ems/graph']).then(e => {
      if(e){ console.log("Navigation success!"); }
      else{ console.log('Navigation failed :()'); }
    });
  }
  openPanel(){
    this.opened=true;
  }
  ngOnDestroy(){ this.stopRefresh(); }
  ngAfterViewInit(){
    this.setRefresh();
  }

}
