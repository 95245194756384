<div class="charging-point page">
  <mat-sidenav-container class="charging-point-container">
    <mat-sidenav class="charging-point-sidenav" mode="over" [(opened)]="opened">
      <charging-point-panel (onSearchEvent)="onSearch($event)" (onSelect)="selectionDone($event)" [searchEvent]="searchEvent.asObservable()">
      </charging-point-panel>
    </mat-sidenav>
    <mat-sidenav-content class="charging-point-content">
      <div class="sub-header">
        <charging-point-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></charging-point-head>
      </div>
      <div class="page-content">
        <charging-point-graphs [idlist]="'lines'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('lines',$event)">
        </charging-point-graphs>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
