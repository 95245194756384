import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { CanvasList } from '@shared/classes/request-manager';
import { ReseauService } from '../reseau.service'
import * as StyleSTD from '@shared/modules/canvas-js/style/style-std';

import { BuildParams } from '@shared/modules/canvas-js/types/graph-builder';
import * as ParamsMaker from '@shared/classes/parameters-maker';

function initLineParams(selects,majors,mode){
  return {"select":selects,"time":0,"timetype":"longtime","numbers":majors,device:0,mode:mode}
}
function initLincLineParams(selects, majors, mode) {
  return { "select": selects, "time": 0, "timetype": "short", "numbers": majors, device: 0, mode: mode }
}

const LINC_GRAPH = {
  y_format: {
    app_power: StyleSTD.NUMFORMAT.std,
    current: StyleSTD.NUMFORMAT.std,
    voltage: StyleSTD.NUMFORMAT.std
  },
  axis_pos: {
    app_power: StyleSTD.AXISPOS.pri,
    current: StyleSTD.AXISPOS.pri,
    voltage: StyleSTD.AXISPOS.sec
  },
  y_axis_format: { valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format: { valueFormatString: StyleSTD.NUMFORMAT.std }
}

function initLincsParams(mesures, initEvent, device) {
  initEvent.emit({
    apppower: initLincLineParams(["4"], ["0"], "app_power"),
    curvolt: initLincLineParams(["4"], ["0","1"], "curvolt")
  });
}


const ENER_GRAPH = {
  y_format:{
    power:StyleSTD.NUMFORMAT.std
  },
  axis_pos:{
    power:StyleSTD.AXISPOS.pri
  },
  y_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std },
  y2_axis_format:{ valueFormatString: StyleSTD.NUMFORMAT.std }
}

function initEnrdevParams(mesures, initEvent,device){
  initEvent.emit({
    enrpower:initLineParams(["0"], ["0"], "enrpower")
  });
}

@Component({
  selector: 'reseau-graph',
  templateUrl: './reseau-graph.component.html',
  styleUrls: ['./reseau-graph.component.scss']
})
export class ReseauGraphComponent extends CanvasList {
  buildEvent : Subject<any> = new Subject<any>();
  dataEventNext : Subject<any> = new Subject<any>();;
  idgraphs = ["enrpower", "apppower", "curvolt"];
  linebuilds : BuildParams[] = [
    {name:"Puissance Réseau", mode:"enrpower", id:'enrpower', type:'area'},
    { name: "Puissance Apparente", mode: "conso", id: 'apppower', type: 'area' },
    { name: "Courant & Tension", mode: "conso", id: 'curvolt', type: 'area' },
  ]

  constructor( private reseau : ReseauService) {
    super();
  }
  ngOnInit(): void {
    this.reseau.get_usr_enrdev_device().subscribe((enrdevs)=>{
      ParamsMaker.setLineParams(enrdevs, "enrdevs", "enrpower", ENER_GRAPH, initEnrdevParams, this.buildEvent, this.initRequest, 0);
    });
    this.reseau.get_usr_linc_device().subscribe((psqllincs) => {
      console.log(psqllincs);
      ParamsMaker.setLineParams(psqllincs, "psqllincs", "conso", LINC_GRAPH, initLincsParams, this.buildEvent, this.initRequest, 0);
    });
  }

  ngAfterViewInit(){
    this.dataEvent.subscribe((event)=>{
      this.passDatas(event,this.dataEventNext)
    });
  }
}
