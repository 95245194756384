import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module';
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';
import { RouterModule } from '@angular/router';
import { LinkTableModule } from '../link-table/link-table.module';
import { HostLinkTableModule } from '../host-link-table/host-link-table.module';

import { DatacenterEnergyPageComponent } from './datacenter-energy-page.component';
import { DatacenterEnergyHeadComponent } from './datacenter-energy-head/datacenter-energy-head.component';
import { DatacenterEnergyPanelComponent } from './datacenter-energy-panel/datacenter-energy-panel.component';
import { DatacenterEnergyGraphsComponent } from './datacenter-energy-graphs/datacenter-energy-graphs.component';

@NgModule({
  declarations: [DatacenterEnergyPageComponent, DatacenterEnergyHeadComponent, DatacenterEnergyPanelComponent, DatacenterEnergyGraphsComponent],
  exports : [ DatacenterEnergyPageComponent ],
  imports: [
    CommonModule,
    MaterialLoaderModule,
    CanvasJsModule,
    SelectorsModule,
    RouterModule,
    LinkTableModule,
    HostLinkTableModule
  ]
})
export class DatacenterEnergyModule { }
