<div class="datacenter-energy page">
  <mat-sidenav-container class="datacenter-energy-container" >
    <mat-sidenav class="datacenter-energy-sidenav" mode="over" [(opened)]="opened">
      <datacenter-energy-panel (onSelect)=selectionDone($event)  [hostUpdate]="hostUpdate.asObservable()" [(onsearch)]="onsearch" (onOpenSearch)="openSearch($event)" (onLeaveSearch)="leaveSeach($event)"></datacenter-energy-panel>
    </mat-sidenav>
    <mat-sidenav-content class="datacenter-energy-content">
      <div class="sub-header">
        <datacenter-energy-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></datacenter-energy-head>
      </div>
      <div class="page-content">
        <datacenter-energy-graphs [idlist]="'energylines'" [dataEvent]="dataEvent.asObservable()"  (initRequest)="initRequest('energylines',$event)"  (serv_devs)="onGetHost($event)"></datacenter-energy-graphs>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
