import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MainPageComponent } from './main-page.component';
import { EMSGraphComponent } from 'src/app/gui/sub-page/ems-graph/ems-graph.component';
import { PlaceholderPageComponent } from '@part-page/placeholder-page/placeholder-page.component';
import { ReseauPageComponent } from '@part-page/reseau-page/reseau-page.component';
import { PVPageComponent } from '@part-page/pv-page/pv-page.component';
import { H2PageComponent } from '@part-page/h2-page/h2-page.component';
import { BatteryPageComponent } from '@part-page/battery-page/battery-page.component';
import { InverterPVPageComponent } from '@part-page/inverter-pv-page/inverter-pv-page.component';
import { DCDCPageComponent } from '@part-page/dcdc-page/dcdc-page.component';
import { DatacenterRoutingModule } from '@part-page/datacenter-page/datacenter-routing.module';
import { RouterPageComponent } from '@part-page/router-page/router-page.component';
import { ChargingPointPageComponent } from '@utility-page/charging-point-page/charging-point-page.component';
import { ElecPlugsPageComponent } from '@utility-page/elec-plugs-page/elec-plugs-page.component';


const ems_routes = [
    {path : 'ems', component:MainPageComponent,children:[
      { path: '', redirectTo: '/ems/graph', pathMatch: 'full' },
      { path: 'graph', component: EMSGraphComponent },
      { path: 'pv', component: PVPageComponent },
      { path: 'dcdc', component: DCDCPageComponent },
      { path: 'batterie', component: BatteryPageComponent },
      { path: 'onduleurpv', component: InverterPVPageComponent },
      { path: 'reseau', component: ReseauPageComponent },
      { path: 'transfoiso', component: PlaceholderPageComponent },
      { path: 'pac', component: H2PageComponent },
      { path: 'electrolyseur', component: H2PageComponent },
      { path: 'onduleur', component: H2PageComponent },
      DatacenterRoutingModule.datacenter_routes(),
      { path: 'borne', component: ChargingPointPageComponent },
      { path: 'prisescierie', component: ElecPlugsPageComponent },
      { path: 'eclairage', component: PlaceholderPageComponent },
      { path: 'refroidissement', component: PlaceholderPageComponent },
      { path: 'utilite', component: PlaceholderPageComponent },
      { path: 'router', component: RouterPageComponent }
    ]},
  ]


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ems_routes)
  ],
  exports:[RouterModule]
})
export class PageRoutingModule { }
