<mat-card class="canvasjsComp" >
  <div *ngIf="graphIsNull(); then thenBlock; else elseBlock"></div>
  <ng-template #thenBlock>
    <mat-card-header class='title' [ngStyle]="{color:'rgb(98, 99, 106)'}">Pas de Graph</mat-card-header>
  </ng-template>
  <ng-template #elseBlock>
    <mat-card-header class='title' [ngStyle]="{color:'rgb(98, 99, 106)'}">{{graph.name}}</mat-card-header>
  </ng-template>
  <mat-card  class="graph" align="center" id="{{idval}}"></mat-card>
</mat-card>
