export const FONTFAMILY : string = '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif';

export const TITLE = {text:""};
export const TIPLINE = "<b>({x}){red}</b> : {y}{unit}";
export const LEGENDTXT = "{comp} (#percent%)";
export const SENSTIPDOUGHNUT = "{comp} (#percent%)";
export const STDAXISSEL = 'primary';

export enum COLORS {
  graylight = 'rgb(217,217,217)',
  gray = 'rgb(140,140,140)',
  graydark = 'rgb(98, 99, 106)',
  cyan = 'rbg(102, 255, 255)'
}

export enum NUMFORMAT {
  sci = "0.0#E+0",
  kilo = '#,##0,.#0k',
  giga = '#,##0,.#0G',
  std = "#0.#0",
  dec = "#.00"
}

export enum DATEFORMAT {
  date = "DD/MM",
  fullhour = "HH'h'mm",
  day = "DDD H'h'"
}

export enum FNTSIZE{
  small = 10,
  normal = 12
}

export enum AXISPOS {
  pri="primary",
  sec="secondary"
}

export const STDYAXIS = { valueFormatString: NUMFORMAT.std }
export const STDYAXISLIST = {y:STDYAXIS, y2: STDYAXIS}
export const YDOUGHNUTFORMAT = "";
export const TIPDOUGHNUT = "{red} {y}{unit}";

export function STDAXIS(axis:string) {
  let isX = axis == 'X';
  let base =  {
    LabelFontFamily: FONTFAMILY,
    labelFontColor: COLORS.gray,
    labelFontSize :12,
    lineThickness: 1,
    gridThickness:1,
    tickThickness: 1,
    gridColor:COLORS.graylight,
    lineColor:COLORS.gray,
    tickColor:COLORS.gray,
    valueFormatString: isX ? DATEFORMAT.fullhour : NUMFORMAT.sci
  }
  if(isX)
    base["labelAngle"]=-30;
  return base;
}

export function STDCHART(){
  return {
    animationEnabled:true,
    exportEnabled:false,
    title:TITLE,
    legend:STDLEGEND("bottom"),
    data:[]
  }
}

export function STDLINECHART() {
  return {
    animationEnabled:true,
    exportEnabled:false,
    legend:STDLEGEND("bottom"),
    axisX:STDAXIS('X'),
    axisY:STDAXIS('Y'),
    axisY2:STDAXIS('Y'),
    zoomEnabled:true,
    data:[]
   }
}

export function STDLINECANVAS(id:string="",comp:string="",red:string="",unit:string="",type:string="area", color:string=COLORS.cyan) {
  return {
    type: type,
    id:id,
    unit:unit,
    name:comp,
    comp:comp,
    red:red,
    showInLegend:true,
    markerSize:0,
    fillOpacity:.1,
    lineThickness:1,
    toolTipFontFamily:FONTFAMILY,
    yValueFormatString:NUMFORMAT.std,
    lineColor:color,
    color:color,
    axisYType:STDAXISSEL,
    dataPoints:[]
  }
}

export function DOUGHNUTSTD(key,mode){
  return {
    type:'doughnut',
    id:key,
    showInLegend:true,
    radius:'95%',
    innerRadius:'75%',
    toolTipContent:TIPDOUGHNUT,
    toolTipFontFamily:FONTFAMILY,
    yValueFormatString:YDOUGHNUTFORMAT,
    legendText: LEGENDTXT,
    dataPoints:[]
  }
}

export function DOUGHNUTFORMAT(key,tip_format,y_format){
  return {
    type:'doughnut',
    id:key,
    showInLegend:true,
    radius:'95%',
    innerRadius:'75%',
    toolTipContent:tip_format,
    toolTipFontFamily:FONTFAMILY,
    yValueFormatString:y_format,
    legendText: LEGENDTXT,
    dataPoints:[]
  }
}
export function ShowDate(e,cb) {
    let data = e.entries[0].dataSeries;
    //CanvasJS.formatDate
    return  "<b> ("+cb(data.x,DATEFORMAT.date)+
    +")"+data.red+" </b > : "+
    data.y+""+data.unit;
}

export function LINEAREASTD(key:string,mode:string,mes:string,color:string){
  return {
    yValueFormatString: NUMFORMAT.std,
    lineColor:color,
    axisYType: STDAXISSEL,
    id:key
  }
}

export function LINEAREAFORMAT(key:string,y_format:string,axis_pos:string,color:string){
  return {
    yValueFormatString: y_format,
    lineColor:color,
    axisYType:axis_pos,
    id:key
  }
}

export function STDLEGEND(vertaling:string){
  return {
    fontFamily: FONTFAMILY,
    fontSize: 12,
    horizontalAlign: "left",
    verticalAlign: vertaling,
    fontColor: COLORS.graydark
  }
}

  export function STDBARFORMAT(id:string="",type:string="bar", axisy_pos:string=AXISPOS.pri, toolTipContent:string="{label}:{y}",y_format:string=NUMFORMAT.std, index_label:string=""){
    return {
      id:id,
      type: type,
      axisYType: axisy_pos,
      toolTipContent: toolTipContent,
      indexLabel: index_label,
      valueFormatString:y_format,
      yValueFormatString:y_format,
      dataPoints:[]
    }
  }


  export function STDBARAXIS(axis:string, type:string,y_format:string=NUMFORMAT.sci) {
    let isX = axis == 'X';
    let base =  {
      LabelFontFamily: FONTFAMILY,
      labelFontColor: COLORS.gray,
      labelFontSize :12,
      lineThickness: 1,
      gridThickness:1,
      tickThickness: 1,
      gridColor:COLORS.graylight,
      lineColor:COLORS.gray,
      tickColor:COLORS.gray,
      yValueFormatString: y_format
    }
    if(isX && type != "bar")
      base["labelAngle"]=-30;
    if(!isX && type == "bar")
      base["labelAngle"]=-30;
    return base;
  }

  export function STDBARCHART(type:string="",y_format:string=null) {
    if (y_format==null){
      return {
        animationEnabled:true,
        exportEnabled:false,
        legend:STDLEGEND("bottom"),
        axisX:STDBARAXIS('X',type),
        axisY:STDBARAXIS('Y',type),
        axisY2:STDBARAXIS('Y',type),
        zoomEnabled:true,
        data:[]
      }
    }
    return {
      animationEnabled:true,
      exportEnabled:false,
      legend:STDLEGEND("bottom"),
      axisX:STDBARAXIS('X',type,y_format),
      axisY:STDBARAXIS('Y',type,y_format),
      axisY2:STDBARAXIS('Y',type,y_format),
      zoomEnabled:true,
      data:[]
    }
  }


export const TIMEFORMATLIST = {
  "h":DATEFORMAT.fullhour,
  "d":DATEFORMAT.fullhour,
  "w":DATEFORMAT.day,
  "M":DATEFORMAT.day,
  "y":DATEFORMAT.day
}
