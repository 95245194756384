import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';

interface LinkElt {
  name: string,
  params: { [name: string]: any }
}
@Component({
  selector: 'host-link-table',
  templateUrl: './host-link-table.component.html',
  styleUrls: ['./host-link-table.component.scss']
})
export class HostLinkTableComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any>;
  @Input() linkEvent: Observable<{ sel: string, value: any }>;
  @Input() setTitleEvent: Subject<string>;
  @Input() title: string;
  @Input() idlinks: string;
  @Input() linkbase: string;
  public dataSource: MatTableDataSource<LinkElt> = new MatTableDataSource([]);
  public displayedColumns: string[] = ["name"];
  public colNames = {
    "name": ""
  }
  linktargets: any[] = []
  constructor() { }
  public params: any = {}

  getParams(elt) {
    return elt.params;
  }

  makeLink(index){
      return this.linkbase+"/"+index;
  }

  ngOnInit() {
    this.linkEvent.subscribe((event) => {
      if (event.sel == this.idlinks) {
        // let values = event.value;
        this.linktargets=event.value;
        // for (let i = 0; i < values.length; i++){
        //   this.linktargets.push({name:values.name,params:values[i]+"/"+i});
        // }
        console.log(this.linktargets);
        this.updateTable(event.value);
      }
    });
    this.colNames["name"] = this.title;
  }

  public updateTable(link: any[]): void {
    this.dataSource.data = link;
    this.table.renderRows();
  }

}