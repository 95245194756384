<div class="main_panel" layout="column" layout-align="center center">
  <div class="up space-div">
    <mat-card *ngIf="has_main_img" class="biglogo">
      <img class="logo" [src]="main_img" alt="">
    </mat-card>
  </div>
  <div class="mid space-div">
    <mat-card class="panel-card mat-elevation-z8">
      <form class="connect-form" [formGroup]="form_user" (ngSubmit)=connect(form_user.value)>
        <div class="form-div">
          <mat-form-field class="formfield" appearance="outline">
            <mat-label>Utilisateur</mat-label>
            <input matInput formControlName="name" >
          </mat-form-field>

          <mat-form-field class="formfield" appearance="outline">
            <mat-label>Mot de passe</mat-label>
            <input  [type]="'password'" matInput formControlName="pwd" >
          </mat-form-field>
        </div>
        <div class="ctrl-div">
          <div class="log-fail">
            <p *ngIf="failedConnection"> Identifiant ou mot de passe incorrect. </p>
          </div>
          <button mat-button name="connect-button" class="form-button">Connexion</button>
        </div>
      </form>
    </mat-card>
    </div>
  <div class="down space-div"> </div>
</div>
