import { Component } from '@angular/core';

@Component({
  selector: 'datacenter-page',
  template:'<div class="datacenter page"><router-outlet></router-outlet></div>',
  styles:['.page{width:100%; height:100%; overflow:auto;}']
})
export class DatacenterPageComponent {
  idpage:number=10;
  constructor(){}
  ngOnInit() { }
}
