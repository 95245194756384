import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
/**
This load server configuration
**/
interface ServerParams {
  protocol: string,
  host: string,
  port: string,
  use_proxy: boolean
}

interface ClientParams {
  has_main_img: boolean,
  main_img: string,
  connect_to: string,
  sitename: string
}

interface Banners {
  img: string;
  link: string;
  name: string;
  sizex: number;
  sizey: number;
  celsize: number;
  rel_size: number;

}

interface BannersParams {
  base: string,
  hor: Banners[],
  vert: Banners[]
}

@Injectable({
  providedIn: 'root'
})

export class ConfigLoaderService {
  server: ServerParams;
  banners: BannersParams;
  client: ClientParams;
  constructor(private httpClient: HttpClient) { }
  ensureInit(): Promise<any> {
    return new Promise((result, error) => {
      this.httpClient.get("assets/conf-files/serv-conf.json")
        .subscribe(
          (content: ConfigLoaderService) => {
            Object.assign(this, content);
            result(this);
          },
          reason => error(reason));
    });
  }
}


export class Config {
  //This class give an access to loaded configuration
  private static self: Config = null;
  private static _server: ServerParams;
  private static _client: ClientParams;
  private static _adress: string;
  private static _banners: BannersParams;
  private constructor(cls: ConfigLoaderService) {
    Config._server = cls.server;
    Config._adress = Config.buildAddress(cls.server);
    Config._client = cls.client;
    Config._banners = cls.banners;
  }

  private static buildAddress(server) {
    let tmp = server.protocol + "://" + server.host;
    return server.use_proxy ? tmp : tmp + ":" + server.port;
  }


  public static server(cls: ConfigLoaderService): ServerParams {
    if (Config.self == null) Config.self = new Config(cls);
    return Config._server;
  }

  public static client(cls: ConfigLoaderService): ClientParams {
    if (Config.self == null) Config.self = new Config(cls);
    return Config._client;
  }

  public static adress(cls: ConfigLoaderService): string {
    if (Config.self == null) Config.self = new Config(cls);
    return Config._adress;
  }

  public static banners(cls: ConfigLoaderService): BannersParams {
    if (Config.self == null) Config.self = new Config(cls);
    return Config._banners;
  }

}
