import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChargingPointModule } from './charging-point-page/charging-point.module';
import { ElecPlugsModule } from './elec-plugs-page/elec-plugs.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ChargingPointModule,
    ElecPlugsModule
  ]
})
export class UtilityPageModule { }
