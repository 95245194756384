import * as CanvasJs from 'src/assets/js-script/canvasjs/canvasjs.min.js';
import * as CanvasSTD from '../canvas-std';
export abstract class GenGraph {
  protected chart : CanvasJs.Chart;
  public name : string;
  protected mode : string;
  protected id : string;
  protected type : string;
  protected styles : any;
  protected entries : any;
  public constructor(name:string,mode:string,id:string,type:string){
    this.name=name;
    this.mode=mode;
    this.type=type;
    this.id=id;
  }

  protected abstract addPoints(line : CanvasSTD.CanvasLine ,xkey : string,ykey : string,datas : CanvasSTD.Datapoint[]):void;
  protected abstract initLine(key:string): CanvasSTD.CanvasLine;
  public abstract initChart(target:string,build:any):void;
  protected abstract initDatas(data:CanvasSTD.CanvasLine[]):void;

  public controlDataValidity(datas : CanvasSTD.Datapoint[][]) : boolean{
    if(datas.length==0)return false;
    if(datas.length==1 && datas[0].length==0)return false;
    return true;
  }

  private build(lines : CanvasSTD.CanvasLine[] ,datas:CanvasSTD.Datapoint[],xkey : string,ykey : string):void {
    var dataspoints : CanvasSTD.CanvasPoint[] = [];
    lines.forEach((line)=>{
      if(line.id==ykey){
        this.addPoints(line,xkey,ykey,datas);
      }
    });
  }

  private replace(lines : CanvasSTD.CanvasLine[],datas : CanvasSTD.Datapoint[], xkey : string,ykey : string):void {
    var dataspoints : CanvasSTD.CanvasPoint[] = [];
    lines.forEach((line)=>{
      if(line.id==ykey){
        this.addPoints(line,xkey,ykey,datas);
      }
    });
  }

  public setLines(datas : CanvasSTD.Datapoint[][],type:string) : any{
    var lines : CanvasSTD.CanvasLine[] = this.chart.options.data;
    var keys:string[];
    var lastK: number;
    lines.forEach((line)=>{
      line.dataPoints.splice(0);
    });
    for(var j = 0; j < datas.length;j++){
      if(datas[j].length>0){
        keys=Object.keys(datas[j][0]);
        lastK=keys.length
        for(var i=1; i<lastK;i++){
          this.build(lines, datas[j], keys[0], keys[i]);
        }
      }
    }
  }

  public updateGraph(datas : CanvasSTD.Datapoint[][],type:string) : any {
    var lines : CanvasSTD.CanvasLine[] = this.chart.options.data;
    var keys:string[];
    var lastK: number;
    lines.forEach((line)=>{
      line.dataPoints.splice(0);
    });
    for(var j = 0; j < datas.length;j++){
      if(datas[j].length>0){
        keys=Object.keys(datas[j][0]);
        lastK=keys.length;
        for(var i=1; i<lastK;i++){
          this.replace(lines, datas[j], keys[0], keys[i]);
        }
      }
    }
  }

  public updateAxes(time:string){}
  public updateAxesFromTime(start:number, end:number){}

  public removeAllPoints(){
    this.chart.options.data.forEach((line)=>{
      line.dataPoints.splice(0);
    });
  }

  getType(){
    return this.type;
  }

  getMode(){
    return this.mode;
  }

  getChart(){
    return this.chart;
  }

  updateDisplay(){
    this.chart.render();
  }
}
