import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Observable } from 'rxjs';
 
@Component({
  selector: 'reseau-panel',
  templateUrl: './reseau-panel.component.html',
  styleUrls: ['./reseau-panel.component.scss']
})
export class ReseauPanelComponent implements OnInit {
  @Output() onSelect: EventEmitter<{ sel: string, value: any }> = new EventEmitter < { sel: string, value: any } >();
  @Output() onSearchEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() searchEvent: Observable<boolean>;

  onsearch:boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.searchEvent.subscribe((onsearch)=>{
      this.onsearch = onsearch;
    });
  }

  leaveSearch(){
    this.onSearchEvent.emit(false);
  }

  openSearch(){
    this.onSearchEvent.emit(true);
  }

  onSelectionDone(sel:string, event:any){
    this.onSelect.emit({sel:sel,value:event});    
  }
}
