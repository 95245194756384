import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'h2-head',
  templateUrl: './h2-head.component.html',
  styleUrls: ['./h2-head.component.scss']
})
export class H2HeadComponent implements OnInit {
  @Output() onSelect: EventEmitter<{sel:string,value:any}> = new EventEmitter<{sel:string,value:any}>();
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeParams: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenPanel: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  selectionDone(sel:string,event:any){
    this.onSelect.emit({sel:sel,value:event});
  }

  toEms(){
    this.onGoBack.emit();
  }

  changeParams(){
    this.onChangeParams.emit();
  }
  openPanel(){
    this.onOpenPanel.emit();
  }
}
