import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'router-head',
  templateUrl: './router-head.component.html',
  styleUrls: ['./router-head.component.scss']
})
export class RouterHeadComponent implements OnInit {
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenPanel: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void { }

  toEms() {
    this.onGoBack.emit();
  }

  openPanel() {
    this.onOpenPanel.emit();
  }
}
