<div class="logs-card">
  <div class="logs-options">
    <div class="device-fiter">
      <button class="device-btn" (click)="setDeviceFilter('all')" name="all">Tous</button>
      <button class="device-btn" (click)="setDeviceFilter(currentPage)" name="device">{{get_button_txt(currentPage)}}</button>
    </div>
    <div class="warn-filter">
      <button class="warn-btn" (click)="setMinWarn(0)" name="info"><img class="warn-img" src="{{ico_path[0]}}" alt=" "></button>
      <button class="warn-btn" (click)="setMinWarn(1)" name="warning"><img class="warn-img" src="{{ico_path[1]}}" alt=" "></button>
      <button class="warn-btn" (click)="setMinWarn(2)" name="alert"><img class="warn-img" src="{{ico_path[2]}}" alt=" "></button>
    </div>
  </div>
  <div class="logs-view">
    <table mat-table class="logs-table" [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
      <th class="elt-{{column}}" mat-header-cell *matHeaderCellDef > {{columnNames[column]}} </th>
      <td class="elt-{{column}}" mat-cell *matCellDef="let element" >
        <div *ngIf="column=='date'; then thenBlock; else elseBlock"></div>
        <ng-template #thenBlock>
          {{element[column] | date : 'dd/MM/yyyy'}}
          <br>
          {{element[column] | date : 'HH:mm:ss'}}
        </ng-template>
        <ng-template #elseBlock>
          <div class="event-div">
            <div class="event-header">
              <div class="event-ico">
                <img class="event-img" src="{{ico_path[element[column].warnlvl]}}" alt=" ">
              </div>
              <div class="event-part">
                {{element[column].part}}
              </div>
            </div>
            <div class="event-shdesc">
              {{element[column].shortdesc}}
            </div>
          </div>
        </ng-template>
      </td>

    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element"  [attr.colspan]="columnsToDisplay.length">
        <div class="element-detail"
             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="element-description"> {{element.event.description}} </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
  </div>

</div>
