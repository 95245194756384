import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { InverterPVService } from '../../inverter-pv.service';
import { BuildParams } from '@shared/modules/canvas-js/types/graph-builder';

const REDSTATE:string = "rgb(255, 80, 80)";
const GREENSTATE:string = "rgb(102, 255, 153)";
const ORANGESTATE:string = "rgb(255, 136, 77)";
const GRAYSTATE:string = "rgb(166, 166, 166)";

interface InvFieldList{
  [name: string]: {
    title:string;
    value:boolean;
    coloron:string;
    coloroff:string;
  }
}

function initLineParams(selects, majors, mode) {
  return { "select": selects, "time": 0, "timetype": "nogroup", "numbers": majors, device: 0, mode: mode }
}

function initPhoenixParams(initEvent) {
  initEvent.emit({
    state: initLineParams([0,1,2,3], ["0"], "phoenixlast_state")
  });
}

@Component({
  selector: 'inverter-pv-state-panel',
  templateUrl: './inverter-pv-state-panel.component.html',
  styleUrls: ['./inverter-pv-state-panel.component.scss']
})
export class InverterPVStatePanelComponent implements OnInit {
  @Output() initRequest : EventEmitter<any> = new  EventEmitter<any>();
  @Input() dataEvent: Observable<any>;

  fields: InvFieldList = {
    "stateinvon":{
      title: "Onduleur Actif",
      value: false,
      coloron:GREENSTATE,
      coloroff:REDSTATE
     },

    "stateoverload": {
      title: "Surcharge",
      value: false,
      coloron:REDSTATE,
      coloroff:GRAYSTATE
     },

    "statelowbat": {
      title: "Batterie Faible",
      value: false,
      coloron:ORANGESTATE,
      coloroff:GRAYSTATE
      },

    "statetem": {
      title: "Température Haute",
      value: false,
      coloron:REDSTATE,
      coloroff:GRAYSTATE
      }
    }
  fieldnames = Object.keys(this.fields);

  constructor() {
  }

  setAllFalse(){
    this.fieldnames.forEach((name) => {
      this.fields[name].value = false;
    });
  }

  getStateColor(field){
    return  field["value"] ? field["coloron"] : field["coloroff"];
  }

  ngOnInit(): void {  initPhoenixParams(this.initRequest);  }

  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {
      if(event.orig=="invstate"){
        console.log(event.datas);
        let datas = event.datas;
        if (datas != null) {
          if (datas.length > 0) {
            if (datas[0] != null) {
              if (datas[0].length > 0) {
                if (datas[0][0] != null) {
                  console.log(datas[0][0]);
                  let keys = Object.keys(this.fields);
                  keys.forEach((key) => {
                    if(key in datas[0][0]){
                      this.fields[key].value = datas[0][0][key];
                    }
                  });
                  // this.fields[event.graph].value = event.datas[0][0][event.graph];
                }
              }
              else{
                this.setAllFalse()
              }
            }
          }
        }
      }
    });
  }
}
