import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { InverterPVService } from '../../inverter-pv.service';
import { BuildParams } from '@shared/modules/canvas-js/types/graph-builder';

interface InvFieldList{
  [name: string]: {
    title:string;
    value:string;
    unit:string;

  }
}

function initLineParams(selects, majors, mode) {
  return { "select": selects, "time": 0, "timetype": "short", "numbers": majors, device: 0, mode: mode }
}

function initPhoenixParams(initEvent) {
  initEvent.emit({
    // power_acin: initLineParams([0], ["0"], "phoenixlast_ac"),
    power_acin: initLineParams([0], ["1"], "phoenixlast_ac"),
    voltageumains: initLineParams([1], ["0"], "phoenixlast_volt"),
    // voltageuinv: initLineParams([2], ["0"], "phoenixlast_volt"),
    currentimains: initLineParams([1], ["0"], "phoenixlast_cur"),
    // currentiinv: initLineParams([2], ["0"], "phoenixlast_cur")
  });
}

@Component({
  selector: 'inverter-pv-acsync',
  templateUrl: './inverter-pv-acsync.component.html',
  styleUrls: ['./inverter-pv-acsync.component.scss']
})
export class InverterPvACSyncComponent implements OnInit {
  @Output() initRequest : EventEmitter<any> = new  EventEmitter<any>();
  @Input() dataEvent: Observable<any>;

  fields: InvFieldList = {


    "voltageumains": {
      title: "Tension",
      value: "-1",
      unit:"V"
     },

    "currentimains": {
      title: "Courant",
      value: "-1",
      unit:"A"
      },

    "power_acin":{
      title : "Puissance Apparente",
      value: "-1",
      unit:"VA"
      }

  };
  fieldnames = Object.keys(this.fields);

  constructor() {
  }

  ngOnInit(): void {  initPhoenixParams(this.initRequest);  }

  ngAfterViewInit() {
    this.dataEvent.subscribe((event) => {
      if(event.orig=="invacsync"){
        console.log(event.orig," ",event.datas);
        let datas = event.datas;
        if (datas != null) {
          if (datas.length > 0) {
            if (datas[0] != null) {
              if (datas[0].length > 0) {
                if (datas[0][0] != null) {
                  console.log(datas[0][0])
                  let keys = Object.keys(this.fields);
                  keys.forEach((key) => {
                    console.log(key);
                    if(key in datas[0][0]){
                      this.fields[key].value = ""+(Math.round(( datas[0][0][key] + Number.EPSILON) * 100) / 100)+""+this.fields[key].unit;
                      console.log(this.fields[key].value);
                    }
                  });
                }
              }
            }
          }
        }
      }
    });
  }

}
