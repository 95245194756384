import { Component, OnInit, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'pv-panel',
  templateUrl: './pv-panel.component.html',
  styleUrls: ['./pv-panel.component.scss']
})
export class PVPanelComponent implements OnInit {
  @Output() onSelect: EventEmitter<{ sel: string, value: any }> = new EventEmitter<{ sel: string, value: any }>();


  constructor() { }

  ngOnInit(): void {
  }

  onSelectionDone(sel: string, event: any) {
    this.onSelect.emit({ sel: sel, value: event });
  }
}
