import { Component, OnInit } from '@angular/core';
import { SelectorBase } from '../selector-base';
import { LongTimeList } from '@shared/classes/const-canvas';
@Component({
  selector: 'longtime-selector',
  templateUrl: './longtime-selector.component.html',
  styleUrls: ['./longtime-selector.component.scss']
})
export class LongtimeSelectorComponent extends SelectorBase<any> {

    constructor() { super(); }

    ngOnInit() {
      this.list = LongTimeList.tab;
      this.sel = this.list[0];
    }

    getSelected(sel:string):any{
      return LongTimeList.list[sel]
    }

  }
