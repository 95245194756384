import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'websites-head',
  templateUrl: './websites-head.component.html',
  styleUrls: ['./websites-head.component.scss']
})
export class WebsitesHeadComponent implements OnInit {
  @Output() onOpenPanel: EventEmitter<{ sel: string, value: any }> = new EventEmitter<{ sel: string, value: any }>();
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  openPanel() {
    this.onOpenPanel.emit();
  }

  toEms() {
    this.onGoBack.emit();
  }
}

