import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {  Observable, Subject  } from 'rxjs';

@Component({
  selector: 'datacenter-vm-panel',
  templateUrl: './datacenter-vm-panel.component.html',
  styleUrls: ['./datacenter-vm-panel.component.scss']
})
export class DatacenterVMPanelComponent implements OnInit {
  @Output() onSelect: EventEmitter<{sel:string,value:any}> = new EventEmitter<{sel:string,value:any}>();
  @Input() hostUpdate : Observable<any>;
  @Input() sitesUpdate : Observable<any>;
  @Output() onOpenSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLeaveSearch: EventEmitter<any> = new EventEmitter<any>();
  @Input() set onsearch(value: boolean) { this._onsearch = value; }
  get onsearch() {
    return this._onsearch;
  }
  _onsearch: boolean = false;

  hostlink:string="";
  guestslinks:string[]=[];
  host:any={name:"",index:0};
  guests:any[]=[];
  linkEvent : Subject<any> = new Subject<any>()
  constructor() { }

  hostTabToParams(hosts,hostindex){
    // [{ name: "Energie", index: 0 }]
    let selpar = []
    hosts.forEach((host) => {
      selpar.push({ name: host.name, params: { indexvm: host.index, hostindex: hostindex}})
    });
    return selpar;
  }

  hostTabToHostList(hosts) {
    let selpar = []
    for (let i = 0; i < hosts.length; i++) {
      selpar.push({ name: hosts[i].name, params: { indexvm: hosts[i].index, hostindex: i } })

    }
    return selpar;
  }


  ngOnInit(): void {
    this.hostUpdate.subscribe((datas)=>{
      var hosts = datas.hosts;
      var guests = datas.guests;
      var hostindex = datas.hostindex;
      this.linkEvent.next({ sel: "vm-energy-links", value: this.hostTabToParams([{ name: "Energie", index: 0 }], hostindex)});
      this.linkEvent.next({ sel: "vm-host-list-links", value: this.hostTabToParams(hosts, hostindex)});
      this.linkEvent.next({ sel: "vm-host-vm-links", value: this.hostTabToHostList(hosts)});
      this.linkEvent.next({ sel: "vm-guest-vm-links", value: this.hostTabToParams(guests, hostindex)});
      
    });
    this.sitesUpdate.subscribe((datas) => {
      // var host = datas.host;
      // var guests = datas.guests;
      // this.linkEvent.next({ sel: "vm-energy-links", value: [{ name: "Energie", index: 0 }] });
      // this.linkEvent.next({ sel: "vm-host-list-links", value: [host] });
      // this.linkEvent.next({ sel: "vm-host-vm-links", value: [host] });
      console.log(datas);
      var sites = datas["sites"].sites;
      var selsites = []
      for(var i = 0; i < sites.length;i++){
        selsites.push({ name: sites[i], params: { site: sites[i], vmnum: datas.vmnum}});
      }
      this.linkEvent.next({ sel: "vm-sites-links", value: selsites });
    });
  }
  openSearch() { this.onOpenSearch.emit(null); }
  leaveSearch() { this.onLeaveSearch.emit(null); }
  onSelectionDone(sel:string,event:any){
    this.onSelect.emit({sel:sel,value:event});
  }
}
