import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'datacenter-overview-head',
  templateUrl: './datacenter-overview-head.component.html',
  styleUrls: ['./datacenter-overview-head.component.scss']
})
export class DatacenterOverviewHeadComponent implements OnInit {

  @Output() onOpenPanel: EventEmitter<{sel:string,value:any}> = new EventEmitter<{sel:string,value:any}>();
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  openPanel(){
    this.onOpenPanel.emit();
  }

  toEms(){
    this.onGoBack.emit();
  }

}
