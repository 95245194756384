import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { SearchPopUpComponent } from './search-pop-up/search-pop-up.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [ SearchPopUpComponent ],
    imports: [ 
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialLoaderModule

    ]
})
export class DialogModule { }
