import { Component, Output, EventEmitter } from '@angular/core';
import { CanvasList } from '@shared/classes/request-manager';
import { H2Service } from "../h2.service";
import {  Subject  } from 'rxjs';
import * as ParamsMaker from '@shared/classes/parameters-maker';

function initNoGroupParams(selects,majors,mode){
  return {"select":selects,"time":0,"timetype":"nogroup","numbers":majors,"device":0,mode:mode}
}

function initH2Params(mesures, initEvent,device){
  initEvent.emit( {
    paramsview:initNoGroupParams(["0","1"], ["0"],"greenbox_params"),
    stateview:initNoGroupParams(["0","1"], ["0"],"greenbox_state")
  });
}

@Component({
  selector: 'h2-views',
  templateUrl: './h2-views.component.html',
  styleUrls: ['./h2-views.component.scss']
})
export class H2ViewsComponent extends CanvasList {
  @Output() paramsUpdated: EventEmitter<any> = new EventEmitter<any>();
  buildEvent : Subject<any> = new Subject<any>();
  dataEventNext : Subject<any> = new Subject<any>();
  idparams="paramsview";
  idstate="stateview";

  constructor( private h2 : H2Service) {
    super();
  }

  onParamsUpdated(event){
    this.paramsUpdated.emit(event);

  }

  ngOnInit(): void {
    this.h2.get_usr_greenbox_device().subscribe((greenboxs)=>{
      ParamsMaker.setTabParams(greenboxs, "greenboxs", "greenbox", initH2Params, this.initRequest, 0);
    });
  }

  ngAfterViewInit(){
    this.dataEvent.subscribe((event)=>{
      console.log(event)
      this.passDatas(event,this.dataEventNext);
    });
  }
}
