
<div class="battery page">
  <mat-sidenav-container class="battery-container">
    <mat-sidenav class="battery-sidenav" mode="over" [(opened)]="opened">
      <battery-panel (onSelect)="selectionDone($event)" [searchEvent]="searchEvent.asObservable()" (onSearchEvent)="onSearch($event)">
      </battery-panel>
    </mat-sidenav>
    <mat-sidenav-content class="battery-content">
      <div class="sub-header">
        <battery-head (onOpenPanel)="openPanel()" (onGoBack)="toEms()"></battery-head>
      </div>
      <div class="page-content">
          <div class="bat-state">
            <battery-state [idlist]="'batstate'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('batstate',$event)"></battery-state>
          </div>
          <div class="bat-list">
            <battery-list [idlist]="'batlist'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('batlist',$event)"></battery-list>
          </div>
          <div class="bat-graphs">
            <battery-graphs [idlist]="'batgraph'" [dataEvent]="dataEvent.asObservable()" (initRequest)="initRequest('batgraph',$event)">
            </battery-graphs>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>