export interface Datapoint{
  name?:string,
  y?:number,
  rate0?:number,
  rate1?:number,
  rate2?:number
}

export class GenTab {
  mode : string;
  min : string;
  mean : string;
  max : string;
  refresh : number;

  constructor(name:string, mode:string, id:string){
    this.min='0';
    this.mean='0';
    this.max='0';
    this.mode=mode;
  }

  public noDataPoints(){
    this.min='';
    this.mean='';
    this.max='';
  }

  public controlDataValidity(datas : Datapoint[][]) : boolean{
    if(datas.length==0)return false;
    if(datas.length==1 && datas[0].length==0)return false;
    return true;
  }

  private buildStringNum(num) : string{
    var end: string ='';
    var tmp: number;
    if(num==null){
      return ""
    }
    tmp = num < 0 ? -num : num;
    if(tmp>=1000){
      tmp = tmp/1000;
      end='K';
    }
    if(tmp>=1000){
      tmp = tmp/1000;
      end='M';
    }
    if(tmp>=1000){
      tmp = tmp/1000;
      end='G';
    }
    return tmp.toFixed(2)+end;
  }
  public updateTab(min:number,mean:number,max:number) : any {
    this.min = this.buildStringNum(min);
    this.mean = this.buildStringNum(mean);
    this.max = this.buildStringNum(max);
  }

    public getMode(){
      return this.mode;
    }
}
