import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialLoaderModule } from '@shared/modules/material-loader.module';
import { CanvasJsModule } from '@shared/modules/canvas-js/canvas-js.module'

import { ReseauPageComponent } from './reseau-page.component';
import { ReseauHeadComponent } from './reseau-head/reseau-head.component';
import { ReseauGraphComponent } from './reseau-graph/reseau-graph.component';
import { ReseauPanelComponent } from './reseau-panel/reseau-panel.component'
import { SelectorsModule } from '@shared/modules/selectors/selectors.module';

@NgModule({
  declarations: [ ReseauPageComponent, ReseauHeadComponent, ReseauGraphComponent, ReseauPanelComponent ],
  exports: [ ReseauPageComponent ],
  imports: [
    MaterialLoaderModule,
    CanvasJsModule,
    SelectorsModule,
    CommonModule
  ]
})
export class ReseauPageModule { }
